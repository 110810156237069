import React, { useEffect, useState } from "react";
import "./Enquiry.css";
import { Button, Card, Col, Container, Nav, Navbar, Row, Dropdown, Table, Form } from "react-bootstrap";
// import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
// import { IoIosArrowForward } from "react-icons/io";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import container from "../src/Photos/Container.png";

function Enquiry() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };


    const [orderDetails, setOrderDetails] = useState([]);

    useEffect(() => {
        // Get OrderID from localStorage
        const selectedOrderId = localStorage.getItem("selectedOrderId");

        // const userId = localStorage.getItem("userId");

        if (selectedOrderId) {
            const token = localStorage.getItem("token");
            const userId = localStorage.getItem("userId");

            // Fetch data and filter by OrderID
            fetch(`https://api-ft5g.onrender.com/Get-container-place-enquiry-user-and-admin/${userId}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`, // Add token for authentication
                    "Content-Type": "application/json", // Optional, based on backend requirements
                },
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`HTTP error! status: ${res.status}`);
                    }
                    return res.json();
                })
                .then((docs) => {
                    if (docs && docs.data) {
                        const filteredOrder = docs.data.filter(
                            (item) => item.OrderID === selectedOrderId
                        );
                        setOrderDetails(filteredOrder);
                    } else {
                        console.warn("Data property not found in response:", docs);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }

        // (Optional) Clear the stored OrderID after use
        return () => {
            localStorage.removeItem("selectedOrderId");
        };
    }, []);


    const navigate = useNavigate();

    // const [files, setFiles] = useState([]);
    // const [error, setError] = useState("");
    const { orderId } = useParams(); // Get UserID and OrderID from the URL


    // Fetch files from the backend
    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     const userId = localStorage.getItem("userId");

    //     if (!token || !userId) {
    //         setError("Authentication failed: Missing token or user ID.");
    //         return;
    //     }

    //     fetch(`https://api-ft5g.onrender.com/enquiry-quotation-files/${userId}/${orderId}`, {
    //         method: "GET",
    //         headers: {
    //             "Authorization": `Bearer ${token}`, // Add the token for authentication
    //             "Content-Type": "application/json", // Optional, based on backend requirements
    //         },
    //     })
    //         .then((res) => {
    //             if (!res.ok) {
    //                 throw new Error(`HTTP error! status: ${res.status}`);
    //             }
    //             return res.json();
    //         })
    //         .then((data) => {
    //             if (data.files) {
    //                 setFiles(data.files);
    //             } else {
    //                 setError(data.message || "Failed to fetch files");
    //             }
    //         })
    //         .catch((err) => {
    //             console.error("Error fetching files:", err);
    //             setError("Failed to fetch files");
    //         });
    // }, [orderId]);


    // Handle file download
    // const handleDownload = (fileName) => {
    //     window.open(`https://api-ft5g.onrender.com/download-file/${fileName}`, "_blank");
    // };


    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in when the component mounts
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        setIsLoggedIn(!!(token && userId)); // Set `isLoggedIn` based on the existence of token and userId
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            alert("You are already logged in!"); // Show an alert if already logged in
        } else {
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        console.log("User logged out and data removed from localStorage.");
        setIsLoggedIn(false);
        navigate("/Login"); // Redirect to login
    };



    const [statusMap, setStatusMap] = useState({}); // Track status for each order

    // Handle status change in dropdown
    const handleStatusChange = (cpeId, value) => {
        setStatusMap((prev) => ({ ...prev, [cpeId]: value }));
    };

    // Save status update to backend
    const handleSaveStatus = async (cpeId) => {
        try {
            const response = await fetch("https://api-ft5g.onrender.com/update-enquiry-user-side", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cpeId,
                    status: statusMap[cpeId], // Selected status
                }),
            });

            if (response.ok) {
                alert("Status updated successfully!");
            } else {
                alert("Failed to update status");
            }
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };



    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container fluid className="mt-3">
                <Row>
                    {/* <Col lg={7}>
                        <Card className="p-4">
                            <h5 className="text-center mb-4">ENQUIRY DETAILS</h5>

                            {
                                orderDetails.length > 0 ? (
                                    orderDetails.map((item, index) => (
                                        <Row key={index}>
                                            <hr className="mt-3 mb-3" />
                                            <Col>
                                                <Row className="text-center mb-2 mt-3">
                                                    <Col>
                                                        <p className="mb-1 text-muted orderfont2">{item.OrderID}</p>
                                                        <h4 className="orderfont1">{item.PRODUCT_DESCRIPTION}</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-muted mb-1 orderfont1">ORDERED ON</p>
                                                        <p className="orderfont1">{item.UploadDate}</p>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-muted mb-1 orderfont1">STATUS</p>
                                                        <p className="text-warning orderfont1" style={{ fontWeight: "bold" }}>Ongoing</p>
                                                    </Col>
                                                </Row>

                                                <Row className="text-center">
                                                    <Col className="mt-4">
                                                        <p className="text-muted mb-1 orderfont1">QUANTITY</p>
                                                        <p className="orderfont1">{item.UNIT_PER_CTN} {item.UNIT} / CARTONS</p>
                                                    </Col>
                                                    <Col className="mt-4">
                                                        <p className="text-muted mb-1 orderfont1">NO. OF CARTONS</p>
                                                        <p className="orderfont1">{item.CartonQty}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))
                                ) : (
                                    <p>No details available for this order.</p>
                                )
                            }

                            < hr className="my-4" />
                        </Card>
                    </Col> */}

                    <Col className="mt-3">
                        <Card className="p-4">
                            <h5 className="text-center mb-4">ENQUIRY DETAILS</h5>

                            {orderDetails.length > 0 ? (
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr className="text-center">
                                            <th>Order ID</th>
                                            <th>Product Description</th>
                                            <th>Ordered On</th>
                                            <th>SKU Code</th>
                                            <th>Quantity</th>
                                            <th>No. of Cartons</th>
                                            <th>Total Cases</th>
                                            <th>Total QTY</th>
                                            <th>Rate Per Unit USD</th>
                                            <th>Rate Per Case USD</th>
                                            <th>Total Rate In USD FOB INDIA</th>
                                            <th>Total Net Weight</th>
                                            <th>Total Gross Weight</th>
                                            <th>Total Volume</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetails.map((item, index) => (
                                            <tr key={index} className="text-center">
                                                <td>{item.OrderID}</td>
                                                <td>{item.PRODUCT_DESCRIPTION}</td>
                                                <td>{new Date(item.UploadDate).toLocaleString()}</td>
                                                <td>{item.SKU_CODE}</td>
                                                <td>{item.UNIT_PER_CTN} {item.UNIT} / Cartons</td>
                                                <td>{item.CartonQty}</td>
                                                <td>{item.TotalCases}</td>
                                                <td>{item.TotalQty}</td>
                                                <td>{item.RatePerUnitUSD}</td>
                                                <td>{item.RatePerCaseUSD}</td>
                                                <td>{item.TotalRateInUSDFobIndia}</td>
                                                <td>{item.TotalNetWeight}</td>
                                                <td>{item.TotalGrossWeight}</td>
                                                <td>{item.TotalVolume}</td>
                                                <td>
                                                    <Form.Select
                                                        value={statusMap[item.CPE_ID] || ""}
                                                        onChange={(e) => handleStatusChange(item.CPE_ID, e.target.value)}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="approve">Approve</option>
                                                        <option value="reject">Reject</option>
                                                        <option value="negotiate">Negotiate</option>
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Button variant="primary" onClick={() => handleSaveStatus(item.CPE_ID)}>
                                                        Save
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p className="text-center text-muted">No details available for this order.</p>
                            )}

                            {/* <hr className="my-4" />
                            <p className="text-center text-muted orderfont">
                                Your order will be delivered tentatively by <span style={{ fontWeight: "bold" }}>15 Dec 2024</span>
                            </p> */}
                        </Card>
                    </Col>

                    {/* <Col lg={4} className="mb-3">
                        <Card className="p-3"> */}
                    {/* <Row>
                                <Col className="mt-3 mx-3">
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label>Upload Quotation</Form.Label>
                                        <Form.Control type="file" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mx-3">
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label>Upload Proforma Invoice</Form.Label>
                                        <Form.Control type="file" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mx-3">
                                    <Form.Group controlId="formFileMultiple" className="mb-3">
                                        <Form.Label>Upload Shipping Documents</Form.Label>
                                        <Form.Control type="file" multiple />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-2 mx-3">
                                    <Form.Label>Update Shipping Details</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                        <option>----Shipment Status----</option>
                                        <option value="1">Inquiry Places</option>
                                        <option value="2">Quotation Uploaded</option>
                                        <option value="3">Order Confirm</option>
                                        <option value="4">In Transit</option>
                                        <option value="5">Order Reached Destination Port</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-3 mx-3">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formGroupBLNo.">
                                            <Form.Label>BL Number</Form.Label>
                                            <Form.Control type="text" placeholder="Enter BL Number" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formGroupShippingLines">
                                            <Form.Label>Shipping Lines</Form.Label>
                                            <Form.Control type="text" placeholder="Enter the Name of Shipping Lines" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formGroupETA">
                                            <Form.Label>Estimated Time of Arrival</Form.Label>
                                            <Form.Control type="text" placeholder="Enter the Estimated of Arrival" />
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row> */}


                    {/* <div className="container mt-4"> */}
                    {/* <h4 className="mb-3">Uploaded Files</h4>
                    {error && <p className="text-danger">{error}</p>} */}
                    {/* {files.length > 0 ? ( */}
                    {/* <Table>
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>File Type</th>
                                <th>Uploaded Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file, index) => (
                                <tr key={index}>
                                    <td>{file.FileName}</td>
                                    <td>{file.FileType}</td>
                                    <td>{new Date(file.UploadDate).toLocaleString()}</td>
                                    <td>
                                        <Button
                                            variant="primary"
                                            onClick={() => handleDownload(file.FileName)}
                                        >
                                            Download
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table> */}
                    {/* ) : (
                                    <p>No files available</p>
                                )} */}
                    {/* </div> */}
                    {/* </Card>
            </Col> */}
                </Row>
            </Container>
        </>
    );
}

export default Enquiry;