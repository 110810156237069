import React, { useState } from "react";
import "./Signup.css";
import { Card, Col, Container, Row, Form, Button, Alert } from "react-bootstrap";
// import Logo from "../src/Photos/Logo.png";
import { Link, useNavigate } from "react-router-dom";

function Signup() {

    const navigate = useNavigate();

    // State to handle input fields and feedback
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    // Function to handle form submission
    const handleSignup = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        // Reset feedback messages
        setMessage(null);
        setError(null);

        try {
            // API call
            const response = await fetch("https://api-ft5g.onrender.com/signup", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    EmailID: email,
                    Password: password,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage("User registered successfully!");
                setTimeout(() => {
                    navigate("/Login"); // Redirect to login page after 2 seconds
                }, 2000);
            } else {
                setError(data.message || "Failed to sign up.");
            }
        } catch (err) {
            console.error(err);
            setError("An error occurred. Please try again.");
        }
    };

    return (
        <>
            <Container fluid className="backdesign">
                <Row className="justify-content-center p-4">
                    {/* <Col className="p-0" lg={6} md={6}>
                        <Card className="firstcard">
                            <img src={Logo}
                                alt="logo"
                                className="d-inline-block align-top w-50"
                            />
                        </Card>
                    </Col> */}

                    <Col className="p-0 mb-4" lg={6} md={6} xs={12}>
                        <Card className="p-5 mx-5 rightcard">
                            <Row>
                                <Col>
                                    <Card className="mt-3 p-5 rightinsidecard">
                                        <Row>
                                            <Col className="text-center mt-4">
                                                <p className="login">Sign Up</p>
                                            </Col>
                                        </Row>

                                        {/* Feedback Messages */}
                                        {message && (
                                            <Alert variant="success" className="text-center">
                                                {message}
                                            </Alert>
                                        )}
                                        {error && (
                                            <Alert variant="danger" className="text-center">
                                                {error}
                                            </Alert>
                                        )}

                                        <Form onSubmit={handleSignup}>
                                            <Row>
                                                <Col>
                                                    <Form.Label className="emailid">
                                                        Email ID
                                                    </Form.Label>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter Your Email ID"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="mt-3">
                                                    <Form.Label className="password">
                                                        Password
                                                    </Form.Label>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="Enter Your Password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        required
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="text-center mt-5">
                                                    <Button
                                                        type="submit"
                                                        className="w-100 loginbtn"
                                                    >
                                                        Sign Up
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>

                                        <Row>
                                            <Col className="text-center mt-4">
                                                <p className="line">Have an Account?
                                                    <Link to="/Login" className="signuplink">Login In</Link>
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Signup;