import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import { Nav, Navbar, Container, Button, Row, Col, Card, Dropdown, Form } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import Account from "../src/Photos/Account.png";
import container from "../src/Photos/Container.png";

import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";

function ContactUs() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const navigate = useNavigate();


    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        ContactNumber: '',
        Message: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setResponseMessage('');

        try {
            const response = await fetch('https://api-ft5g.onrender.com/contact-us-messages', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok) {
                setResponseMessage('Message sent successfully!');
                setFormData({
                    Name: '',
                    Email: '',
                    ContactNumber: '',
                    Message: '',
                });
            } else {
                setResponseMessage(result.error || 'Failed to send the message.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setResponseMessage('An error occurred while submitting the form.');
        } finally {
            setIsSubmitting(false);
        }
    };


    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in when the component mounts
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        setIsLoggedIn(!!(token && userId)); // Set `isLoggedIn` based on the existence of token and userId
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            alert("You are already logged in!"); // Show an alert if already logged in
        } else {
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        console.log("User logged out and data removed from localStorage.");
        setIsLoggedIn(false);
        navigate("/Login"); // Redirect to login
    };


    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container>
                <Row>
                    <Col className="mt-3" lg={6} xs={12}>
                        <Row>
                            <Col>
                                <p className="location">Our Location</p>
                            </Col>
                        </Row>

                        <Card className="locationbox">
                            <Row>
                                <Col className="text-center">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15070.918065775717!2d72.8428824!3d19.2070128!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6da613131df%3A0xaa9256ecfee80d85!2sExim%20Trac!5e0!3m2!1sen!2sin!4v1733835208003!5m2!1sen!2sin"
                                        width="630"
                                        height="520"
                                        style={{ border: 0 }}
                                        allowfullscreen=""
                                        loading="eager"
                                        referrerpolicy="no-referrer-when-downgrade"
                                        className="contact_map">
                                    </iframe>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mt-3" lg={6} xs={12}>
                        <Row>
                            <Col>
                                <p className="location">Get In Touch</p>
                            </Col>
                        </Row>

                        <Card className="locationbox">
                            <Form className="p-5" onSubmit={handleSubmit}>
                                <Form.Group className="mb-4">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="Name"
                                        value={formData.Name}
                                        onChange={handleInputChange}
                                        placeholder="Enter Name"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email" name="Email"
                                        value={formData.Email}
                                        onChange={handleInputChange}
                                        placeholder="Enter Email"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Contact Number</Form.Label>
                                    <Form.Control
                                        type="text" name="ContactNumber"
                                        value={formData.ContactNumber}
                                        onChange={handleInputChange}
                                        placeholder="Enter Contact Number"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        type="text" name="Message"
                                        rows="3"
                                        value={formData.Message}
                                        onChange={handleInputChange}
                                        placeholder="Enter Message"
                                        required
                                    />
                                </Form.Group>

                                <Button type="submit" className="w-100 contactsubmitbtn" disabled={isSubmitting}>
                                    Submit
                                </Button>
                                {/* Display response message */}
                                {responseMessage && <p className="mt-3">{responseMessage}</p>}
                            </Form>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-4 mb-5">
                        <Card className="contactdetailscard p-5">
                            <Row>
                                <Col className="text-center">
                                    <p className="phone">Support</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center">
                                    <p className="text1">Our Friendly Team is here to help you</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center">
                                    <a href="mailto:eximtrac@gmail.com" className="text2 textdeco">
                                        eximtrac@gmail.com
                                    </a>
                                    <br /> <br />
                                    <a href="mailto:connect@eximtrac.com" className="text2 textdeco">
                                        connect@eximtrac.com
                                    </a>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mt-4 mb-5">
                        <Card className="contactdetailscard p-5">
                            <Row>
                                <Col className="text-center">
                                    <p className="phone">Phone</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center">
                                    <p className="text1">Monday - Saturday from 10am to 6pm</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center">
                                    <a href="tel:9699268642" className="text2 textdeco">
                                        Mr. Keyur Mehta : +91-96992 68642
                                    </a>
                                    <br /> <br />
                                    <a href="tel:9029937080" className="text2 textdeco">
                                        Mr. Kunjesh Mehta : +91-90299 37080
                                    </a>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>


            <div class="icon-bar-floating">
                <a href="https://wa.me/+919029937080"
                    // class="whatsapp_float"
                    target="_blank"
                    rel="noopener noreferrer">
                    <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                </a>

                <a href="tel:9029937080">
                    <BiSolidPhoneCall className="call p-1" />
                </a>
            </div>

            {/* ----------------FOOTER----------------- */}

            <Container fluid>
                <MDBFooter bgColor='light' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                                        <div className='me-5 d-none d-lg-block'>
                                            <span>Get connected with us on social networks:</span>
                                        </div>
                
                                        <div>
                                            <Link to="#" className='me-4 text-reset'>
                                                <FaFacebook />
                                            </Link>
                
                                            <Link to="#" className='me-4 text-reset'>
                                                <MDBIcon color='secondary' fab icon='twitter' />
                                            </Link>
                
                                            <Link to="#" className='me-4 text-reset'>
                                                <MDBIcon color='secondary' fab icon='instagram' />
                                            </Link>
                
                                            <Link to="#" className='me-4 text-reset'>
                                                <MDBIcon color='secondary' fab icon='linkedin' />
                                            </Link>
                                        </div>
                                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Eximtrac
                                    </h6>
                                    <p>
                                        We are a certified Mumbai-based exporter, specializing in Indian groceries and FMCG products (food and non-food).
                                        With a vast portfolio of 200+ brands and new additions regularly, we source and deliver everything you need, consolidating all your requirements into one solution.
                                    </p>
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>Quick Links</h6>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/" className='text-reset foottext'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Aboutus" className='text-reset foottext'>
                                                About Us
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Products" className='text-reset foottext'>
                                                Shop Now
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/ContactUs" className='text-reset foottext'>
                                                Contact Us
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>OFFICE ADDRESS</h6>
                                    <p>
                                        Eximtrac<br />
                                        Shop No 2&3, Laxmi Apts, Behind Patel Nagar,
                                        M.G. Cross Road no 4, Kandivali West,
                                        Mumbai – 400067, India

                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>CONTACT & EMAIL</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div class="icon-bar">
                                            <a href="mailto:eximtrac@gmail.com" className="textdeco">
                                                <MdEmail className="me-2" /> eximtrac@gmail.com
                                            </a>
                                            <br />
                                            <a href="mailto:connect@eximtrac.com" className="textdeco">
                                                <MdEmail className="me-2 footerphone" /> connect@eximtrac.com
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:9699268642" className="textdeco">
                                                <FaPhoneAlt className="me-2" /> +91-96992 68642
                                            </a>
                                            <br />
                                            <a href="tel:9029937080" className="textdeco">
                                                <FaPhoneAlt className="me-2 footerphone" /> +91-90299 37080
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-4 footerlastbgco'>
                        © 2024 Copyright:
                        <a className='text-reset fw-bold' href='Eximtrac'>
                            Eximtrac
                        </a>
                    </div>
                </MDBFooter>
            </Container >
        </>
    );
};

export default ContactUs;