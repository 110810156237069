import React, { useEffect, useState } from "react";
import "./AdminDash.css";
// import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useNavigate } from "react-router-dom";
import { Button, Navbar, Nav, Container, Row, Col, Card, Table, Dropdown } from "react-bootstrap";
// import dash from "../src/Photos/Dashboard.png";
// import menu from "../src/Photos/Menu.png";
// import { IoIosArrowForward } from "react-icons/io";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";
// import rupee from "../src/Photos/Rupees.png";
import order from "../src/Photos/Order.png";
import customer from "../src/Photos/Customer.png";

// import { Chart as ChartJS, defaults } from "chart.js/auto";
// import { Line } from "react-chartjs-2";
// import salesData from "../src/data/salesData.json";
// import purchaseData from "../src/data/purchaseData.json";

function AdminDash() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    // const [toggled, setToggled] = React.useState(false);


    // for the line chart

    // defaults.maintainAspectRatio = false;
    // defaults.responsive = true;

    // defaults.plugins.title.display = true;
    // defaults.plugins.title.align = "start";
    // defaults.plugins.title.font.size = 0;
    // defaults.plugins.title.color = "black";


    const [input, setData] = useState([]); // Ensure `input` starts as an empty array
    const navigate = useNavigate();

    useEffect(() => {
        fetch("https://api-ft5g.onrender.com/Get-data-for-admin-dash-enquiry-page")
            .then((res) => res.json())
            .then((docs) => {
                if (docs && docs.data) {
                    setData(docs.data);
                    console.log(docs.data);
                } else {
                    console.warn("Data property not found in response:", docs);
                    setData([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setData([]);
            });
    }, []);

    const handleViewOrder = (orderId, userId) => {
        // Store OrderID in localStorage
        localStorage.setItem("selectedOrderId", orderId);

        // Navigate to Order Details Page
        navigate(`/ViewQuotationEnquiry/${orderId}/${userId}`);

        window.scrollTo(0, 0); // Scroll to the top of the page
    };


    const [input2, setData2] = useState([]); // Ensure `input` starts as an empty array
    // const navigate = useNavigate();

    useEffect(() => {
        fetch('https://api-ft5g.onrender.com/Get-orders-for-admin-dash')
            .then((res) => res.json())
            .then((docs) => {
                if (docs && docs.data) {
                    setData2(docs.data);
                    console.log(docs.data);
                } else {
                    console.warn("Data property not found in response:", docs);
                    setData([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setData([]);
            });
    }, []);


    const handleViewOrder2 = (orderId, userId) => {
        // Store OrderID in localStorage
        localStorage.setItem("selectedOrderId", orderId);

        // Navigate to Order Details Page
        navigate(`/ViewQuotationOrder/${orderId}/${userId}`);

        window.scrollTo(0, 0); // Scroll to the top of the page
    };


    const [totalBrands, setTotalBrands] = useState(0);

    useEffect(() => {
        const fetchTotalBrands = async () => {
            try {
                const response = await fetch("https://api-ft5g.onrender.com/Get-admin-brand", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log("Brand API Response:", data); // Debugging

                    setTotalBrands(data.data.TotalBrands || 0);
                } else {
                    throw new Error(`Failed to fetch total brands: ${response.statusText}`);
                }
            } catch (err) {
                console.error("Error fetching total brands:", err);
            }
        };

        fetchTotalBrands();
    }, []);


    const [totalCategories, setTotalCategories] = useState(0);

    useEffect(() => {
        const fetchTotalCategories = async () => {
            try {
                const response = await fetch("https://api-ft5g.onrender.com/Get-admin-category", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log("Category API Response:", data); // Debugging

                    setTotalCategories(data.data.TotalCategories || 0);
                } else {
                    throw new Error(`Failed to fetch total categories: ${response.statusText}`);
                }
            } catch (err) {
                console.error("Error fetching total categories:", err);
            }
        };

        fetchTotalCategories();
    }, []);


    const [totalSubCategories, setTotalSubCategories] = useState(0);

    useEffect(() => {
        const fetchTotalSubCategories = async () => {
            try {
                const response = await fetch("https://api-ft5g.onrender.com/Get-admin-subcategory", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch total subcategories: ${response.statusText}`);
                }

                const data = await response.json();
                console.log("Received API response:", data); // Debugging

                // Correctly accessing TotalSubCategories based on API response
                if (data && data.data && typeof data.data.TotalSubCategories !== 'undefined') {
                    setTotalSubCategories(data.data.TotalSubCategories);
                } else {
                    console.warn("Unexpected API response structure");
                    setTotalSubCategories(0); // Default value in case of unexpected response
                }
            } catch (err) {
                console.error("Error fetching total subcategories:", err);
            }
        };

        fetchTotalSubCategories();
    }, []);


    const [totalProducts, setTotalProducts] = useState(0);

    useEffect(() => {
        const fetchTotalProducts = async () => {
            try {
                const response = await fetch("https://api-ft5g.onrender.com/Get-admin-products", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log("Product API Response:", data); // Debugging

                    setTotalProducts(data.data?.TotalCount || 0);
                } else {
                    throw new Error(`Failed to fetch total products: ${response.statusText}`);
                }
            } catch (err) {
                console.error("Error fetching total products:", err);
            }
        };

        fetchTotalProducts();
    }, []);


    const [totalOrders, setTotalOrders] = useState(0);

    useEffect(() => {
        const fetchTotalOrders = async () => {
            try {
                const response = await fetch("https://api-ft5g.onrender.com/Get-admin-order", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log("Order API Response:", data); // Debugging

                    setTotalOrders(data.data?.TotalCount || 0);
                } else {
                    throw new Error(`Failed to fetch total orders: ${response.statusText}`);
                }
            } catch (err) {
                console.error("Error fetching total orders:", err);
            }
        };

        fetchTotalOrders();
    }, []);


    const [totalEnquiry, setTotalEnquiry] = useState(0);

    useEffect(() => {
        const fetchTotalEnquiry = async () => {
            try {
                const response = await fetch("https://api-ft5g.onrender.com/Get-admin-enquiry", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log("Enquiry API Response:", data); // Debugging

                    setTotalEnquiry(data.data?.TotalDistinctCount || 0);
                } else {
                    throw new Error(`Failed to fetch total enquiry: ${response.statusText}`);
                }
            } catch (err) {
                console.error("Error fetching total enquiry:", err);
            }
        };

        fetchTotalEnquiry();
    }, []);


    const [totalCustomer, setTotalCustomer] = useState(0);

    useEffect(() => {
        const fetchTotalCustomer = async () => {
            try {
                const response = await fetch("https://api-ft5g.onrender.com/Get-admin-customer", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log("Customer API Response:", data); // Debugging

                    setTotalCustomer(data.data?.TotalCount || 0);
                } else {
                    throw new Error(`Failed to fetch total customers: ${response.statusText}`);
                }
            } catch (err) {
                console.error("Error fetching total customers:", err);
            }
        };

        fetchTotalCustomer();
    }, []);


    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* <Nav className="me-auto">
                        </Nav> */}

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminDash" className="adminnavtext">Dashboard</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Customers" className="adminnavtext">Customers</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Category" className="adminnavtext">Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/SubCategory" className="adminnavtext">Sub-Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Brands" className="adminnavtext">Brands</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminProducts" className="adminnavtext">Products</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Quotations" className="adminnavtext">Enquiries</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminOrder" className="adminnavtext">Orders</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUsResponse" className="adminnavtext">Contact</Nav.Link>
                            </Button>


                            <Dropdown>
                                <Dropdown.Toggle className="scrollbtn2 p-0 mx-2 mt-1 responsiveview" id="dropdown-basic">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="/Profile">My Profile</Dropdown.Item> */}
                                    {/* <Dropdown.Item href="/OrderPage">Past Orders</Dropdown.Item> */}
                                    <Dropdown.Item href="/AdminLogin">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}


            {/* <div style={{ display: 'flex', height: '100%', minHeight: '400px' }} className="mt-3">
                <Sidebar onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint="always" className="side">
                    <Menu>

                        <MenuItem icon={< img src={dash}
                            alt=""
                            className="d-inline-block align-top"
                        />} component={<Link to="/AdminDash" />}> Dashboard</MenuItem>

                        <SubMenu icon={< img src={menu}
                            alt=""
                            className="d-inline-block align-top"
                        />} defaultOpen label="Menu">

                            <MenuItem component={<Link to="/Customers" />}> Customers</MenuItem>
                            <MenuItem component={<Link to="/Category" />}> Category</MenuItem>
                            <MenuItem component={<Link to="/SubCategory" />}> Sub-Category</MenuItem>
                            <MenuItem component={<Link to="/Brands" />}> Brands</MenuItem>
                            <MenuItem component={<Link to="/AdminProducts" />}> Products</MenuItem>
                            <MenuItem component={<Link to="/Quotations" />}> Quotations</MenuItem>
                        </SubMenu>
                    </Menu>
                </Sidebar>
                <main>
                    <div>
                        <Button className="sb-button menubutton" onClick={() => setToggled(!toggled)}>
                            <IoIosArrowForward className="mb-1" />Navigate
                        </Button>
                    </div>
                </main> */}

            <Container fluid className="mt-3">
                <Row>
                    <Col className="mb-3" lg={4} md={4} sm={6} xs={12}>
                        <Card className="todaysalecard px-4">
                            <Row>
                                <Col lg={8} xs={9} className="mt-3">
                                    <p className="todaysale">TOTAL ENQUIRIES</p>
                                </Col>

                                <Col lg={4} xs={3} className="mt-3">
                                    <img src={order}
                                        alt="order"
                                        className="d-inline-block align-top"
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="todaysaleamt">{totalEnquiry}</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mb-3" lg={4} md={4} sm={6} xs={12}>
                        <Card className="totalordercard px-4">
                            <Row>
                                <Col lg={8} xs={9} className="mt-3">
                                    <p className="totalorder">TOTAL ORDERS</p>
                                </Col>

                                <Col lg={4} xs={3} className="mt-3">
                                    <img src={order}
                                        alt="order"
                                        className="d-inline-block align-top"
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="totalordernumber">{totalOrders}</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mb-3" lg={4} md={4} sm={6} xs={12}>
                        <Card className="totalcustomercard px-4">
                            <Row>
                                <Col lg={8} xs={9} className="mt-3">
                                    <p className="totalcustomer">TOTAL CUSTOMERS</p>
                                </Col>

                                <Col lg={4} xs={3} className="mt-3">
                                    <img src={customer}
                                        alt="customers"
                                        className="d-inline-block align-top"
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="totalcustomernumber">{totalCustomer}</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="mb-3" lg={4} md={4} sm={6} xs={6}>
                        <Card className="totalcategorycard px-4">
                            <Row>
                                <Col className="mt-3">
                                    <p className="totalcategory">TOTAL CATEGORY</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="totalcategorynumber">{totalCategories}</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mb-3" lg={4} md={4} sm={6} xs={6}>
                        <Card className="totalsubcategorycard px-4">
                            <Row>
                                <Col className="mt-3">
                                    <p className="totalsubcategory">TOTAL SUB-CATEGORY</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="totalsubcategorynumber">{totalSubCategories}</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mb-3" lg={4} md={4} sm={6} xs={6}>
                        <Card className="totalbrandcard px-4">
                            <Row>
                                <Col className="mt-3">
                                    <p className="totalbrand">TOTAL BRANDS</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="totalbrandnumber">{totalBrands}</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mb-3" lg={4} md={4} sm={6} xs={6}>
                        <Card className="totalproductcard px-4">
                            <Row>
                                <Col className="mt-3">
                                    <p className="totalproduct">TOTAL PRODUCTS</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="totalproductnumber">{totalProducts}</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    {/* <Col className="mb-3" lg={4} md={4} sm={6} xs={12}>
                        <Card className="totalquotationcard px-4">
                            <Row>
                                <Col className="mt-3">
                                    <p className="totalquotation">TOTAL QUOTATIONS</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="totalquotationnumber">45</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>
                {/* </Container> */}
                {/* </div > */}

                {/* <Container fluid> */}
                {/* <Row className="">
                    <Col className="p-4">
                        <Card className="graphcard">
                            <Row className="px-4">
                                <Col className="mt-3" lg={8}>
                                    <p className="salespruchasetext">Sales Purchase Satistics</p>
                                </Col>

                                <Col className="mt-3" lg={1}>
                                    <p className="year">Year</p>
                                </Col>

                                <Col className="mt-3" lg={2}>
                                    <Form.Select aria-label="Default select example" className="">
                                        <option>Select</option>
                                        <option value="1">2024</option>
                                        <option value="2">2023</option>
                                        <option value="3">2022</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row className="App">
                                <Col className="dataCard revenueCard mx-4 mb-4">
                                    <Line
                                        data={{
                                            labels: purchaseData.map((data) => data.label),
                                            datasets: [
                                                {
                                                    label: "Purchase",
                                                    data: purchaseData.map((data) => data.purchase),
                                                    backgroundColor: "red",
                                                    borderColor: "red",
                                                },

                                                {
                                                    label: "Sales",
                                                    data: salesData.map((data) => data.sales),
                                                    backgroundColor: "blue",
                                                    borderColor: "blue",
                                                }
                                            ],
                                        }}
                                        options={{
                                            elements: {
                                                line: {
                                                    tension: 0.5,
                                                },
                                            },
                                            // plugins: {
                                            //     title: {
                                            //         text: "Monthly Purchase",
                                            //     },
                                            // },
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row> */}

                <Row>
                    <Col className="mt-3">
                        <Card className="p-4">
                            <Row>
                                <Col>
                                    <p className="quotations">ENQUIRIES</p>
                                </Col>

                                <Col className="text-end">
                                    <Button onClick={scrollToTop} className="seeallbtn p-0 m-0">
                                        <Link to="/Quotations">
                                            <p className="seeall">See All Enquiries</p>
                                        </Link>
                                    </Button>
                                </Col>
                            </Row>

                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th className="">
                                            Order ID
                                        </th>

                                        <th>
                                            User ID
                                        </th>

                                        <th>
                                            Email ID
                                        </th>

                                        <th>
                                            Customer Name
                                        </th>

                                        <th>
                                            Company Name
                                        </th>

                                        <th>
                                            Enquiry Date
                                        </th>

                                        <th>
                                            View
                                        </th>

                                        {/* <th>
                                                                        Deal Status
                                                                    </th> */}
                                        {/* <th>
                                                                        Action
                                                                    </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {input.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p className="mt-2 ccn">{item.OrderID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.UserID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.EmailID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.FirstName} {item.LastName}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.CompanyName}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{new Date(item.UploadDate).toLocaleString()}</p>
                                            </td>


                                            <td>
                                                <Button
                                                    className="mt-1 dotbtn"
                                                    onClick={() => handleViewOrder(item.OrderID, item.UserID)}
                                                >
                                                    View Enquiry / Quotations
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-5 mb-5">
                        <Card className="p-4">
                            <Row>
                                <Col>
                                    <p className="quotations">ORDERS</p>
                                </Col>

                                <Col className="text-end">
                                    <Button onClick={scrollToTop} className="seeallbtn p-0 m-0">
                                        <Link to="/AdminOrder">
                                            <p className="seeall">See All Order Details</p>
                                        </Link>
                                    </Button>
                                </Col>
                            </Row>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th className="">
                                            Order ID
                                        </th>

                                        <th>
                                            User ID
                                        </th>

                                        <th>
                                            Email ID
                                        </th>

                                        <th>
                                            Customer Name
                                        </th>

                                        <th>
                                            Company Name
                                        </th>

                                        <th>
                                            Enquiry Date
                                        </th>

                                        <th>
                                            View
                                        </th>

                                        <th>
                                            Deal Status
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {input2.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p className="mt-2 ccn">{item.OrderID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.UserID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.EmailID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.FirstName} {item.LastName}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.CompanyName}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{new Date(item.UploadDate).toLocaleString()}</p>
                                            </td>


                                            <td>
                                                <Button className="mt-1 dotbtn" onClick={() => handleViewOrder2(item.OrderID, item.UserID)}>View Order / Quotations</Button>
                                            </td>

                                            <td>
                                                <Card className="pending text-center mt-2">Pending</Card>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AdminDash;