import React, { useState, useEffect } from "react";
import "./SubCategory.css";
// import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from "react-router-dom";
import { Button, Navbar, Nav, Container, Row, Col, Card, Table, Form, Dropdown } from "react-bootstrap";
// import dash from "../src/Photos/Dashboard.png";
// import menu from "../src/Photos/Menu.png";
// import { IoIosArrowForward } from "react-icons/io";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";

// import { FaRegEye } from "react-icons/fa";
// import { FaTrashAlt } from "react-icons/fa";
// import { LuPencil } from "react-icons/lu";

function SubCategory() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    // const [toggled, setToggled] = React.useState(false);



    // const [showModal, setShowModal] = useState(false);
    // const [file, setFile] = useState(null); // State to hold the uploaded file
    // const [successMessage, setSuccessMessage] = useState(false);

    // // Show/Hide Modal
    // const handleShowModal = () => setShowModal(true);
    // const handleCloseModal = () => setShowModal(false);

    // // Handle file input
    // const handleFileChange = (e) => {
    //     setFile(e.target.files[0]);
    // };

    // // Function to handle the file upload and show success message
    // const handleAddCategory = () => {
    //     if (file) {
    //         console.log('File uploaded:', file);
    //         setSuccessMessage(true);  // Show success message
    //         setTimeout(() => setSuccessMessage(false), 3000);  // Hide message after 3 seconds
    //         setShowModal(false);  // Close the modal
    //     }
    // };




    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");

    // Handle file selection
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // Handle file upload to the backend
    const handleFileUpload = async (event) => {
        event.preventDefault();
        if (!file) {
            setUploadStatus("Please select a CSV file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await fetch("https://api-ft5g.onrender.com/upload-csv", {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                setUploadStatus("File uploaded successfully!");
            } else {
                const errorResponse = await response.json();
                setUploadStatus(`Upload failed: ${errorResponse.message}`);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            setUploadStatus("An error occurred while uploading the file.");
        }
    };




    const [input, setData] = useState([]); // Ensure `input` starts as an empty array

    useEffect(() => {
        fetch('https://api-ft5g.onrender.com/GetCategoryandSubCategory')
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            })
            .then((docs) => {
                if (docs && docs.data) { // Check if docs.Data exists
                    setData(docs.data);
                    // setApiData(docs.data);
                    console.log(docs.data);
                } else {
                    console.warn("Data property not found in response:", docs);
                    setData([]); // Set to empty array if docs.Data is missing
                    // setApiData([]); // Same here
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setData([]); // Set to empty array on error
                // setApiData([]); // Set to empty array on error
            });
    }, []);




    const [editSubCategory, seteditSubCategory] = useState({ oldName: "", newName: "" }); // Stores data for editing

    // Handle edit category input change
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        seteditSubCategory((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle edit button click
    const handleEditClick = (categoryName) => {
        seteditSubCategory({ oldName: categoryName, newName: categoryName });
    };

    // Handle update category
    const handleUpdateSubCategory = (e) => {
        e.preventDefault();

        if (editSubCategory.oldName === editSubCategory.newName) {
            alert('No changes made to Sub-category name.');
            return;
        }

        // Send PUT request to update category
        fetch('https://api-ft5g.onrender.com/update-subcategories', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(editSubCategory)
        })
            .then(response => response.json())
            .then(data => {
                alert(data.message);
                // Update the local categories list
                setData((prevSubCategory) =>
                    prevSubCategory.map((subcategory) =>
                        subcategory.name === editSubCategory.oldName
                            ? { ...subcategory, name: editSubCategory.newName }
                            : subcategory
                    )
                );
            })
            .catch(error => {
                console.error('Error updating Sub-Category:', error);
                alert('Failed to update Sub-Category');
            });
    };


    return (
        <>
            {/* <Navbar collapseOnSelect expand="lg" className="navibar">
                <Container>
                    <Button onClick={scrollToTop} className="logoscrollbtn p-0">
                        <Navbar.Brand href="/">
                            <img src={Logo}
                                alt="logo"
                                className="d-inline-block align-top" />
                        </Navbar.Brand>
                    </Button> */}

            {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}

            {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
            {/* <Nav className="me-auto">
                    </Nav>

                    <Nav>
                        <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3">
                            <Link to="/Profile">
                                <img src={Account}
                                    alt="account"
                                    className="d-inline-block align-top"
                                />
                            </Link>
                        </Button>
                    </Nav> */}
            {/* </Navbar.Collapse> */}
            {/* </Container>
            </Navbar>

            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }} className="mt-3">
                <Sidebar onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint="always" className="side">
                    <Menu>

                        <MenuItem icon={< img src={dash}
                            alt=""
                            className="d-inline-block align-top"
                        />} component={<Link to="/AdminDash" />}> Dashboard</MenuItem>

                        <SubMenu icon={< img src={menu}
                            alt=""
                            className="d-inline-block align-top"
                        />} defaultOpen label="Menu">

                            <MenuItem component={<Link to="/Customers" />}> Customers</MenuItem>
                            <MenuItem component={<Link to="/Category" />}> Category</MenuItem>
                            <MenuItem component={<Link to="/SubCategory" />}> Sub-Category</MenuItem>
                            <MenuItem component={<Link to="/Brands" />}> Brands</MenuItem>
                            <MenuItem component={<Link to="/AdminProducts" />}> Products</MenuItem>
                            <MenuItem component={<Link to="/Quotations" />}> Quotations</MenuItem>
                        </SubMenu>
                    </Menu>
                </Sidebar>
                <main>
                    <div>
                        <Button className="sb-button menubutton" onClick={() => setToggled(!toggled)}>
                            <IoIosArrowForward className="mb-1" />Navigate
                        </Button>
                    </div>
                </main> */}

            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* <Nav className="me-auto">
                        </Nav> */}

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminDash" className="adminnavtext">Dashboard</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Customers" className="adminnavtext">Customers</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Category" className="adminnavtext">Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/SubCategory" className="adminnavtext">Sub-Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Brands" className="adminnavtext">Brands</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminProducts" className="adminnavtext">Products</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Quotations" className="adminnavtext">Enquiries</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminOrder" className="adminnavtext">Orders</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUsResponse" className="adminnavtext">Contact</Nav.Link>
                            </Button>


                            <Dropdown>
                                <Dropdown.Toggle className="logoscrollbtn p-0 mx-2 mt-1 responsiveview" id="dropdown-basic">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="/Profile">My Profile</Dropdown.Item> */}
                                    {/* <Dropdown.Item href="/OrderPage">Past Orders</Dropdown.Item> */}
                                    <Dropdown.Item href="/AdminLogin">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container className="mt-3">
                <Row>
                    <Col>
                        <Card>
                            <Row className="p-3">
                                <Col lg={7}>
                                    <p className="subcategory">Sub - Category</p>
                                </Col>

                                {/* Success message */}
                                {/* {successMessage && (
                                    <Alert variant="success" className="text-center">
                                        CSV Uploaded Successfully!
                                    </Alert>
                                )} */}

                                <Col className="" lg={5}>
                                    <Form onSubmit={handleFileUpload}>
                                        <Form.Label htmlFor="csvFile" className="categoryaddnewcatebtn">
                                            Add New Products Select a CSV File:
                                        </Form.Label>
                                        <input
                                            type="file"
                                            id="csvFile"
                                            accept=".csv"
                                            onChange={handleFileChange}
                                        />
                                        <Button type="submit" className="uploadbtn">
                                            Upload
                                        </Button>
                                    </Form>
                                    {uploadStatus && <p>{uploadStatus}</p>}
                                    {/* </Link>
                                    </Button> */}
                                </Col>
                            </Row>

                            {/* Modal for uploading CSV */}
                            {/* <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Upload Complete CSV</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group controlId="formFile">
                                            <Form.Label>Upload CSV</Form.Label>
                                            <Form.Control
                                                type="file"
                                                accept=".csv"
                                                onChange={handleFileChange}  // File input handler
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="success" onClick={handleAddCategory}>
                                        Add
                                    </Button>
                                </Modal.Footer>
                            </Modal> */}



                            {/* Edit brand modal */}
                            {editSubCategory.oldName && (
                                <div className="px-3 mb-4">
                                    <h4>Edit Sub-Category</h4>
                                    <form onSubmit={handleUpdateSubCategory}>
                                        <div>
                                            <label>
                                                Old Sub - Category Name:
                                                <input
                                                    type="text"
                                                    value={editSubCategory.oldName}
                                                    disabled
                                                    className="mx-2"
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                New Sub - Category Name:
                                                <input
                                                    type="text"
                                                    name="newName"
                                                    value={editSubCategory.newName}
                                                    onChange={handleEditChange}
                                                    required
                                                    className="mt-3 mx-2"
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <Button type="submit" variant="success" className="mt-3">Update</Button>
                                        </div>
                                    </form>
                                </div>
                            )}


                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Sr.No.</th>
                                        <th>Sub-Category Name</th>
                                        <th>Category Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        input.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.SUB_CATEGORY}</td>
                                                <td>{item.CATEGORY}</td>
                                                <td>
                                                    <Button variant="success" onClick={() => handleEditClick(item.SUB_CATEGORY)}>
                                                        Edit
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    {/* <tr>
                                            <td>1</td>
                                            <td>Sub - Category Name</td>*/}
                                    {/* <td>
                                            Category Name
                                        </td> */}
                                    {/* </tr> */}

                                    {/* <tr>
                                            <td>2</td>
                                            <td>Sub - Category Name</td>
                                            <td>Category Name</td>
                                        </tr>

                                        <tr>
                                            <td>3</td>
                                            <td>Sub - Category Name</td>
                                            <td> */}
                                    {/* <Row>
                                                    <Col lg={1} xs={4}>
                                                        <Button className="subcategoryviewbtn">
                                                            <Link className="subcategoryviewlink">
                                                                <FaRegEye />
                                                            </Link>
                                                        </Button>
                                                    </Col>

                                                    <Col lg={1} xs={4}>
                                                        <Button className="subcategorybinbtn">
                                                            <Link className="subcategorybinlink">
                                                                <FaTrashAlt />
                                                            </Link>
                                                        </Button>
                                                    </Col>

                                                    <Col lg={1} xs={4}>
                                                        <Button className="subcategorypenbtn">
                                                            <Link className="subcategorypenlink">
                                                                <LuPencil />
                                                            </Link>
                                                        </Button>
                                                    </Col>
                                                </Row> */}

                                    {/* Category Name
                                            </td>
                                        </tr> */}

                                    {/* <tr>
                                            <td>4</td>
                                            <td>Sub - Category Name</td>
                                            <td> */}
                                    {/* <Row>
                                                    <Col lg={1} xs={4}>
                                                        <Button className="subcategoryviewbtn">
                                                            <Link className="subcategoryviewlink">
                                                                <FaRegEye />
                                                            </Link>
                                                        </Button>
                                                    </Col>

                                                    <Col lg={1} xs={4}>
                                                        <Button className="subcategorybinbtn">
                                                            <Link className="subcategorybinlink">
                                                                <FaTrashAlt />
                                                            </Link>
                                                        </Button>
                                                    </Col>

                                                    <Col lg={1} xs={4}>
                                                        <Button className="subcategorypenbtn">
                                                            <Link className="subcategorypenlink">
                                                                <LuPencil />
                                                            </Link>
                                                        </Button>
                                                    </Col>
                                                </Row> */}

                                    {/* Category Name
                                            </td>
                                        </tr> */}

                                    {/* <tr>
                                            <td>5</td>
                                            <td>Sub - Category Name</td>
                                            <td> */}
                                    {/* <Row>
                                                    <Col lg={1} xs={4}>
                                                        <Button className="subcategoryviewbtn">
                                                            <Link className="subcategoryviewlink">
                                                                <FaRegEye />
                                                            </Link>
                                                        </Button>
                                                    </Col>

                                                    <Col lg={1} xs={4}>
                                                        <Button className="subcategorybinbtn">
                                                            <Link className="subcategorybinlink">
                                                                <FaTrashAlt />
                                                            </Link>
                                                        </Button>
                                                    </Col>

                                                    <Col lg={1} xs={4}>
                                                        <Button className="subcategorypenbtn">
                                                            <Link className="subcategorypenlink">
                                                                <LuPencil />
                                                            </Link>
                                                        </Button>
                                                    </Col>
                                                </Row> */}

                                    {/* Category Name
                                            </td>
                                        </tr> */}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* </div > */}
        </>
    );
}

export default SubCategory;