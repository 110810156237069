import React, { useState, useEffect } from "react";
import "./AdminProducts.css";
// import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from "react-router-dom";
import { Button, Navbar, Nav, Container, Row, Col, Card, Table, Form, Dropdown } from "react-bootstrap";
// import dash from "../src/Photos/Dashboard.png";
// import menu from "../src/Photos/Menu.png";
// import { IoIosArrowForward } from "react-icons/io";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";

// import { FaRegEye } from "react-icons/fa";
// import { FaTrashAlt } from "react-icons/fa";
// import { LuPencil } from "react-icons/lu";

function AdminProducts() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    // const [toggled, setToggled] = React.useState(false);



    // const [showModal, setShowModal] = useState(false);
    // const [file, setFile] = useState(null); // State to hold the uploaded file
    // const [successMessage, setSuccessMessage] = useState(false);

    // // Show/Hide Modal
    // const handleShowModal = () => setShowModal(true);
    // const handleCloseModal = () => setShowModal(false);

    // // Handle file input
    // const handleFileChange = (e) => {
    //     setFile(e.target.files[0]);
    // };

    // // Function to handle the file upload and show success message
    // const handleAddCategory = () => {
    //     if (file) {
    //         console.log('File uploaded:', file);
    //         setSuccessMessage(true);  // Show success message
    //         setTimeout(() => setSuccessMessage(false), 3000);  // Hide message after 3 seconds
    //         setShowModal(false);  // Close the modal
    //     }
    // };




    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");

    // Handle file selection
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // Handle file upload to the backend
    const handleFileUpload = async (event) => {
        event.preventDefault();
        if (!file) {
            setUploadStatus("Please select a CSV file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await fetch("https://api-ft5g.onrender.com/upload-csv", {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                setUploadStatus("File uploaded successfully!");
            } else {
                const errorResponse = await response.json();
                setUploadStatus(`Upload failed: ${errorResponse.message}`);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            setUploadStatus("An error occurred while uploading the file.");
        }
    };




    // const [filterVal, setFilterVal] = useState('');
    // const [ApiData, setApiData] = useState([]);
    const [input, setData] = useState([]); // Ensure `input` starts as an empty array

    useEffect(() => {
        fetch('https://api-ft5g.onrender.com/GetAllProducts')
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            })
            .then((docs) => {
                if (docs && docs.data) { // Check if docs.Data exists
                    setData(docs.data);
                    // setApiData(docs.data);
                    console.log(docs.data);
                } else {
                    console.warn("Data property not found in response:", docs);
                    setData([]); // Set to empty array if docs.Data is missing
                    // setApiData([]); // Same here
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setData([]); // Set to empty array on error
                // setApiData([]); // Set to empty array on error
            });
    }, []);




    const [editProduct, seteditProduct] = useState({ oldName: "", newName: "" }); // Stores data for editing

    // Handle edit category input change
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        seteditProduct((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle edit button click
    const handleEditClick = (categoryName) => {
        seteditProduct({ oldName: categoryName, newName: categoryName });
    };

    // Handle update category
    const handleUpdateProduct = (e) => {
        e.preventDefault();

        if (editProduct.oldName === editProduct.newName) {
            alert('No changes made to Product name.');
            return;
        }

        // Send PUT request to update category
        fetch('https://api-ft5g.onrender.com/update-product', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(editProduct)
        })
            .then(response => response.json())
            .then(data => {
                alert(data.message);
                // Update the local categories list
                setData((prevBrand) =>
                    prevBrand.map((brand) =>
                        brand.name === editProduct.oldName
                            ? { ...brand, name: editProduct.newName }
                            : brand
                    )
                );
            })
            .catch(error => {
                console.error('Error updating Product:', error);
                alert('Failed to update Product');
            });
    };



    // Filter list
    const [input2, setInput] = useState([]); // Initial state for all products
    const [filteredData, setFilteredData] = useState([]); // Filtered data state
    const [ApiData, setApiData] = useState([]); // Categories
    const [ApiData2, setApiData2] = useState([]); // Sub-Categories
    const [ApiData3, setApiData3] = useState([]); // Brands

    const [category, setCategory] = useState(''); // Category filter state
    const [subCategory, setSubCategory] = useState(''); // Sub-category filter state
    const [brand, setBrand] = useState(''); // Brand filter state

    // Fetch All Products on Mount
    useEffect(() => {
        fetch('https://api-ft5g.onrender.com/GetAllProducts')
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            })
            .then((docs) => {
                if (docs && docs.data) {
                    setInput(docs.data); // Original data
                    setFilteredData(docs.data); // Set initial filtered data
                } else {
                    console.warn("Data property not found in response:", docs);
                    setInput([]);
                    setFilteredData([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setInput([]);
                setFilteredData([]);
            });
    }, []);

    // Fetch Categories
    useEffect(() => {
        fetch('https://api-ft5g.onrender.com/GetCategory')
            .then((res) => res.json())
            .then((docs) => setApiData(docs.data))
            .catch((error) => console.error("Error fetching categories:", error));
    }, []);

    // Fetch Sub-Categories when Category Changes
    useEffect(() => {
        if (category) {
            fetch(`https://api-ft5g.onrender.com/GetSubCategory?category=${category}`)
                .then((res) => res.json())
                .then((docs) => setApiData2(docs.data))
                .catch((error) => console.error("Error fetching sub-categories:", error));
        } else {
            setApiData2([]);
        }
    }, [category]);

    // Fetch Brands when Category or Sub-Category Changes
    useEffect(() => {
        if (category) {
            const url = subCategory
                ? `https://api-ft5g.onrender.com/GetBrand?category=${category}&subCategory=${subCategory}`
                : `https://api-ft5g.onrender.com/GetBrand?category=${category}`;

            fetch(url)
                .then((res) => res.json())
                .then((docs) => setApiData3(docs.data))
                .catch((error) => console.error("Error fetching brands:", error));
        } else {
            setApiData3([]);
        }
    }, [category, subCategory]);

    // Apply Filter
    const handleFilter = () => {
        console.log("Applying filters...");
        console.log("Selected Category:", category);
        console.log("Selected Sub-Category:", subCategory);
        console.log("Selected Brand:", brand);

        // Start filtering from the original dataset
        let filteredResults = input2;

        // Apply category filter
        if (category && category !== "SELECT") {
            filteredResults = filteredResults.filter(
                (item) => item.CATEGORY.toLowerCase() === category.toLowerCase()
            );
        }

        // Apply sub-category filter
        if (subCategory && subCategory !== "SELECT") {
            filteredResults = filteredResults.filter(
                (item) => item.SUB_CATEGORY.toLowerCase() === subCategory.toLowerCase()
            );
        }

        // Apply brand filter
        if (brand && brand !== "SELECT") {
            filteredResults = filteredResults.filter(
                (item) => item.BRAND.toLowerCase() === brand.toLowerCase()
            );
        }

        console.log("Filtered Results:", filteredResults);

        // Update the state with filtered data
        setFilteredData(filteredResults);
    };

    const resetFilters = () => {
        console.log("Resetting filters...");
        setCategory("");
        setSubCategory("");
        setBrand("");
        setFilteredData(input2); // Reset to original data
    };


    return (
        <>
            {/* <Navbar collapseOnSelect expand="lg" className="navibar">
                <Container>
                    <Button onClick={scrollToTop} className="logoscrollbtn p-0">
                        <Navbar.Brand href="/">
                            <img src={Logo}
                                alt="logo"
                                className="d-inline-block align-top" />
                        </Navbar.Brand>
                    </Button> */}

            {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}

            {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
            {/* <Nav className="me-auto">
                    </Nav>

                    <Nav>
                        <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3">
                            <Link to="/Profile">
                                <img src={Account}
                                    alt="account"
                                    className="d-inline-block align-top"
                                />
                            </Link>
                        </Button>
                    </Nav> */}
            {/* </Navbar.Collapse> */}
            {/* </Container>
            </Navbar>

            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }} className="mt-3">
                <Sidebar onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint="always" className="side">
                    <Menu>

                        <MenuItem icon={< img src={dash}
                            alt=""
                            className="d-inline-block align-top"
                        />} component={<Link to="/AdminDash" />}> Dashboard</MenuItem>

                        <SubMenu icon={< img src={menu}
                            alt=""
                            className="d-inline-block align-top"
                        />} defaultOpen label="Menu">

                            <MenuItem component={<Link to="/Customers" />}> Customers</MenuItem>
                            <MenuItem component={<Link to="/Category" />}> Category</MenuItem>
                            <MenuItem component={<Link to="/SubCategory" />}> Sub-Category</MenuItem>
                            <MenuItem component={<Link to="/Brands" />}> Brands</MenuItem>
                            <MenuItem component={<Link to="/AdminProducts" />}> Products</MenuItem>
                            <MenuItem component={<Link to="/Quotations" />}> Quotations</MenuItem>
                        </SubMenu>
                    </Menu>
                </Sidebar>
                <main>
                    <div>
                        <Button className="sb-button menubutton" onClick={() => setToggled(!toggled)}>
                            <IoIosArrowForward className="mb-1" />Navigate
                        </Button>
                    </div>
                </main> */}


            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* <Nav className="me-auto">
                        </Nav> */}

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminDash" className="adminnavtext">Dashboard</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Customers" className="adminnavtext">Customers</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Category" className="adminnavtext">Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/SubCategory" className="adminnavtext">Sub-Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Brands" className="adminnavtext">Brands</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminProducts" className="adminnavtext">Products</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Quotations" className="adminnavtext">Enquiries</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminOrder" className="adminnavtext">Orders</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUsResponse" className="adminnavtext">Contact</Nav.Link>
                            </Button>


                            <Dropdown>
                                <Dropdown.Toggle className="logoscrollbtn p-0 mx-2 mt-1 responsiveview" id="dropdown-basic">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="/Profile">My Profile</Dropdown.Item> */}
                                    {/* <Dropdown.Item href="/OrderPage">Past Orders</Dropdown.Item> */}
                                    <Dropdown.Item href="/AdminLogin">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container fluid>
                <Row className="mb-5">
                    <Col className="mt-3 filter">
                        <Card className="filtercard">
                            <Row className="px-3 mb-1">
                                {/* Category Filter */}
                                <Col lg={1} xs={3} className="mt-4">
                                    <Form.Label htmlFor="categorySelect" className="filtertext">Category</Form.Label>
                                </Col>
                                <Col lg={2} xs={3} className="mt-3">
                                    <Form.Select
                                        id="categorySelect"
                                        aria-label="Select Category"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    >
                                        <option value="">SELECT</option>
                                        {ApiData.map((name, index) => (
                                            <option key={index} value={name.name}>
                                                {name.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>

                                {/* Sub-Category Filter */}
                                <Col lg={1} xs={3} className="mt-2">
                                    <Form.Label htmlFor="subCategorySelect" className="filtertext">Sub - Category</Form.Label>
                                </Col>
                                <Col lg={2} xs={3} className="mt-3">
                                    <Form.Select
                                        id="subCategorySelect"
                                        aria-label="Select Sub-Category"
                                        value={subCategory}
                                        onChange={(e) => setSubCategory(e.target.value)}
                                    >
                                        <option value="">SELECT</option>
                                        {ApiData2.map((name, index) => (
                                            <option key={index} value={name.SUB_CATEGORY}>
                                                {name.SUB_CATEGORY}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>

                                {/* Brand Filter */}
                                <Col lg={1} xs={3} className="mt-4">
                                    <Form.Label htmlFor="brandSelect" className="filtertext">Brands</Form.Label>
                                </Col>
                                <Col lg={2} xs={3} className="mt-3">
                                    <Form.Select
                                        id="brandSelect"
                                        aria-label="Select Brand"
                                        value={brand}
                                        onChange={(e) => setBrand(e.target.value)}
                                    >
                                        <option value="">SELECT</option>
                                        {ApiData3.map((name, index) => (
                                            <option key={index} value={name.BRAND}>
                                                {name.BRAND}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>

                                {/* Buttons */}
                                <Col className="mt-3">
                                    <Button onClick={handleFilter} className="applyfilterbtn">
                                        Apply Filter
                                    </Button>
                                </Col>

                                <Col className="mt-3">
                                    <Button onClick={resetFilters} className="resetbtn ms-2 applyfilterbtn">
                                        Reset
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="mt-3">
                <Row>
                    <Col>
                        <Card className="mt-5">
                            <Row className="p-3">
                                <Col lg={7}>
                                    <p className="adminproducts">Products</p>
                                </Col>

                                {/* Success message */}
                                {/* {successMessage && (
                                    <Alert variant="success" className="text-center">
                                        CSV Uploaded Successfully!
                                    </Alert>
                                )} */}

                                <Col lg={5} className="">
                                    {/* <Button className="brandaddnewcatebtn" onClick={handleShowModal}>
                                        <Link className="brandaddnewcatelink"> */}
                                    <Form onSubmit={handleFileUpload}>
                                        <Form.Label htmlFor="csvFile" className="brandaddnewcatebtn">
                                            Add New Products Select a CSV File:
                                        </Form.Label>
                                        <input
                                            type="file"
                                            id="csvFile"
                                            accept=".csv"
                                            onChange={handleFileChange}
                                        />
                                        <Button type="submit" className="uploadbtn">
                                            Upload
                                        </Button>
                                    </Form>
                                    {uploadStatus && <p>{uploadStatus}</p>}
                                    {/* </Link>
                                    </Button> */}
                                </Col>
                            </Row>

                            {/* Modal for uploading CSV */}
                            {/* <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Upload Complete CSV</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group controlId="formFile">
                                            <Form.Label>Upload CSV</Form.Label>
                                            <Form.Control
                                                type="file"
                                                accept=".csv"
                                                onChange={handleFileChange}  // File input handler
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="success" onClick={handleAddCategory}>
                                        Add
                                    </Button>
                                </Modal.Footer>
                            </Modal> */}


                            {/* Edit brand modal */}
                            {editProduct.oldName && (
                                <div className="px-3 mb-4">
                                    <h4>Edit Brand</h4>
                                    <form onSubmit={handleUpdateProduct}>
                                        <div>
                                            <label>
                                                Old Brand Name:
                                                <input
                                                    type="text"
                                                    value={editProduct.oldName}
                                                    disabled
                                                    className="mx-2"
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                New Brand Name:
                                                <input
                                                    type="text"
                                                    name="newName"
                                                    value={editProduct.newName}
                                                    onChange={handleEditChange}
                                                    required
                                                    className="mt-3 mx-2"
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <Button type="submit" variant="success" className="mt-3">Update</Button>
                                        </div>
                                    </form>
                                </div>
                            )}

                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>SKU Code</th>
                                        <th>Product Name</th>
                                        <th>Unit Per Carton</th>
                                        <th>Unit</th>
                                        <th>Weight Per Pkt Gms</th>
                                        <th>Unit Measurement Type</th>
                                        <th>Category Name</th>
                                        <th>SubCategory Name</th>
                                        <th>Brand Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        filteredData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.SKU_CODE}</td>
                                                <td>{item.PRODUCT_DESCRIPTION}</td>
                                                <td>{item.UNIT_PER_CTN}</td>
                                                <td>{item.UNIT}</td>
                                                <td>{item.WEIGHT_PER_PKT_GRAMS}</td>
                                                <td>{item.UNIT_MEASUREMENT_TYPE}</td>
                                                <td>{item.CATEGORY}</td>
                                                <td>{item.SUB_CATEGORY}</td>
                                                <td>{item.BRAND}</td>
                                                <td>
                                                    <Button variant="success" onClick={() => handleEditClick(item.PRODUCT_DESCRIPTION)}>
                                                        Edit
                                                    </Button>
                                                </td>
                                                {/* <td> */}
                                                {/* <Row> */}
                                                {/* <Col lg={1} xs={4}>
                                                        <Button className="adminproductsviewbtn">
                                                            <Link className="adminproductsviewlink">
                                                                <FaRegEye />
                                                            </Link>
                                                        </Button>
                                                    </Col> */}

                                                {/* <Col lg={1} xs={4}>
                                                        <Button className="adminproductsbinbtn">
                                                            <Link className="adminproductsbinlink">
                                                                <FaTrashAlt />
                                                            </Link>
                                                        </Button>
                                                    </Col> */}

                                                {/* <Col lg={1} xs={4}>
                                                        <Button className="adminproductspenbtn">
                                                            <Link className="adminproductspenlink">
                                                                <LuPencil />
                                                            </Link>
                                                        </Button>
                                                    </Col> */}
                                                {/* </Row> */}
                                                {/* </td> */}
                                            </tr>
                                        ))
                                    }

                                    <tr>
                                        <td>2</td>
                                        <td>Product Name</td>
                                        <td>Category Name</td>
                                        <td>SubCategory Name</td>
                                        <td>Brand Name</td>
                                        <td>
                                            <Row>
                                                {/* <Col lg={1} xs={4}>
                                                        <Button className="adminproductsviewbtn">
                                                            <Link className="adminproductsviewlink">
                                                                <FaRegEye />
                                                            </Link>
                                                        </Button>
                                                    </Col> */}

                                                {/* <Col lg={1} xs={4}>
                                                    <Button className="adminproductsbinbtn">
                                                        <Link className="adminproductsbinlink">
                                                            <FaTrashAlt />
                                                        </Link>
                                                    </Button>
                                                </Col> */}

                                                {/* <Col lg={1} xs={4}>
                                                        <Button className="adminproductspenbtn">
                                                            <Link className="adminproductspenlink">
                                                                <LuPencil />
                                                            </Link>
                                                        </Button>
                                                    </Col> */}
                                            </Row>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* </div > */}
        </>
    );
}

export default AdminProducts;