import React, { useEffect, useState } from "react";
import "./ContactUsResponse.css";
import { Button, Container, Dropdown, Nav, Navbar, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";

function ContactUsResponse() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };


    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    // Fetch messages from the backend
    const fetchMessages = async () => {
        try {
            const response = await fetch("https://api-ft5g.onrender.com/Get-contact-us-messages");
            const data = await response.json();

            if (response.ok) {
                setMessages(data);
            } else {
                setError(data.error || "Failed to fetch messages.");
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
            setError("An error occurred while fetching the messages.");
        } finally {
            setLoading(false);
        }
    };

    // Use useEffect to fetch messages on component mount
    useEffect(() => {
        fetchMessages();
    }, []);

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* <Nav className="me-auto">
                        </Nav> */}

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminDash" className="adminnavtext">Dashboard</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Customers" className="adminnavtext">Customers</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Category" className="adminnavtext">Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/SubCategory" className="adminnavtext">Sub-Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Brands" className="adminnavtext">Brands</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminProducts" className="adminnavtext">Products</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Quotations" className="adminnavtext">Enquiries</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminOrder" className="adminnavtext">Orders</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUsResponse" className="adminnavtext">Contact</Nav.Link>
                            </Button>


                            <Dropdown>
                                <Dropdown.Toggle className="scrollbtn2 p-0 mx-2 mt-1 responsiveview" id="dropdown-basic">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="/Profile">My Profile</Dropdown.Item> */}
                                    {/* <Dropdown.Item href="/OrderPage">Past Orders</Dropdown.Item> */}
                                    <Dropdown.Item href="/AdminLogin">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container fluid>
                {loading && <p>Loading messages...</p>}
                {error && <p className="text-danger">{error}</p>}
                {!loading && !error && messages.length === 0 && <p>No messages found.</p>}
                {!loading && !error && messages.length > 0 && (
                    <Table responsive className="mt-3">
                        <thead>
                            <tr>
                                <th className="">
                                    Sr.No.
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Email ID
                                </th>
                                <th>
                                    Contact No.
                                </th>
                                <th>
                                    Message
                                </th>
                                <th>
                                    Date
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {messages.map((message) => (
                                <tr key={message.ContactUsID}>
                                    <td>{message.ContactUsID}</td>
                                    <td>{message.Name}</td>
                                    <td>{message.Email}</td>
                                    <td>{message.ContactNumber}</td>
                                    <td>{message.Message}</td>
                                    <td>{new Date(message.CreatedAt).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Container>
        </>
    );
}

export default ContactUsResponse;