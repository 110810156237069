import React, { useEffect, useState } from "react";
import "./Quotations.css";
// import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useNavigate } from "react-router-dom";
import { Button, Navbar, Nav, Container, Row, Col, Card, Table, Dropdown } from "react-bootstrap";
// import dash from "../src/Photos/Dashboard.png";
// import menu from "../src/Photos/Menu.png";
// import { IoIosArrowForward } from "react-icons/io";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";


// import { FaRegEye } from "react-icons/fa";
// import { FaTrashAlt } from "react-icons/fa";
// import { LuPencil } from "react-icons/lu";

function Quotations() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    // const [toggled, setToggled] = React.useState(false);

    const [input, setData] = useState([]); // Ensure `input` starts as an empty array
    const navigate = useNavigate();

    useEffect(() => {
        fetch("https://api-ft5g.onrender.com/Get-data-for-enquiry-page")
            .then((res) => res.json())
            .then((docs) => {
                if (docs && docs.data) {
                    setData(docs.data);
                    console.log(docs.data);
                } else {
                    console.warn("Data property not found in response:", docs);
                    setData([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setData([]);
            });
    }, []);

    // const handleViewOrder = (OrderID) => {
    //     console.log("OrderID received:", OrderID); // Debug log
    //     if (OrderID) {
    //         // Store OrderID in localStorage
    //         sessionStorage.setItem("OrderID", OrderID);
    //         console.log("OrderID stored in sessionStorage:", sessionStorage.getItem("OrderID"));

    //         // Navigate to Order Details Page
    //         navigate("/ViewQuotationEnquiry");
    //     } else {
    //         console.warn("OrderID is undefined or null");
    //     }
    // }

    const handleViewOrder = (orderId, userId) => {
        // Store OrderID in localStorage
        localStorage.setItem("selectedOrderId", orderId);

        // Navigate to Order Details Page
        navigate(`/ViewQuotationEnquiry/${orderId}/${userId}`);
    };

    return (
        <>
            {/* <Navbar collapseOnSelect expand="lg" className="navibar">
                <Container>
                    <Button onClick={scrollToTop} className="logoscrollbtn p-0">
                        <Navbar.Brand href="/">
                            <img src={Logo}
                                alt="logo"
                                className="d-inline-block align-top" />
                        </Navbar.Brand>
                    </Button> */}

            {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}

            {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
            {/* <Nav className="me-auto">
                    </Nav> */}

            {/* <Nav>
                        <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3">
                            <Link to="/Profile">
                                <img src={Account}
                                    alt="account"
                                    className="d-inline-block align-top"
                                />
                            </Link>
                        </Button>
                    </Nav> */}
            {/* </Navbar.Collapse> */}
            {/* </Container>
            </Navbar>

            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }} className="mt-3">
                <Sidebar onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint="always" className="side">
                    <Menu>

                        <MenuItem icon={< img src={dash}
                            alt=""
                            className="d-inline-block align-top"
                        />} component={<Link to="/AdminDash" />}> Dashboard</MenuItem>

                        <SubMenu icon={< img src={menu}
                            alt=""
                            className="d-inline-block align-top"
                        />} defaultOpen label="Menu">

                            <MenuItem component={<Link to="/Customers" />}> Customers</MenuItem>
                            <MenuItem component={<Link to="/Category" />}> Category</MenuItem>
                            <MenuItem component={<Link to="/SubCategory" />}> Sub-Category</MenuItem>
                            <MenuItem component={<Link to="/Brands" />}> Brands</MenuItem>
                            <MenuItem component={<Link to="/AdminProducts" />}> Products</MenuItem>
                            <MenuItem component={<Link to="/Quotations" />}> Quotations</MenuItem>
                        </SubMenu>
                    </Menu>
                </Sidebar>
                <main>
                    <div>
                        <Button className="sb-button menubutton" onClick={() => setToggled(!toggled)}>
                            <IoIosArrowForward className="mb-1" />Navigate
                        </Button>
                    </div>
                </main> */}


            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* <Nav className="me-auto">
                        </Nav> */}

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminDash" className="adminnavtext">Dashboard</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Customers" className="adminnavtext">Customers</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Category" className="adminnavtext">Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/SubCategory" className="adminnavtext">Sub-Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Brands" className="adminnavtext">Brands</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminProducts" className="adminnavtext">Products</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Quotations" className="adminnavtext">Enquiries</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminOrder" className="adminnavtext">Orders</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUsResponse" className="adminnavtext">Contact</Nav.Link>
                            </Button>


                            <Dropdown>
                                <Dropdown.Toggle className="logoscrollbtn p-0 mx-2 mt-1 responsiveview" id="dropdown-basic">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="/Profile">My Profile</Dropdown.Item> */}
                                    {/* <Dropdown.Item href="/OrderPage">Past Orders</Dropdown.Item> */}
                                    <Dropdown.Item href="/AdminLogin">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container fluid className="mt-3">
                <Row>
                    <Col>
                        <Card>
                            <Row className="p-3">
                                <Col lg={6} xs={6}>
                                    <p className="quotations">ENQUIRIES</p>
                                </Col>
                            </Row>

                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th className="">
                                            Order ID
                                        </th>

                                        <th>
                                            User ID
                                        </th>

                                        <th>
                                            Email ID
                                        </th>

                                        <th>
                                            Customer Name
                                        </th>

                                        <th>
                                            Company Name
                                        </th>

                                        <th>
                                            Enquiry Date
                                        </th>

                                        <th>
                                            View
                                        </th>

                                        {/* <th>
                                            Deal Status
                                        </th> */}
                                        {/* <th>
                                            Action
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {input.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p className="mt-2 ccn">{item.OrderID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.UserID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.EmailID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.FirstName} {item.LastName}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.CompanyName}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{new Date(item.UploadDate).toLocaleString()}</p>
                                            </td>


                                            <td>
                                                <Button
                                                    className="mt-1 dotbtn"
                                                    onClick={() => handleViewOrder(item.OrderID, item.UserID)}
                                                >
                                                    {/* <Link
                                                        to={`/ViewQuotationEnquiry/${item.UserID}`}
                                                        className="ebtn"
                                                    > */}
                                                    View Enquiry / Quotations
                                                    {/* </Link> */}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}

                                    <tr>
                                        <td>
                                            <p className="mt-2 ccn">#06</p>
                                        </td>

                                        <td>
                                            <p className="mt-2 ccn">MNO</p>
                                        </td>

                                        <td>
                                            <p className="mt-2 ccn">Aug 27, 2024</p>
                                        </td>


                                        <td>
                                            <Button onClick={scrollToTop} className="mt-1 dotbtn">
                                                <Link to="/ViewQuotationEnquiry" className="dotlink">
                                                    View Order / Quotations
                                                </Link>
                                            </Button>
                                        </td>

                                        {/* <td>
                                            <Card className="complete text-center mt-2">Completed</Card>
                                        </td> */}
                                        <td>
                                            <Row>
                                                {/* <Col lg={2} xs={4}>
                                                        <Button className="quotationsviewbtn">
                                                            <Link className="quotationsviewlink">
                                                                <FaRegEye />
                                                            </Link>
                                                        </Button>
                                                    </Col> */}

                                                {/* <Col lg={2} xs={4}>
                                                    <Button className="quotationsbinbtn">
                                                        <Link className="quotationsbinlink">
                                                            <FaTrashAlt />
                                                        </Link>
                                                    </Button>
                                                </Col> */}

                                                {/* <Col lg={2} xs={4}>
                                                        <Button className="quotationspenbtn">
                                                            <Link className="quotationspenlink">
                                                                <LuPencil />
                                                            </Link>
                                                        </Button>
                                                    </Col> */}
                                            </Row>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* </div> */}
        </>
    );
}

export default Quotations;