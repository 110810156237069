import React, { useEffect, useState } from "react";
import "./Login.css";
import { Card, Col, Container, Row, Form, Button, Navbar, Nav, Dropdown } from "react-bootstrap";
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import container from "../src/Photos/Container.png";
import Account from "../src/Photos/Account.png";

function Login() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const [username, setUsername] = useState(""); // Username (email)
    const [password, setPassword] = useState(""); // Password
    const [responseMessage, setResponseMessage] = useState(""); // Message for success or error
    const navigate = useNavigate(); // For navigation

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload

        // Login data to send to the server
        const loginData = { EmailID: username, Password: password };

        try {
            const response = await fetch("https://api-ft5g.onrender.com/loginuser", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginData),
            });

            const result = await response.json();

            if (response.ok) {
                // Successful login
                console.log("API Response:", result);

                // Extract user details from the response
                const userId = result.user?.UserID; // Ensure the response contains `UserID`
                const token = result.token;
                const emailID = result.user?.EmailID || result?.user?.EmailID;  // Extract EmailID (username)
                console.log("Token:", token);

                // Check if UserID exists and store it in localStorage
                if (userId) {
                    localStorage.setItem("userId", userId);
                    console.log("User ID stored:", userId);  // Log for debugging
                } else {
                    console.error("User ID is not present in the response.");
                }

                if (token) {
                    localStorage.setItem("token", token);
                    console.log("Token stored:", token);  // Log for debugging
                } else {
                    console.error("Token is not present in the response.");
                }

                if (emailID) {
                    localStorage.setItem("username", emailID); // Store EmailID
                    console.log("EmailID stored:", emailID);
                } else {
                    console.error("EmailID is not present in the response.");
                }

                // Redirect to the homepage or dashboard
                navigate("/"); // Adjust based on your routing
            } else {
                // Handle API errors
                setResponseMessage(result.message || "Login failed. Please try again!");
            }
        } catch (error) {
            // Handle network or other errors
            console.error("Error during login:", error);
            setResponseMessage("An error occurred. Please try again later.");
        }
    };


    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in when the component mounts
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        setIsLoggedIn(!!(token && userId)); // Set `isLoggedIn` based on the existence of token and userId
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            alert("You are already logged in!"); // Show an alert if already logged in
        } else {
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        localStorage.removeItem("username"); // Remove stored EmailID
        console.log("User logged out and data removed from localStorage.");
        setIsLoggedIn(false);
        navigate("/Login"); // Redirect to login
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container fluid className="backdesign">
                <Row className="justify-content-center">
                    {/* <Col className="p-0" lg={6} md={6}>
                        <Card className="firstcard">
                            <img src={Logo}
                                alt="logo"
                                className="d-inline-block align-top w-50"
                            />
                        </Card>
                    </Col> */}

                    <Col className="p-0 mb-4" lg={5} md={6} xs={12}>
                        <Card className="p-5 rightcard">
                            <Row>
                                <Col>
                                    <Card className="mt-3 p-5 rightinsidecard">
                                        <Row>
                                            <Col className="text-center mt-2">
                                                <p className="login">Log In</p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="mt-3">
                                                <Form.Label className="emailid">
                                                    Email ID
                                                </Form.Label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter Your Email ID"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    required
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="mt-3">
                                                <Form.Label className="password">
                                                    Password
                                                </Form.Label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Enter Your Password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </Col>
                                        </Row>

                                        {/* Show response message */}
                                        {responseMessage && (
                                            <Row>
                                                <Col className="text-center mt-3 text-danger">
                                                    <p>{responseMessage}</p>
                                                </Col>
                                            </Row>
                                        )}

                                        <Row>
                                            <Col className="text-end">
                                                <Link to="/forgot-password">
                                                    <p className="forgetpassword">Forget Password?</p>
                                                </Link>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="text-centerx">
                                                {/* <Link to="/"> */}
                                                <Button className="w-100 loginbtn"
                                                    onClick={handleSubmit} // Call handleSubmit on button click
                                                >
                                                    Log In
                                                </Button>
                                                {/* </Link> */}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="text-center mt-4">
                                                <p className="line">Don't Have an Account?
                                                    <Link to="/Signup" className="signuplink">Sign Up</Link>
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Login;