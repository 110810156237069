import React, { useState } from "react";
import "./AdminLogin.css";
import { Button, Card, Col, Container, Row, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function AdminLogin() {

    const [Username, setUsername] = useState("");
    const [Password, setPassword] = useState("");
    const [responseMessage, setResponseMessage] = useState("");
    const navigate = useNavigate(); // useNavigate hook for navigation

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        // API endpoint for login
        //   const apiUrl = "/api/adminLogin"; // Replace with your actual API endpoint

        const loginData = { Username, Password };

        try {
            // Make the POST request
            const response = await fetch("https://api-ft5g.onrender.com/adminlogin", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginData),
            });

            const result = await response.json();

            if (response.ok) {
                // If login is successful, redirect to Admin Dashboard
                navigate("/AdminDash"); // useNavigate for navigation
            } else {
                // Show the error message from the API
                setResponseMessage(result.message || "Login failed, try again!");
            }
        } catch (error) {
            console.error("Error during login:", error);
            setResponseMessage("An error occurred, please try again.");
        }
    };

    return (
        <>
            <Container>
                <Row className="justify-content-center">
                    <Col className="p-5 mt-5" lg={6} md={6} xs={12}>
                        <Card className="mt-3 p-5 rightinsidecard">
                            <Row>
                                <Col className="text-center mt-4">
                                    <p className="login">Admin Log In</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-3">
                                    <Form.Label className="emailid">
                                        Username
                                    </Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Control
                                        type="username"
                                        placeholder="Enter Your Username"
                                        value={Username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-3">
                                    <Form.Label className="password">
                                        Password
                                    </Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter Your Password"
                                        value={Password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </Col>
                            </Row>

                            {/* Show response message */}
                            {responseMessage && (
                                <Row>
                                    <Col className="text-center mt-3 text-danger">
                                        <p>{responseMessage}</p>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col className="text-center mt-5 mb-5">
                                    <Link to="/AdminDash">
                                        <Button className="w-100 loginbtn"
                                            onClick={handleSubmit} // Call handleSubmit on button click
                                        >
                                            Log In
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AdminLogin;