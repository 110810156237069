import React, { useEffect, useState } from "react";
import "./ProductCatalogue.css";
import { Container, Nav, Button, Navbar, Row, Col, Dropdown, Card } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import Account from "../src/Photos/Account.png";
import container from "../src/Photos/Container.png";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";

function ProductCatalogue() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate("/Products"); // Replace "/target-path" with your desired route
    };

    // Function to handle the download
    const handleDownload = () => {
        // Opens the backend endpoint that serves the file
        // window.open('https://api-ft5g.onrender.com/download-catalogue');

        if (isLoggedIn) {
            window.location.href = 'https://api-ft5g.onrender.com/download-catalogue'; // Update with your file path
        } else {
            alert("Please log in to download the product catalogue.");
            navigate("/Login"); // Redirect to login page
        }
    };

    const categories = [
        {
            title: "SPICES",
            image: "https://media.istockphoto.com/id/497184266/photo/indian-spices-in-wooden-trays.jpg?s=612x612&w=0&k=20&c=No_p6ymchJimJGi2HzzbLEm9w52Lrp1cCAbOgvOoW0M=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/SPICES"
        },
        {
            title: "PULSES & DALS",
            image: "https://images.unsplash.com/photo-1705475388190-775066fd69a5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cHVsc2VzJTIwYW5kJTIwZGFsc3xlbnwwfDB8MHx8fDA%3D",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/PULSES & DALS"
        },
        {
            title: "DRY SNACKS",
            image: "https://www.hotelierindia.com/cloud/2024/10/20/indian-tea-time-snacks-in-group-sev-chivda-farsan-mixture-bakarwadi-served-in-different-bowls-free-photo-1.jpg",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/DRY SNACKS"
        },
        {
            title: "MILK MIXTURES",
            image: "https://www.eximtrac.com/images/grocery/tea-coffee.jpg",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/MILK MIXTURES"
        },
        {
            title: "BAKERY",
            image: "https://www.eximtrac.com/images/grocery/bak.jpg",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/BAKERY"
        },
        {
            title: "CONFECTIONERY",
            image: "https://www.eximtrac.com/images/grocery/conf.jpg",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/CONFECTIONERY"
        },
        {
            title: "SWEETNER",
            image: "https://www.naario.com/cdn/shop/articles/images_6db636f6-525b-4708-9b27-365bdd8d02b3.jpg?v=1696321310",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/SWEETNER"
        },
        {
            title: "OIL / GHEE",
            image: "https://media.istockphoto.com/id/1413268611/photo/ghee-butter-oil.webp?a=1&b=1&s=612x612&w=0&k=20&c=t1C7Tzg3Ym0_bro9wV1JSwvELqpfQwqv5kpogbrMT3Q=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/OIL & GHEE"
        },
        {
            title: "PAPAD & FRYUMS",
            image: "https://media.istockphoto.com/id/1150014655/photo/colorful-variety-of-fryums-ready-to-fry-snacks-in-an-indian-market.jpg?s=612x612&w=0&k=20&c=Ly-wlAeTPHGe0dbLHUcfOlbkooLIVsEya7ljlLbkScc=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/PAPAD & FRYUMS"
        },
        {
            title: "FLOURS",
            image: "https://media.istockphoto.com/id/186831897/photo/flour-and-wheat-grains.webp?a=1&b=1&s=612x612&w=0&k=20&c=XRT7ZyZ4Az7pAKptVXnsmcogDwwMBgqNncX7YuJJmFo=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/FLOURS"
        },
        {
            title: "FOOD ENHANCERS",
            image: "https://www.mommieswithcents.com/wp-content/uploads/2012/08/MommiesWithCents_FoodColors.jpg",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/FOOD ENHANCERS"
        },
        {
            title: "SWEETS",
            image: "https://media.istockphoto.com/id/1054228718/photo/indian-sweets-in-a-plate-includes-gulab-jamun-rasgulla-kaju-katli-morichoor-bundi-laddu.jpg?s=612x612&w=0&k=20&c=hYWCXLaldKvhxdBa83M0RnUij7BCmhf-ywWdvyIXR40=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/SWEETS"
        },
        {
            title: "FOOD SIDERS",
            image: "https://img2.exportersindia.com/product_images/bc-full/dir_80/2370564/pickles-chutney-753543.jpg",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/FOOD SIDERS"
        },
        {
            title: "HOT SNACKS",
            image: "https://media.istockphoto.com/id/1497707611/photo/white-sauce-red-sauce-pasta-closeup-photo.jpg?s=612x612&w=0&k=20&c=8A7jJbWOROLD_FH1JgFiv-eTuGE9W_1V6qpvj05vB3k=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/HOT SNACKS"
        },
        {
            title: "INSTANT FOOD",
            image: "https://images.unsplash.com/photo-1569058242567-93de6f36f8e6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGluc3RhbnQlMjBmb29kfGVufDB8MHwwfHx8MA%3D%3D",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/INSTANT FOOD"
        },
        {
            title: "RICE",
            image: "https://media.istockphoto.com/id/638936684/photo/rice-on-the-dark-background-healthy-eating-and-lifestyle.jpg?s=612x612&w=0&k=20&c=mDU95_u8HfMlToatbB1byJOHZCiJdu29hjy_VhMJlds=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/RICE"
        },
        {
            title: "HEALTH CARE",
            image: "https://plus.unsplash.com/premium_photo-1673953509975-576678fa6710?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aGVhbHRoY2FyZXxlbnwwfDB8MHx8fDA%3D",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/HEALTHCARE"
        },
        {
            title: "PERSONAL CARE",
            image: "https://media.istockphoto.com/id/584574708/photo/soap-bar-and-liquid-shampoo-shower-gel-towels-spa-kit.jpg?s=612x612&w=0&k=20&c=TFeQmTwVUwKY0NDKFFORe3cwDCxRtotFgEujMswn3dc=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/PERSONAL CARE"
        },
        {
            title: "CLEANING & HOUSEHOLD",
            image: "https://plus.unsplash.com/premium_photo-1667520405114-47d3677f966e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGNsZWFuaW5nJTIwYW5kJTIwaG91c2Vob2xkfGVufDB8MHwwfHx8MA%3D%3D",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/CLEANING & HOUSEHOLD"
        },
        {
            title: "BEVERAGE",
            image: "https://plus.unsplash.com/premium_photo-1721227932255-175db3ae7f88?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YmV2ZXJhZ2VzfGVufDB8MHwwfHx8MA%3D%3D",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/BEVERAGE"
        },
        {
            title: "DRYFRUITS & SEEDS",
            image: "https://media.istockphoto.com/id/1218693828/photo/wooden-bowl-with-mixed-nuts-on-rustic-table-top-view-healthy-food-and-snack.jpg?s=612x612&w=0&k=20&c=89-ko7nwlcqM6HPvwaQ3tZus4apArtwHkFAB0IxPQpo=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/DRYFRUITS & SEEDS"
        },
        {
            title: "DAIRY",
            image: "https://media.istockphoto.com/id/544807136/photo/various-fresh-dairy-products.jpg?s=612x612&w=0&k=20&c=U5T70bi24itoTDive1CVonJbJ97ChyL2Pz1I2kOoSRo=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/DAIRY"
        },
        {
            title: "FOODGRAINS & DAILY FOOD",
            image: "https://media.istockphoto.com/id/835833518/photo/wholegrain-food-still-life-shot-on-rustic-wooden-table.webp?a=1&b=1&s=612x612&w=0&k=20&c=YMoRIZkohqBpyLRUSj3D5xoReSDMn8NbrRnMpwHVNS8=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/FOODGRAINS & DAILY FOOD"
        },
        {
            title: "PUJA ITEMS",
            image: "https://media.istockphoto.com/id/1132079979/photo/smoking-and-smelling-joss-sticks-at-home-feng-shui-copy-space.jpg?s=612x612&w=0&k=20&c=FKlch_fa-_NEJ1X-DomklJfbnX2IhkruTjH6VcdPE3k=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/PUJA ITEMS"
        },
        {
            title: "DIGESTIVE & MOUTH FRESHNERS",
            image: "https://media.istockphoto.com/id/1761769615/photo/indian-variety-of-traditional-mouth-freshener-in-bowl-for-sale-mukhwas-poona-traditional.jpg?s=612x612&w=0&k=20&c=macaeqpOIRmczIk2ZChJwMQSEVv1I1pwy1ho97N7rTs=",
            // description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
            link: "/Products/DIGESTIVE & MOUTH FRESHNERS"
        }
    ];


    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in when the component mounts
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        setIsLoggedIn(!!(token && userId)); // Set `isLoggedIn` based on the existence of token and userId
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            alert("You are already logged in!"); // Show an alert if already logged in
        } else {
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        console.log("User logged out and data removed from localStorage.");
        setIsLoggedIn(false);
        navigate("/Login"); // Redirect to login
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                            <Link to="/Profile">
                                <img src={Account}
                                    alt="account"
                                    className="d-inline-block align-top"
                                />
                            </Link>
                        </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>

                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container>
                <Row>
                    <Col className="mt-5">
                        <Card>
                            <Row>
                                <Col className="text-center mt-5">
                                    <p className="pc">
                                        PRODUCT CATALOGUE
                                    </p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center mt-3">
                                    <p className="pc2">
                                        We have a strong supplier chain and product sourcing from 200+ brands.<br />
                                        While we have made an attempt to integrate a wide range of offerings, we’re continually adding new brands.<br />
                                        If you’re looking for something not listed, we’ll be glad to assist you!
                                    </p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="p-5 text-center">
                                    <Button className="pcbut" onClick={handleDownload}>
                                        Click Here To Download Our Product Catalogue
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    {categories.map((category, index) => (
                        <Col xs={12} sm={6} md={4} lg={3} className="mt-3" key={index}>
                            <Card className="pccard">
                                <Card.Img variant="top" src={category.image} alt={category.title} />
                                <Card.Body>
                                    <Card.Title className="catetext">{category.title}</Card.Title>
                                    <Card.Text className="catetext1">{category.description}</Card.Text>
                                    <Button
                                        variant="primary"
                                        className="w-100 catebtntext"
                                        onClick={handleNavigate}
                                    >
                                        Explore Products
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>

            <div class="icon-bar-floating">
                <a href="https://wa.me/+919029937080"
                    // class="whatsapp_float"
                    target="_blank"
                    rel="noopener noreferrer">
                    <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                </a>

                <a href="tel:9029937080">
                    <BiSolidPhoneCall className="call p-1" />
                </a>
            </div>

            {/* ----------------FOOTER----------------- */}

            <Container fluid>
                <MDBFooter bgColor='light' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                                                        <div className='me-5 d-none d-lg-block'>
                                                            <span>Get connected with us on social networks:</span>
                                                        </div>
                                
                                                        <div>
                                                            <Link to="#" className='me-4 text-reset'>
                                                                <FaFacebook />
                                                            </Link>
                                
                                                            <Link to="#" className='me-4 text-reset'>
                                                                <MDBIcon color='secondary' fab icon='twitter' />
                                                            </Link>
                                
                                                            <Link to="#" className='me-4 text-reset'>
                                                                <MDBIcon color='secondary' fab icon='instagram' />
                                                            </Link>
                                
                                                            <Link to="#" className='me-4 text-reset'>
                                                                <MDBIcon color='secondary' fab icon='linkedin' />
                                                            </Link>
                                                        </div>
                                                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Eximtrac
                                    </h6>
                                    <p>
                                        We are a certified Mumbai-based exporter, specializing in Indian groceries and FMCG products (food and non-food).
                                        With a vast portfolio of 200+ brands and new additions regularly, we source and deliver everything you need, consolidating all your requirements into one solution.
                                    </p>
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>Quick Links</h6>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/" className='text-reset foottext'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Aboutus" className='text-reset foottext'>
                                                About Us
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Products" className='text-reset foottext'>
                                                Shop Now
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/ContactUs" className='text-reset foottext'>
                                                Contact Us
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>OFFICE ADDRESS</h6>
                                    <p>
                                        Eximtrac<br />
                                        Shop No 2&3, Laxmi Apts, Behind Patel Nagar,
                                        M.G. Cross Road no 4, Kandivali West,
                                        Mumbai – 400067, India

                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>CONTACT & EMAIL</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div class="icon-bar">
                                            <a href="mailto:eximtrac@gmail.com" className="textdeco">
                                                <MdEmail className="me-2" /> eximtrac@gmail.com
                                            </a>
                                            <br />
                                            <a href="mailto:connect@eximtrac.com" className="textdeco">
                                                <MdEmail className="me-2 footerphone" /> connect@eximtrac.com
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:9699268642" className="textdeco">
                                                <FaPhoneAlt className="me-2" /> +91-96992 68642
                                            </a>
                                            <br />
                                            <a href="tel:9029937080" className="textdeco">
                                                <FaPhoneAlt className="me-2 footerphone" /> +91-90299 37080
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-4 footerlastbgco'>
                        © 2024 Copyright:
                        <a className='text-reset fw-bold' href='Eximtrac'>
                            Eximtrac
                        </a>
                    </div>
                </MDBFooter>
            </Container >
        </>
    );
}

export default ProductCatalogue;