import React, { useCallback, useEffect, useState } from "react";
import "./Addtocontainer.css";
import { Container, Nav, Button, Navbar, Row, Col, Card, Table, Dropdown } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import Account from "../src/Photos/Account.png";
import container from "../src/Photos/Container.png";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";

function Addtocontainer() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const navigate = useNavigate();

    // Retrieve user details once
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const emailID = localStorage.getItem("username");

    const [input, setData] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(!!(token && userId));
    const [successMessage, setSuccessMessage] = useState("");

    // Fetch Add-to-Container Data
    const fetchData = useCallback(() => {
        if (!userId || !token) return;

        fetch(`https://api-ft5g.onrender.com/Get-add-to-container/${userId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
            .then(res => res.ok ? res.json() : Promise.reject(res.statusText))
            .then(docs => setData(docs?.data || []))
            .catch(err => console.error("Error fetching data:", err));
    }, [userId, token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Handle Quantity Change
    const handleQuantityChange = (index, value) => {
        setData(prevData => {
            const updatedData = [...prevData];
            updatedData[index] = { ...updatedData[index], CartonQty: value };
            return updatedData;
        });
    };

    // Handle Order Submission
    const handleSubmit = () => {
        const submissionData = input.map(item => ({
            ProductID: item.ProductID,
            CartonQty: item.CartonQty,
            UserID: userId,
        }));

        fetch("https://api-ft5g.onrender.com/container-place-enquiry", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(submissionData),
        })
            .then(res => res.json())
            .then(response => {
                if (response.message === "Enquiry placed successfully!") { // ✅ Fixed success message
                    setSuccessMessage("Enquiry placed successfully!");

                    console.log("✅ Enquiry placed, now deleting container data...");

                    // ✅ Fix: Pass userId as query param, not in body
                    fetch(`https://api-ft5g.onrender.com/delete-container-data?userId=${userId}`, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    })
                        .then(deleteRes => deleteRes.json())
                        .then(deleteResponse => {
                            if (deleteResponse.message === "Container data deleted successfully") {
                                console.log("🗑️ Container data deleted");
                                setData([]);
                                setTimeout(() => setSuccessMessage(""), 5000);
                                sendEnquiryEmail(); // ✅ Moved inside delete success

                                // ✅ Redirect to Enquiry Page after process completes
                                navigate("/EnquiryPage");

                            } else {
                                console.error("❌ Error deleting container data:", deleteResponse.message);
                            }
                        })
                        .catch(err => console.error("❌ Error in delete request:", err));
                } else {
                    console.error("❌ Unexpected response:", response);
                }
            })
            .catch(err => console.error("❌ Error submitting data:", err));
    };


    // Send Enquiry Email
    const sendEnquiryEmail = () => {
        if (!emailID) return console.error("User email not found!");

        fetch("https://api-ft5g.onrender.com/send-enquiry-email", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: emailID }),
        })
            .then(res => res.json())
            .then(response => {
                console.log("Email API Response:", response);
                if (response.message !== "Email sent successfully") {
                    console.error("Error sending email:", response.message, response.error);
                }
            })
            .catch(err => console.error("Error:", err));
    };

    // Handle Delete Item
    const handleDelete = (productId) => {
        fetch(`https://api-ft5g.onrender.com/delete-container-item/${productId}/${userId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => res.json())
            .then(response => {
                if (response.message === "Product deleted successfully") {
                    setData(prevData => prevData.filter(item => item.ProductID !== productId));
                }
            })
            .catch(err => console.error("Error deleting product:", err));
    };

    // Handle Login/Logout
    const handleLoginClick = () => isLoggedIn ? alert("You are already logged in!") : navigate("/Login");
    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        setIsLoggedIn(false);
        navigate("/Login");
    };


    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>

                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container fluid>
                <Row>
                    <Col className="mt-5" lg={9} xs={12}>
                        <Card className="addcard mb-3">
                            <Row>
                                <Col className="mt-3 px-4">
                                    <Table responsive>
                                        <thead>
                                            <tr className="">
                                                <th className="">Sr. No.</th>
                                                <th className="">Products</th>
                                                <th className="">Quantities(Cases)</th>
                                                <th className=""></th>
                                                <th className="">Action</th>
                                            </tr>
                                        </thead>
                                        <br />
                                        <tbody>
                                            {
                                                input.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="border-0">{index + 1}</td>
                                                        <td className="border-0 prodname">Product : {item.PRODUCT_DESCRIPTION}
                                                            <p className="mb-0 branname">Brand : {item.BRAND}</p>
                                                            <p className="catename">Category : {item.CATEGORY}</p>
                                                        </td>

                                                        <td className="border-0">
                                                            {/* <Row className="justify-content-center">
                                                                <Col lg={9} xs={12}>
                                                                    <Form.Select onChange={(e) =>
                                                                        handleQuantityChange(index, e.target.value)
                                                                    }
                                                                    >
                                                                        <option value="">SELECT</option>
                                                                        {[...Array(10).keys()].map((num) => (
                                                                            <option key={num + 1} value={num + 1}>
                                                                                {num + 1}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Col>
                                                            </Row> */}

                                                            <input
                                                                type="number"
                                                                value={item.CartonQty}
                                                                min="1"
                                                                onChange={(e) =>
                                                                    handleQuantityChange(index, e.target.value)
                                                                }
                                                                style={{
                                                                    width: "80px",
                                                                    textAlign: "center",
                                                                }}
                                                            />
                                                        </td>

                                                        <td className="border-0 details">
                                                            <p className="mb-0">Case Size : {item.UNIT_PER_CTN} {item.UNIT} / Case</p>
                                                            <p>Per Unit Weight : {item.WEIGHT_PER_PKT_GRAMS} gm</p>
                                                        </td>
                                                        <td className="border-0">
                                                            <Button onClick={() => handleDelete(item.ProductID)} className="deletebtn">
                                                                <FaTrashAlt className="delete" />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mt-5" lg={3} xs={12}>
                        <Card className="enquirycard">
                            <Row className="px-4">
                                <Col className="mt-4">
                                    <p className="enquirysummary">Enquiry Summary</p>
                                </Col>
                            </Row>

                            {/* <Row className="px-4">
                                <Col>
                                    <p className="textfont">Total Weight</p>
                                </Col>

                                <Col className="text-end">
                                    <p className="textfont">10,000 Kg</p>
                                </Col>
                            </Row>

                            <Row className="px-4">
                                <Col>
                                    <p className="textfont">Total Volume</p>
                                </Col>

                                <Col className="text-end">
                                    <p className="textfont">20 CBM</p>
                                </Col>
                            </Row>

                            <Row className="px-4">
                                <Col>
                                    <p className="textfont">Total Carton</p>
                                </Col>

                                <Col className="text-end">
                                    <p className="textfont">200</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="px-4">
                                    <hr />
                                </Col>
                            </Row> */}

                            <Row>
                                <Col className="text-center px-5 mb-3">
                                    {/* Display Success Message */}
                                    {successMessage && (
                                        <p
                                            style={{
                                                color: "green",
                                                fontWeight: "bold",
                                                font: "30px",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            {successMessage}
                                        </p>
                                    )}
                                    <Button onClick={handleSubmit} className="w-100 placeenquiry">
                                        Place Enquiry
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>


                <div class="icon-bar-floating">
                    <a href="https://wa.me/+919029937080"
                        // class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                    </a>

                    <a href="tel:9029937080">
                        <BiSolidPhoneCall className="call p-1" />
                    </a>
                </div>


                {/* ----------------FOOTER----------------- */}

                <MDBFooter bgColor='light' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                                                                        <div className='me-5 d-none d-lg-block'>
                                                                            <span>Get connected with us on social networks:</span>
                                                                        </div>
                                                
                                                                        <div>
                                                                            <Link to="#" className='me-4 text-reset'>
                                                                                <FaFacebook />
                                                                            </Link>
                                                
                                                                            <Link to="#" className='me-4 text-reset'>
                                                                                <MDBIcon color='secondary' fab icon='twitter' />
                                                                            </Link>
                                                
                                                                            <Link to="#" className='me-4 text-reset'>
                                                                                <MDBIcon color='secondary' fab icon='instagram' />
                                                                            </Link>
                                                
                                                                            <Link to="#" className='me-4 text-reset'>
                                                                                <MDBIcon color='secondary' fab icon='linkedin' />
                                                                            </Link>
                                                                        </div>
                                                                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Eximtrac
                                    </h6>
                                    <p>
                                        We are a certified Mumbai-based exporter, specializing in Indian groceries and FMCG products (food and non-food).
                                        With a vast portfolio of 200+ brands and new additions regularly, we source and deliver everything you need, consolidating all your requirements into one solution.
                                    </p>
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>Quick Links</h6>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/" className='text-reset foottext'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Aboutus" className='text-reset foottext'>
                                                About Us
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Products" className='text-reset foottext'>
                                                Shop Now
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/ContactUs" className='text-reset foottext'>
                                                Contact Us
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>OFFICE ADDRESS</h6>
                                    <p>
                                        Eximtrac<br />
                                        Shop No 2&3, Laxmi Apts, Behind Patel Nagar,
                                        M.G. Cross Road no 4, Kandivali West,
                                        Mumbai – 400067, India

                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>CONTACT & EMAIL</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div class="icon-bar">
                                            <a href="mailto:eximtrac@gmail.com" className="textdeco">
                                                <MdEmail className="me-2" /> eximtrac@gmail.com
                                            </a>
                                            <br />
                                            <a href="mailto:connect@eximtrac.com" className="textdeco">
                                                <MdEmail className="me-2 footerphone" /> connect@eximtrac.com
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:9699268642" className="textdeco">
                                                <FaPhoneAlt className="me-2" /> +91-96992 68642
                                            </a>
                                            <br />
                                            <a href="tel:9029937080" className="textdeco">
                                                <FaPhoneAlt className="me-2 footerphone" /> +91-90299 37080
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-4 footerlastbgco'>
                        © 2024 Copyright:
                        <a className='text-reset fw-bold' href='Eximtrac'>
                            Eximtrac
                        </a>
                    </div>
                </MDBFooter>
            </Container >
        </>
    );
}

export default Addtocontainer;