import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './Home';
import Aboutus from './Aboutus';
import UserDashboard from './UserDashboard';
import Products from './Products';
import Addtocontainer from './Addtocontainer';
import Login from './Login';
import AdminDash from './AdminDash';
import Customers from './Customers';
import Category from './Category';
import SubCategory from './SubCategory';
import Brands from './Brands';
import AdminProducts from './AdminProducts';
import Quotations from './Quotations';
import Profile from './Profile';
import AdminCustomerDetail from './AdminCustomerDetail';
import Order from './Order';
import Signup from './Signup';
import ViewQuotationOrder from './ViewQuotationOrder';
import OrderPage from './OrderPage';
import AdminLogin from './AdminLogin';
import EnquiryPage from './EnquiryPage';
import AdminOrder from './AdminOrder';
import ViewQuotationEnquiry from './ViewQuotationEnquiry';
import Enquiry from './Enquiry';
import ContactUs from './ContactUs';
import ProductCatalogue from './ProductCatalogue';
import OurCredentials from './OurCredentials';
import ContactUsResponse from './ContactUsResponse';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Aboutus' element={<Aboutus />} />
          <Route path='/UserDashboard' element={<UserDashboard />} />
          <Route path='/Products' element={<Products />} />
          <Route path='/Addtocontainer' element={<Addtocontainer />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/AdminDash' element={<AdminDash />} />
          <Route path='/Customers' element={<Customers />} />
          <Route path='/Category' element={<Category />} />
          <Route path='/SubCategory' element={<SubCategory />} />
          <Route path='/Brands' element={<Brands />} />
          <Route path='/AdminProducts' element={<AdminProducts />} />
          <Route path='/Quotations' element={<Quotations />} />
          <Route path='/Profile' element={<Profile />} />
          <Route path='/AdminCustomerDetail/:UserID' element={<AdminCustomerDetail />} />
          <Route path='/Order/:orderId/:userId' element={<Order />} />
          <Route path='/Signup' element={<Signup />} />
          <Route path='/ViewQuotationOrder/:orderId/:userId' element={<ViewQuotationOrder />} />
          <Route path='/OrderPage' element={<OrderPage />} />
          <Route path='/AdminLogin' element={<AdminLogin />} />
          <Route path='/EnquiryPage' element={<EnquiryPage />} />
          <Route path='/AdminOrder' element={<AdminOrder />} />
          <Route path='/ViewQuotationEnquiry/:orderId/:userId' element={<ViewQuotationEnquiry />} />
          <Route path='/Enquiry/:orderId' element={<Enquiry />} />
          <Route path='/ContactUs' element={<ContactUs />} />
          <Route path='/ProductCatalogue' element={<ProductCatalogue />} />
          <Route path='/OurCredentials' element={<OurCredentials />} />
          <Route path='/ContactUsResponse' element={<ContactUsResponse />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password/:token' element={<ResetPassword />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
