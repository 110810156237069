import React, { useState, useEffect } from "react";
import "./ViewQuotationEnquiry.css";
import { Link, useParams } from "react-router-dom";
import { Button, Navbar, Nav, Container, Row, Col, Card, Dropdown, Table } from "react-bootstrap";
import Logo from "../src/Photos/Logo.jpg";
import Account from "../src/Photos/Account.png";
import * as XLSX from "xlsx";

function ViewQuotationEnquiry() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };


    // const [showPopup, setShowPopup] = useState(false);

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     setShowPopup(true); // Show popup when submitted
    // };

    // const handleClose = () => {
    //     setShowPopup(false); // Hide popup
    // };

    // const userId = localStorage.getItem('userId');

    // const token = localStorage.getItem('token');

    // States for file inputs
    // const [quotationFile, setQuotationFile] = useState(null);

    // const { userId, orderId } = useParams(); // Get UserID and OrderID from the URL
    // const [message, setMessage] = useState("");
    // const [messageType, setMessageType] = useState(""); // "success" or "danger"

    // // Handle file selection
    // const handleFileChange = (e) => {
    //     const files = e.target.files;
    //     setQuotationFile(files ? files[0] : null); // Ensure only one file is uploaded
    // };

    // Handle form submission
    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     if (!userId || !orderId) {
    //         setMessage("Invalid UserID or OrderID. Please check the URL.");
    //         setMessageType("danger");
    //         return;
    //     }

    //     // Validate file input
    //     if (!quotationFile) {
    //         setMessage("Please select a file to upload.");
    //         setMessageType("danger");
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append("quotationFile", quotationFile); // Ensure correct field name
    //     formData.append("DocumentType", "Quotation"); // Fixed value for DocumentType

    //     try {
    //         const response = await fetch(`https://api-ft5g.onrender.com/enquiry-quotation-files/${orderId}/${userId}`, {
    //             method: "POST",
    //             body: formData,
    //         });

    //         if (response.ok) {
    //             const data = await response.json();
    //             console.log("Upload successful:", data);
    //             setMessage("File uploaded successfully!");
    //             setMessageType("success");
    //         } else {
    //             const error = await response.json();
    //             setMessage(error.message || "File upload failed.");
    //             setMessageType("danger");
    //         }
    //     } catch (err) {
    //         console.error("Error during upload:", err);
    //         setMessage("Network error. Please try again.");
    //         setMessageType("danger");
    //     }
    // };


    // const navigate = useNavigate();
    // const [orderDetails, setOrderDetails] = useState([]);

    // useEffect(() => {
    //     // Get OrderID from localStorage
    //     const selectedOrderId = localStorage.getItem("selectedOrderId");

    //     if (selectedOrderId) {
    //         // Fetch data and filter by OrderID
    //         fetch(`http://localhost:2000/Get-container-place-enquiry-user-and-admin/${userId}`)
    //             .then((res) => res.json())
    //             .then((docs) => {
    //                 if (docs && docs.data) {
    //                     const filteredOrder = docs.data.filter(
    //                         (item) => item.OrderID === selectedOrderId
    //                     );
    //                     setOrderDetails(filteredOrder);
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error("Error fetching data:", error);
    //             });
    //     }

    //     // (Optional) Clear the stored OrderID after use
    //     return () => {
    //         sessionStorage.removeItem("selectedOrderId");
    //     };
    // }, []);

    // const { orderId, userId } = useParams();

    // const [userDetails, setUserDetails] = useState([]);

    // useEffect(() => {
    //     // Adjust the API URL based on the backend route
    //     const url = orderId
    //         ? `https://api-ft5g.onrender.com/Get-container-place-enquiry-user-and-admin/${userId}/${orderId}`
    //         : `https://api-ft5g.onrender.com/Get-container-place-enquiry-user-and-admin/${userId}`;

    //     fetch(url)
    //         .then((res) => {
    //             if (!res.ok) {
    //                 throw new Error(`HTTP error! Status: ${res.status}`);
    //             }
    //             return res.json();
    //         })
    //         .then((data) => setUserDetails(data.data))
    //         .catch((err) => console.error("Error fetching data:", err));
    // }, [orderId, userId]);


    // const [files, setFiles] = useState([]);
    // const [error, setError] = useState("");

    // Fetch files from the backend
    // useEffect(() => {
    //     fetch(`https://api-ft5g.onrender.com/enquiry-quotation-files-admin/${userId}/${orderId}`)
    //         .then((res) => res.json())
    //         .then((data) => {
    //             if (data.files) {
    //                 setFiles(data.files);
    //             } else {
    //                 setError(data.message || "Failed to fetch files");
    //             }
    //         })
    //         .catch((err) => {
    //             console.error("Error fetching files:", err);
    //             setError("Failed to fetch files");
    //         });
    // }, [userId, orderId]);

    // Handle file download
    // const handleDownload = (fileName) => {
    //     window.open(`https://api-ft5g.onrender.com/download-file/${fileName}`, "_blank");
    // };

    // const navigate = useNavigate();

    // const [message, setMessage] = useState("");
    // const [messageType, setMessageType] = useState("");
    const { userId, orderId } = useParams();
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [userDetails, setUserDetails] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState([]);

    useEffect(() => {
        const url = orderId
            ? `https://api-ft5g.onrender.com/Get-container-place-enquiry-user-and-admin/${userId}/${orderId}`
            : `https://api-ft5g.onrender.com/Get-container-place-enquiry-user-and-admin/${userId}`;

        fetch(url)
            .then((res) => {
                if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`);
                return res.json();
            })
            .then((data) => {
                setUserDetails(data.data);
                setEditedData(data.data);
            })
            .catch((err) => console.error("Error fetching data:", err));
    }, [orderId, userId]);

    const handleSelectAll = (e) => {
        setSelectedOrders(e.target.checked ? userDetails.map((item) => item.CPE_ID) : []);
    };

    const handleCheckboxChange = (cpeId) => {
        setSelectedOrders((prev) =>
            prev.includes(cpeId) ? prev.filter((id) => id !== cpeId) : [...prev, cpeId]
        );
    };

    const handleConvertToOrder = async () => {
        if (selectedOrders.length === 0) {
            setMessage("Please select at least one enquiry to convert.");
            setMessageType("danger");
            return;
        }
        try {
            const response = await fetch("https://api-ft5g.onrender.com/convert-enquiry-to-order", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ cpeIds: selectedOrders }),
            });
            const data = await response.json();
            if (response.ok) {
                setMessage("Enquiries successfully converted to orders.");
                setMessageType("success");
                setSelectedOrders([]);
            } else {
                setMessage(data.message || "Failed to convert enquiry to order.");
                setMessageType("danger");
            }
        } catch (err) {
            setMessage("Network error. Please check your connection.");
            setMessageType("danger");
        }
    };

    const handleEditClick = () => setIsEditing(true);
    const handleSaveClick = () => {
        setIsEditing(false);
        updateData(editedData);
    };

    const handleChange = (index, field, value) => {
        setEditedData((prev) => prev.map((row, i) => (i === index ? { ...row, [field]: value } : row)));
    };

    const updateData = async (updatedRows) => {
        try {
            await fetch("https://api-ft5g.onrender.com/update-enquiry", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ data: updatedRows }),
            });
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };


    const handleDownloadExcel = () => {
        if (userDetails.length === 0) {
            setMessage("No data available to download.");
            setMessageType("danger");
            return;
        }

        // Convert userDetails to a format suitable for Excel
        const worksheet = XLSX.utils.json_to_sheet(userDetails);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "EnquiryDetails");

        // Create an Excel file and trigger download
        XLSX.writeFile(workbook, `Enquiry_Details_${new Date().toISOString()}.xlsx`);
    };



    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container fluid>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* <Nav className="me-auto">
                        </Nav> */}

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminDash" className="adminnavtext">Dashboard</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Customers" className="adminnavtext">Customers</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Category" className="adminnavtext">Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/SubCategory" className="adminnavtext">Sub-Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Brands" className="adminnavtext">Brands</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminProducts" className="adminnavtext">Products</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Quotations" className="adminnavtext">Enquiries</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminOrder" className="adminnavtext">Orders</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUsResponse" className="adminnavtext">Contact</Nav.Link>
                            </Button>


                            <Dropdown>
                                <Dropdown.Toggle className="logoscrollbtn p-0 mx-2 mt-1 responsiveview" id="dropdown-basic">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="/Profile">My Profile</Dropdown.Item> */}
                                    {/* <Dropdown.Item href="/OrderPage">Past Orders</Dropdown.Item> */}
                                    <Dropdown.Item href="/AdminLogin">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container fluid className="">
                <Row>
                    <Col lg={4} className="mt-3">
                        <Button onClick={isEditing ? handleSaveClick : handleEditClick} className="w-100">
                            {isEditing ? "Save" : "Edit"}
                        </Button>
                    </Col>

                    <Col lg={4} className="mt-3">
                        <Button className="w-100 convertbtn" onClick={handleConvertToOrder} disabled={selectedOrders.length === 0}>
                            Convert Enquiry to Order
                        </Button>
                    </Col>

                    <Col lg={4} className="mt-3">
                        <Button className="w-100" onClick={handleDownloadExcel}>
                            Download as Excel
                        </Button>
                    </Col>
                </Row>

                <Row>
                    {/* <Col lg={8} className="mt-3">
                        <Card className="p-4">
                            <h5 className="text-center mb-4">ENQUIRY DETAILS</h5>

                            {
                                orderDetails.length > 0 ? (
                                    orderDetails.map((item, index) => (
                                        <Row key={index}>
                                            <hr className="mt-3 mb-3" />
                                            <Col>
                                                <Row className="text-center mb-2 mt-3">
                                                    <Col>
                                                        <p className="mb-1 text-muted orderfont2">{item.OrderID}</p>
                                                        <h4 className="orderfont1">{item.PRODUCT_DESCRIPTION}</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-muted mb-1 orderfont1">ORDERED ON</p>
                                                        <p className="orderfont1">{item.UploadDate}</p>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-muted mb-1 orderfont1">SKU CODE</p>
                                                        <p className="text-muted orderfont1">{item.SKU_CODE}</p>
                                                    </Col>
                                                </Row>

                                                <Row className="text-center">
                                                    <Col className="mt-4">
                                                        <p className="text-muted mb-1 orderfont1">QUANTITY</p>
                                                        <p className="orderfont1">{item.UNIT_PER_CTN} {item.UNIT} / CARTONS</p>
                                                    </Col>
                                                    <Col className="mt-4">
                                                        <p className="text-muted mb-1 orderfont1">NO. OF CARTONS</p>
                                                        <p className="orderfont1">{item.CartonQty}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))
                                ) : (
                                    <p>No details available for this order.</p>
                                )
                            }

                            <hr className="my-4" />

                            <p className="text-center text-muted orderfont">
                                Your order will be delivered tentetively by <span style={{ fontWeight: "bold" }}>15 Dec 2024</span>
                            </p>

                            <hr />
                        </Card>
                    </Col> */}

                    <Col className="mt-3">
                        <Card className="p-4">
                            <h5 className="text-center mb-4">ENQUIRY DETAILS</h5>
                            {userDetails.length > 0 ? (
                                <>
                                    <div className="d-flex align-items-center mb-3">
                                        <input type="checkbox" onChange={handleSelectAll} checked={selectedOrders.length === userDetails.length} />
                                        <label className="mx-3">Select All</label>
                                    </div>
                                    <Table responsive bordered hover>
                                        <thead>
                                            <tr className="text-center">
                                                <th>Select</th>
                                                <th>CPE ID</th>
                                                <th>Order ID</th>
                                                <th>Product Description</th>
                                                <th>Ordered On</th>
                                                <th>SKU Code</th>
                                                <th>Quantity</th>
                                                <th>No. of Cartons</th>
                                                <th>Total Cases</th>
                                                <th>Total QTY</th>
                                                <th>Rate Per Unit USD</th>
                                                <th>Rate Per Case USD</th>
                                                <th>Total Rate In USD FOB INDIA</th>
                                                <th>Total Net Weight</th>
                                                <th>Total Gross Weight</th>
                                                <th>Total Volume</th>
                                                <th>User Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userDetails.map((item, index) => (
                                                <tr key={item.CPE_ID} className="text-center">
                                                    <td>
                                                        <input type="checkbox" checked={selectedOrders.includes(item.CPE_ID)} onChange={() => handleCheckboxChange(item.CPE_ID)} />
                                                    </td>
                                                    <td>{item.CPE_ID}</td>
                                                    <td>{item.OrderID}</td>
                                                    <td>{item.PRODUCT_DESCRIPTION}</td>
                                                    <td>{new Date(item.UploadDate).toLocaleString()}</td>
                                                    <td>{item.SKU_CODE}</td>
                                                    <td>{item.UNIT_PER_CTN} {item.UNIT} / Case</td>
                                                    <td>{item.CartonQty}</td>
                                                    {['TotalCases', 'TotalQty', 'RatePerUnitUSD', 'RatePerCaseUSD', 'TotalRateInUSDFobIndia', 'TotalNetWeight', 'TotalGrossWeight', 'TotalVolume'].map((field) => (
                                                        <td key={field}>
                                                            {isEditing ? (
                                                                <input
                                                                    type="number"
                                                                    value={editedData[index]?.[field] !== undefined ? editedData[index][field] : ""}
                                                                    onChange={(e) => handleChange(index, field, e.target.value)}
                                                                />
                                                            ) : (
                                                                userDetails[index]?.[field] !== undefined && userDetails[index][field] !== null
                                                                    ? userDetails[index][field]  // Show stored data from the database
                                                                    : "-" // Fallback if no data exists
                                                            )}
                                                        </td>
                                                    ))}
                                                    <td>{item.UserStatus}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                            ) : (
                                <p className="text-center text-muted">No details available for this order.</p>
                            )}

                            {/* <hr className="my-4" />
                            <p className="text-center text-muted orderfont">
                                Your order will be delivered tentatively by <span style={{ fontWeight: "bold" }}>15 Dec 2024</span>
                            </p> */}
                        </Card>
                    </Col>

                    {/* <Col className="mt-3 mb-3" lg={4}>
                        <Card>
                            <Form onSubmit={handleSubmit}> */}
                    {/* Display Success/Error Message */}
                    {/* {message && (
                                    <Alert
                                        variant={messageType}
                                        className="mx-3 mt-3"
                                        onClose={() => setMessage("")}
                                        dismissible
                                    >
                                        {message}
                                    </Alert>
                                )} */}
                    {/* File Upload Fields */}
                    {/* <Row>
                                    <Col className="mt-3 mx-3">
                                        <Form.Group controlId="formFileQuotation" className="mb-3">
                                            <Form.Label>Upload Quotation</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="quotationFile"
                                                onChange={(e) => handleFileChange(e, setQuotationFile)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row> */}

                    {/* Submit Button */}
                    {/* <Row>
                                    <Col className="mt-3 mb-3 mx-3">
                                        <Button type="submit" className="adminproductsaddnewcatebtn w-100">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card> */}



                    {/* {message && (
                            <Row className="mt-3">
                                <Col>
                                    <div className={`alert alert-${messageType} text-center`}>{message}</div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className="mb-3 mt-3">
                                <Card className="p-3">
                                    <h4 className="mb-3">Uploaded Files</h4>
                                    {error && <p className="text-danger">{error}</p>}
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>File Type</th>
                                                <th>Uploaded Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {files.map((file, index) => (
                                                <tr key={index}>
                                                    <td>{file.FileName}</td>
                                                    <td>{file.FileType}</td>
                                                    <td>{new Date(file.UploadDate).toLocaleString()}</td>
                                                    <td>
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => handleDownload(file.FileName)}
                                                        >
                                                            Download
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>
                        </Row> 
                    </Col>*/}
                </Row>
            </Container >
        </>
    );
}

export default ViewQuotationEnquiry;