import React from "react";
import "./OurCredentials.css";
import { Button, Card, Col, Container, Dropdown, Nav, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Account from "../src/Photos/Account.png";
import container from "../src/Photos/Container.png";
import Logo from "../src/Photos/Logo.jpg";
import status from "../src/Photos/StatusHolderCertificate.png";

function OurCredentials() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const navigate = useNavigate();

    // List of documents
    const documents = [
        { name: "GST Certificate", file: "EXIMTRAC GST certificate from 24 11 2023.pdf" },
        { name: "FSSAI License", file: "fssai license - UPTO 20.01.2029.pdf" },
        { name: "IEC Copy", file: "IEC COPY NEW.pdf" },
        { name: "STATUS HOLDER CERTIFICATE", file: "ONE STAR EXPORT HOUSE - Status Holder Certificate.pdf" }
    ];

    // Function to handle file download
    const handleDownload = (file) => {
        const fileUrl = `${process.env.PUBLIC_URL}/${file}`;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = file; // Suggested filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle className="scrollbtn2 p-0 mx-3 mt-1 pt-1 responsiveview" id="dropdown-basic">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>
                                    <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>
                                    <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>
                                    {/* <Dropdown.Item href="/Login">Logout</Dropdown.Item> */}
                                    <Dropdown.Item
                                        onClick={() => {
                                            localStorage.removeItem("userId");
                                            console.log("User ID removed from local storage.");
                                            navigate("/Login"); // React Router navigation
                                        }}
                                    >
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>

                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container className="mt-4">
                <p className="downloadcred">Download Our Company Credentials</p>
                {/* <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Document Name</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documents.length > 0 ? (
                            documents.map((doc, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{doc.name}</td>
                                    <td>
                                        <Button
                                            variant="primary"
                                            onClick={() => handleDownload(doc.file)}
                                        >
                                            Download
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">
                                    No Documents Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table> */}

                <Row>
                    {documents.length > 0 ? (
                        documents.map((doc, index) => (
                            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4 mt-3">
                                <Card>
                                    <Card.Img variant="top" src={status} />
                                    <Card.Body>
                                        <Card.Title>{doc.name}</Card.Title>
                                        <Card.Text>
                                            Click the button below to download the document.
                                        </Card.Text>
                                        <Button
                                            variant="primary"
                                            onClick={() => handleDownload(doc.file)}
                                            className="w-100"
                                        >
                                            Download
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <Col>
                            <p>No Documents Available</p>
                        </Col>
                    )}
                </Row>
            </Container>
        </>
    );
}

export default OurCredentials;