import React, { useState, useEffect } from "react";
import "./Category.css";
// import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from "react-router-dom";
import { Button, Navbar, Nav, Container, Row, Col, Card, Table, Form, Dropdown } from "react-bootstrap";
// import dash from "../src/Photos/Dashboard.png";
// import menu from "../src/Photos/Menu.png";
// import { IoIosArrowForward } from "react-icons/io";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";


// import { FaRegEye } from "react-icons/fa";
// import { FaTrashAlt } from "react-icons/fa";
// import { LuPencil } from "react-icons/lu";

function Category() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };


    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");

    // Handle file selection
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // Handle file upload to the backend
    const handleFileUpload = async (event) => {
        event.preventDefault();
        setUploadStatus(""); // Reset status message

        if (!file) {
            setUploadStatus("Please select a CSV file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await fetch("https://api-ft5g.onrender.com/upload-csv", {
                method: "POST",
                body: formData,
            });

            const data = await response.json(); // ✅ Read JSON response correctly

            if (response.ok) {
                setUploadStatus("✅ " + data.message);
            } else {
                setUploadStatus("❌ Upload failed: " + (data.message || "Unknown error"));
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            setUploadStatus("❌ An error occurred while uploading the file.");
        }
    };


    const [input, setData] = useState([]); // Stores all categories
    const [editCategory, setEditCategory] = useState({ oldName: "", newName: "" }); // Stores data for editing

    useEffect(() => {
        fetch('https://api-ft5g.onrender.com/GetCategory')
            .then((res) => res.json())
            .then((docs) => {
                if (docs && docs.data) {
                    setData(docs.data);
                    console.log(docs.data);
                } else {
                    console.warn("Data property not found in response:", docs);
                    setData([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setData([]);
            });
    }, []);




    // Handle edit category input change
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditCategory((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle edit button click
    const handleEditClick = (categoryName) => {
        setEditCategory({ oldName: categoryName, newName: categoryName });
    };

    // Handle update category
    const handleUpdateCategory = (e) => {
        e.preventDefault();

        if (editCategory.oldName === editCategory.newName) {
            alert('No changes made to category name.');
            return;
        }

        // Send PUT request to update category
        fetch('https://api-ft5g.onrender.com/update-categories', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(editCategory)
        })
            .then(response => response.json())
            .then(data => {
                alert(data.message);
                // Update the local categories list
                setData((prevCategories) =>
                    prevCategories.map((category) =>
                        category.name === editCategory.oldName
                            ? { ...category, name: editCategory.newName }
                            : category
                    )
                );
            })
            .catch(error => {
                console.error('Error updating category:', error);
                alert('Failed to update category');
            });
    };



    //For Image Upload
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [ImageuploadStatus, setImageUploadStatus] = useState("");

    const handleImageFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    const handleUpload = async () => {
        if (selectedFiles.length === 0) {
            alert("Please select images to upload.");
            return;
        }

        const formData = new FormData();

        // Append all selected files
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append("images", selectedFiles[i]);
        }

        try {
            const response = await fetch("https://api-ft5g.onrender.com/upload-images", {
                method: "POST",
                body: formData,
            });

            const data = await response.json();

            if (response.ok) {
                setImageUploadStatus("Images uploaded successfully!");
            } else {
                setImageUploadStatus(`Error: ${data.error}`);
            }
        } catch (error) {
            console.error("Upload failed:", error);
            setImageUploadStatus("Failed to upload images.");
        }
    };



    return (
        <>
            {/* <Navbar collapseOnSelect expand="lg" className="navibar">
                <Container>
                    <Button onClick={scrollToTop} className="logoscrollbtn p-0">
                        <Navbar.Brand href="/">
                            <img src={Logo}
                                alt="logo"
                                className="d-inline-block align-top" />
                        </Navbar.Brand>
                    </Button> */}

            {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}

            {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
            {/* <Nav className="me-auto">
                    </Nav>

                    <Nav>
                        <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3">
                            <Link to="/Profile">
                                <img src={Account}
                                    alt="account"
                                    className="d-inline-block align-top"
                                />
                            </Link>
                        </Button>
                    </Nav> */}
            {/* </Navbar.Collapse> */}
            {/* </Container>
            </Navbar> */}

            {/* <div style={{ display: 'flex', height: '100%', minHeight: '400px' }} className="mt-3">
                <Sidebar onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint="always" className="side">
                    <Menu>

                        <MenuItem icon={< img src={dash}
                            alt=""
                            className="d-inline-block align-top"
                        />} component={<Link to="/AdminDash" />}> Dashboard</MenuItem>

                        <SubMenu icon={< img src={menu}
                            alt=""
                            className="d-inline-block align-top"
                        />} defaultOpen label="Menu">

                            <MenuItem component={<Link to="/Customers" />}> Customers</MenuItem>
                            <MenuItem component={<Link to="/Category" />}> Category</MenuItem>
                            <MenuItem component={<Link to="/SubCategory" />}> Sub-Category</MenuItem>
                            <MenuItem component={<Link to="/Brands" />}> Brands</MenuItem>
                            <MenuItem component={<Link to="/AdminProducts" />}> Products</MenuItem>
                            <MenuItem component={<Link to="/Quotations" />}> Quotations</MenuItem>
                        </SubMenu>
                    </Menu>
                </Sidebar>
                <main>
                    <div>
                        <Button className="sb-button menubutton" onClick={() => setToggled(!toggled)}>
                            <IoIosArrowForward className="mb-1" />Navigate
                        </Button>
                    </div>
                </main> */}



            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* <Nav className="me-auto">
                        </Nav> */}

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminDash" className="adminnavtext">Dashboard</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Customers" className="adminnavtext">Customers</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Category" className="adminnavtext">Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/SubCategory" className="adminnavtext">Sub-Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Brands" className="adminnavtext">Brands</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminProducts" className="adminnavtext">Products</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Quotations" className="adminnavtext">Enquiries</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminOrder" className="adminnavtext">Orders</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUsResponse" className="adminnavtext">Contact</Nav.Link>
                            </Button>


                            <Dropdown>
                                <Dropdown.Toggle className="logoscrollbtn p-0 mx-2 mt-1 responsiveview" id="dropdown-basic">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="/Profile">My Profile</Dropdown.Item> */}
                                    {/* <Dropdown.Item href="/OrderPage">Past Orders</Dropdown.Item> */}
                                    <Dropdown.Item href="/AdminLogin">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container className="mt-3">
                <Row>
                    <Col>
                        <Card>
                            <Row className="p-3">
                                <Col lg={7}>
                                    <p className="category">Category</p>
                                </Col>

                                {/* Success message */}
                                {/* {successMessage && (
                                    <Alert variant="success" className="text-center">
                                        CSV Uploaded Successfully!
                                    </Alert>
                                )} */}

                                {/* <Col className="categoryaddnewcatebtn"> */}
                                {/* <Button className="categoryaddnewcatebtn">
                                        <Link className="categoryaddnewcatelink"> */}
                                {/* Add New Category
                                </Col> */}

                                <Col className="" lg={5}>
                                    <Form onSubmit={handleFileUpload}>
                                        <Form.Label htmlFor="csvFile" className="categoryaddnewcatebtn">
                                            Add New Products Select a CSV File:
                                        </Form.Label>
                                        <input
                                            type="file"
                                            id="csvFile"
                                            accept=".csv"
                                            onChange={handleFileChange}
                                        />
                                        <Button type="submit" className="uploadbtn">
                                            Upload
                                        </Button>
                                    </Form>
                                    {uploadStatus && <p>{uploadStatus}</p>}
                                    {/* </Link>
                                    </Button> */}
                                </Col>
                            </Row>


                            {/* Edit category modal */}
                            {editCategory.oldName && (
                                <div className="px-3 mb-4">
                                    <h4>Edit Category</h4>
                                    <form onSubmit={handleUpdateCategory}>
                                        <div>
                                            <label>
                                                Old Category Name:
                                                <input
                                                    type="text"
                                                    value={editCategory.oldName}
                                                    disabled
                                                    className="mx-2"
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                New Category Name:
                                                <input
                                                    type="text"
                                                    name="newName"
                                                    value={editCategory.newName}
                                                    onChange={handleEditChange}
                                                    required
                                                    className="mt-3 mx-2"
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <Button type="submit" variant="success" className="mt-3">Update</Button>
                                        </div>
                                    </form>
                                </div>
                            )}

                            <Row>
                                <Col>
                                    <div className="upload-container">
                                        <h4>Upload Product Images</h4>
                                        <input type="file" multiple onChange={handleImageFileChange} />

                                        <Row>
                                            <Col className="mt-3">
                                                <Button onClick={handleUpload} className="upload-button">Upload Images</Button>
                                            </Col>
                                        </Row>
                                        {ImageuploadStatus && <p className="status-message">{ImageuploadStatus}</p>}
                                    </div>
                                </Col>
                            </Row>


                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Sr.No.</th>
                                        <th>Category Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        input.map((category, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>

                                                <td>{category.name}</td>

                                                <td>
                                                    <Button variant="success" onClick={() => handleEditClick(category.name)}>
                                                        Edit
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* </div > */}
        </>
    );
}

export default Category;












// import React, { useState } from "react";

// const Category = () => {
//     const [file, setFile] = useState(null);
//     const [uploadStatus, setUploadStatus] = useState("");

//     // Handle file selection
//     const handleFileChange = (event) => {
//         setFile(event.target.files[0]);
//     };

//     // Handle file upload to the backend
//     const handleFileUpload = async (event) => {
//         event.preventDefault();
//         if (!file) {
//             setUploadStatus("Please select a CSV file to upload.");
//             return;
//         }

//         const formData = new FormData();
//         formData.append("file", file);

//         try {
//             const response = await fetch("http://localhost:2000/upload-csv", {
//                 method: "POST",
//                 body: formData,
//             });

//             if (response.ok) {
//                 setUploadStatus("File uploaded successfully!");
//             } else {
//                 const errorResponse = await response.json();
//                 setUploadStatus(`Upload failed: ${errorResponse.message}`);
//             }
//         } catch (error) {
//             console.error("Error uploading file:", error);
//             setUploadStatus("An error occurred while uploading the file.");
//         }
//     };

//     return (
//         <div style={{ padding: "20px" }}>
//             <h2>Upload CSV</h2>
//             <form onSubmit={handleFileUpload}>
//                 <div style={{ marginBottom: "10px" }}>
//                     <label htmlFor="csvFile">Select a CSV File: </label>
//                     <input
//                         type="file"
//                         id="csvFile"
//                         accept=".csv"
//                         onChange={handleFileChange}
//                     />
//                 </div>
//                 <button type="submit" style={{ padding: "10px 20px" }}>
//                     Upload
//                 </button>
//             </form>
//             {uploadStatus && <p>{uploadStatus}</p>}
//         </div>
//     );
// };

// export default Category;
