import { useState } from "react";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch("https://api-ft5g.onrender.com/forgot-password", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email }),
        });

        const data = await response.text();
        setMessage(data);
    };

    return (
        <div>
            <h2>Forgot Password</h2>
            <form onSubmit={handleSubmit}>
                <input type="email" placeholder="Enter Email" value={email} onChange={e => setEmail(e.target.value)} required />
                <button type="submit">Send Reset Link</button>
            </form>
            <p>{message}</p>
        </div>
    );
};

export default ForgotPassword;