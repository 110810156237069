import React, { useEffect, useState } from "react";
import "./Home.css";
import { Nav, Navbar, Container, Button, Row, Col, Dropdown, Carousel, Image } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import Account from "../src/Photos/Account.png";
import container from "../src/Photos/Container.png";
// import dummy1 from "../src/Photos/Dummy1.png";
// import dummy2 from "../src/Photos/Dummy2.png";
// import dummy3 from "../src/Photos/Dummy3.png";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

import chheda from "../src/Photos/Chheda.png";
import dabur from "../src/Photos/Dabur.png";
import haldiram from "../src/Photos/Haldiram.png";
import lijjat from "../src/Photos/Lijjatpapad.png";
import charliee from "../src/Photos/Charliee.jpg";
import coolberg from "../src/Photos/Coolberg.jpg";
import bakerstreet from "../src/Photos/Baker Street.jpg";
import chandan from "../src/Photos/Chandan.jpg";
import lords from "../src/Photos/Lords.jpg";
import nutrapoorna from "../src/Photos/Nutrapoorna.jpg";
import madam from "../src/Photos/Madam.jpg";
import shrifal from "../src/Photos/Shrifal.jpg";
import omsom from "../src/Photos/Omsom.jpg";
import sarwar from "../src/Photos/Sarwar.jpg";

import Slider1 from "../src/Photos/Home_Slider1.png";
import Slider2 from "../src/Photos/NewSlider4.png";
import Slider3 from "../src/Photos/Home_Slider3.png";


import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import FDA from "../src/Photos/FDA.png";
import APEDA from "../src/Photos/APEDA.png";
import FIEO from "../src/Photos/FIEO.png";
import Spice from "../src/Photos/Spice Board.png";
import FSSAI from "../src/Photos/FSSAI.png";
import Tea from "../src/Photos/Tea Board of India.png";
import OneStar from "../src/Photos/One Star Export House.png";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";


function Home() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const navigate = useNavigate();


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true, // This will enable the arrows
        prevArrow: <Button className="prev-arrow">Prev</Button>,
        nextArrow: <Button className="next-arrow">Next</Button>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true, // You can also control arrows on smaller screens
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
        ],
    };

    // const carouselData = [
    //     { headline: 'Item 1', image: 'https://via.placeholder.com/300x200?text=Item+1' },
    //     { headline: 'Item 2', image: 'https://via.placeholder.com/300x200?text=Item+2' },
    //     { headline: 'Item 3', image: 'https://via.placeholder.com/300x200?text=Item+3' },
    //     { headline: 'Item 4', image: 'https://via.placeholder.com/300x200?text=Item+4' },
    //     { headline: 'Item 5', image: 'https://via.placeholder.com/300x200?text=Item+5' },
    //     { headline: 'Item 6', image: 'https://via.placeholder.com/300x200?text=Item+6' },
    // ];

    const handleNavigate = () => {
        navigate("/Products"); // Replace "/target-path" with your desired route
    };


    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in when the component mounts
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        setIsLoggedIn(!!(token && userId)); // Set `isLoggedIn` based on the existence of token and userId
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            alert("You are already logged in!"); // Show an alert if already logged in
        } else {
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        console.log("User logged out and data removed from localStorage.");
        setIsLoggedIn(false);
        navigate("/Login"); // Redirect to login
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            < Container fluid >
                <Row>
                    <Col className="mt-3">
                        <Carousel id="Carousel1" className="w-100">
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100"
                                    src={Slider1}
                                    alt="Slide1"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100"
                                    src={Slider2}
                                    alt="Slide2"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100"
                                    src={Slider3}
                                    alt="Slide3"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-5 text-center">
                        <p className="whoweare">Who We Are ?</p>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3 text-center">
                        <p className="whoweare2 mx-3 px-5">
                            Exim Trac - a certified and trusted Star Export House with over 35 years of experience serving global markets from Mumbai.
                            <br /><br />
                            We are into export of groceries, including spices, cereals, pulses, grains, snacks, sauces, pickles, and more.
                            Our product range also extends to food and non-food FMCG items, such as healthcare and wellness products, personal care items, homecare essentials, puja samagri and beyond.
                            <br /><br />
                            Our in-house brand, ‘Lords,’ offers premium packaged flours, grains, pulses, spices, and dry fruits.
                            We also provide white-labelling services to help you build your brand.
                            Your specific packaging needs, your labels, your branding is all taken care of.
                            <br /><br />
                            Known as "Supermarket Specialists," we consolidate diverse products into a single container, optimizing weight and pricing.
                            <br /><br />
                            With the guidance of our experienced founder and the proactive, hands-on leadership of the second generation, Team EximTrac consistently meets and exceeds customer expectations.
                            We are excited to connect with partners across diverse regions and foster meaningful, long-lasting relationships.
                        </p>
                    </Col>
                </Row>

                <Row className="px-4">
                    <Col className="mt-3">
                        <p className="explorecategories">Explore Categories</p>
                    </Col>

                    <Col className="text-end mt-3">
                        <Button onClick={handleNavigate} className="seeallprod">See All Products</Button>
                    </Col>
                </Row>

                {/* <Row className="">
                    <Col className="mt-3" lg={2} xs={6}>
                        <Card className="categorycard">
                            <p className="categorytext mt-3">Dairy</p>
                        </Card>
                    </Col>

                    <Col className="mt-3" lg={2} xs={6}>
                        <Card className="categorycard">
                            <p className="categorytext mt-3">Hot Snacks</p>
                        </Card>
                    </Col>

                    <Col className="mt-3" lg={2} xs={6}>
                        <Card className="categorycard">
                            <p className="categorytext mt-3">Instant Food</p>
                        </Card>
                    </Col>

                    <Col className="mt-3" lg={2} xs={6}>
                        <Card className="categorycard">
                            <p className="categorytext mt-3">Health Care</p>
                        </Card>
                    </Col>

                    <Col className="mt-3" lg={2} xs={6}>
                        <Card className="categorycard">
                            <p className="categorytext mt-3">Sweets</p>
                        </Card>
                    </Col>

                    <Col className="mt-3" lg={2} xs={6}>
                        <Button onClick={scrollToTop} className="categorybtn p-0">
                            <Link to="/Products" className="categorybtnlink">
                                <Card className="categorycard">
                                    <p className="categorytext mt-3">+ View More</p>
                                </Card>
                            </Link>
                        </Button>
                    </Col>
                </Row> */}

                <Row>
                    <Col>
                        <div style={{ margin: '20px' }}>
                            {/* <h2>React Slick Carousel</h2> */}
                            <Slider {...settings}>
                                <div>
                                    <img src="https://media.istockphoto.com/id/497184266/photo/indian-spices-in-wooden-trays.jpg?s=612x612&w=0&k=20&c=No_p6ymchJimJGi2HzzbLEm9w52Lrp1cCAbOgvOoW0M="
                                        alt="item16" className="imgsize" />
                                    <p className="text-center">Spices</p>
                                </div>
                                <div>
                                    <img src="https://images.unsplash.com/photo-1705475388190-775066fd69a5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cHVsc2VzJTIwYW5kJTIwZGFsc3xlbnwwfDB8MHx8fDA%3D"
                                        alt="item8" className="imgsize" />
                                    <p className="text-center">Pulses & Dals</p>
                                </div>
                                <div>
                                    <img src="https://www.hotelierindia.com/cloud/2024/10/20/indian-tea-time-snacks-in-group-sev-chivda-farsan-mixture-bakarwadi-served-in-different-bowls-free-photo-1.jpg"
                                        alt="item10" className="imgsize" />
                                    <p className="text-center">Dry Snacks</p>
                                </div>
                                <div>
                                    <img src="https://www.eximtrac.com/images/grocery/tea-coffee.jpg"
                                        alt="item1" className="imgsize" />
                                    <p className="text-center">Milk Mixtures</p>
                                </div>
                                <div>
                                    <img src="https://www.eximtrac.com/images/grocery/bak.jpg"
                                        alt="item2" className="imgsize" />
                                    <p className="text-center">Bakery</p>
                                </div>
                                <div>
                                    <img src="https://www.eximtrac.com/images/grocery/conf.jpg"
                                        alt="item3" className="imgsize" />
                                    <p className="text-center">Confectionery</p>
                                </div>
                                <div>
                                    <img src="https://www.naario.com/cdn/shop/articles/images_6db636f6-525b-4708-9b27-365bdd8d02b3.jpg?v=1696321310"
                                        alt="item4" className="imgsize" />
                                    <p className="text-center">Sweetner</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/1413268611/photo/ghee-butter-oil.webp?a=1&b=1&s=612x612&w=0&k=20&c=t1C7Tzg3Ym0_bro9wV1JSwvELqpfQwqv5kpogbrMT3Q="
                                        alt="item5" className="imgsize" />
                                    <p className="text-center">Oil/Ghee</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/1150014655/photo/colorful-variety-of-fryums-ready-to-fry-snacks-in-an-indian-market.jpg?s=612x612&w=0&k=20&c=Ly-wlAeTPHGe0dbLHUcfOlbkooLIVsEya7ljlLbkScc="
                                        alt="item6" className="imgsize" />
                                    <p className="text-center">Papad & Fryums</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/186831897/photo/flour-and-wheat-grains.webp?a=1&b=1&s=612x612&w=0&k=20&c=XRT7ZyZ4Az7pAKptVXnsmcogDwwMBgqNncX7YuJJmFo="
                                        alt="item7" className="imgsize" />
                                    <p className="text-center">Flours</p>
                                </div>
                                <div>
                                    <img src="https://www.mommieswithcents.com/wp-content/uploads/2012/08/MommiesWithCents_FoodColors.jpg"
                                        alt="item9" className="imgsize" />
                                    <p className="text-center">Food Enhancers</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/1054228718/photo/indian-sweets-in-a-plate-includes-gulab-jamun-rasgulla-kaju-katli-morichoor-bundi-laddu.jpg?s=612x612&w=0&k=20&c=hYWCXLaldKvhxdBa83M0RnUij7BCmhf-ywWdvyIXR40="
                                        alt="item11" className="imgsize" />
                                    <p className="text-center">Sweets</p>
                                </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <div style={{ margin: '20px' }}>
                            {/* <h2>React Slick Carousel</h2> */}
                            <Slider {...settings}>
                                <div>
                                    <img src="https://img2.exportersindia.com/product_images/bc-full/dir_80/2370564/pickles-chutney-753543.jpg"
                                        alt="item12" className="imgsize" />
                                    <p className="text-center">Food Siders</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/1497707611/photo/white-sauce-red-sauce-pasta-closeup-photo.jpg?s=612x612&w=0&k=20&c=8A7jJbWOROLD_FH1JgFiv-eTuGE9W_1V6qpvj05vB3k="
                                        alt="item13" className="imgsize" />
                                    <p className="text-center">Hot Snacks</p>
                                </div>
                                <div>
                                    <img src="https://images.unsplash.com/photo-1569058242567-93de6f36f8e6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGluc3RhbnQlMjBmb29kfGVufDB8MHwwfHx8MA%3D%3D"
                                        alt="item14" className="imgsize" />
                                    <p className="text-center">Instant Food</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/638936684/photo/rice-on-the-dark-background-healthy-eating-and-lifestyle.jpg?s=612x612&w=0&k=20&c=mDU95_u8HfMlToatbB1byJOHZCiJdu29hjy_VhMJlds="
                                        alt="item15" className="imgsize" />
                                    <p className="text-center">Rice</p>
                                </div>
                                <div>
                                    <img src="https://plus.unsplash.com/premium_photo-1673953509975-576678fa6710?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aGVhbHRoY2FyZXxlbnwwfDB8MHx8fDA%3D"
                                        alt="item17" className="imgsize" />
                                    <p className="text-center">Health Care</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/584574708/photo/soap-bar-and-liquid-shampoo-shower-gel-towels-spa-kit.jpg?s=612x612&w=0&k=20&c=TFeQmTwVUwKY0NDKFFORe3cwDCxRtotFgEujMswn3dc="
                                        alt="item18" className="imgsize" />
                                    <p className="text-center">Personal care</p>
                                </div>
                                <div>
                                    <img src="https://plus.unsplash.com/premium_photo-1667520405114-47d3677f966e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGNsZWFuaW5nJTIwYW5kJTIwaG91c2Vob2xkfGVufDB8MHwwfHx8MA%3D%3D"
                                        alt="item19" className="imgsize" />
                                    <p className="text-center">Cleaning & Household</p>
                                </div>
                                <div>
                                    <img src="https://plus.unsplash.com/premium_photo-1721227932255-175db3ae7f88?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YmV2ZXJhZ2VzfGVufDB8MHwwfHx8MA%3D%3D"
                                        alt="item20" className="imgsize" />
                                    <p className="text-center">Beverage</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/1218693828/photo/wooden-bowl-with-mixed-nuts-on-rustic-table-top-view-healthy-food-and-snack.jpg?s=612x612&w=0&k=20&c=89-ko7nwlcqM6HPvwaQ3tZus4apArtwHkFAB0IxPQpo="
                                        alt="item21" className="imgsize" />
                                    <p className="text-center">Dryfruits & Seeds</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/544807136/photo/various-fresh-dairy-products.jpg?s=612x612&w=0&k=20&c=U5T70bi24itoTDive1CVonJbJ97ChyL2Pz1I2kOoSRo="
                                        alt="item22" className="imgsize" />
                                    <p className="text-center">Dairy</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/835833518/photo/wholegrain-food-still-life-shot-on-rustic-wooden-table.webp?a=1&b=1&s=612x612&w=0&k=20&c=YMoRIZkohqBpyLRUSj3D5xoReSDMn8NbrRnMpwHVNS8="
                                        alt="item23" className="imgsize" />
                                    <p className="text-center">Foodgrains & Daily Foods</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/1132079979/photo/smoking-and-smelling-joss-sticks-at-home-feng-shui-copy-space.jpg?s=612x612&w=0&k=20&c=FKlch_fa-_NEJ1X-DomklJfbnX2IhkruTjH6VcdPE3k="
                                        alt="item24" className="imgsize" />
                                    <p className="text-center">Puja Items</p>
                                </div>
                                <div>
                                    <img src="https://media.istockphoto.com/id/1761769615/photo/indian-variety-of-traditional-mouth-freshener-in-bowl-for-sale-mukhwas-poona-traditional.jpg?s=612x612&w=0&k=20&c=macaeqpOIRmczIk2ZChJwMQSEVv1I1pwy1ho97N7rTs="
                                        alt="item25" className="imgsize" />
                                    <p className="text-center">Digestive & Mouth Freshners</p>
                                </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-5">
                        <p className="text-center member_register">Membeship & Registration</p>
                    </Col>
                </Row>

                <Row className="px-5 mx-5">
                    <Col lg={3}>
                        <Image src={FDA} alt="" thumbnail />
                    </Col>

                    <Col lg={3}>
                        <Image src={APEDA} alt="" thumbnail />
                    </Col>

                    <Col lg={3}>
                        <Image src={FIEO} alt="" thumbnail />
                    </Col>

                    <Col lg={3}>
                        <Image src={Spice} alt="" thumbnail />
                    </Col>
                </Row>

                <Row className="px-5 mx-5">
                    <Col lg={3} className="mt-3">
                        <Image src={FSSAI} alt="" thumbnail />
                    </Col>

                    <Col lg={3} className="mt-3">
                        <Image src={Tea} alt="" thumbnail />
                    </Col>

                    <Col lg={3} className="mt-3">
                        <Image src={OneStar} alt="" thumbnail />
                    </Col>

                </Row>

                <Row>
                    <Col className="mt-3">
                        <p className="featuredbrands">Featured Brands</p>
                    </Col>
                </Row>

                <Row>
                    <Col className="py-3">
                        <div className="slider">
                            <div className="slider-track">
                                <div className="slider-item">
                                    <img src={chheda} alt="Brand 1" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={dabur} alt="Brand 2" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={haldiram} alt="Brand 3" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={lijjat} alt="Brand 4" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={charliee} alt="Brand 5" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={coolberg} alt="Brand 6" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={bakerstreet} alt="Brand 7" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={chandan} alt="Brand 8" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={lords} alt="Brand 9" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={nutrapoorna} alt="Brand 10" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={madam} alt="Brand 11" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={shrifal} alt="Brand 12" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={omsom} alt="Brand 13" className="imagesize" />
                                </div>
                                <div className="slider-item">
                                    <img src={sarwar} alt="Brand 14" className="imagesize" />
                                </div>
                                {/* Repeat for more logos */}
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* <Row>
                    <Col className="mt-3">
                        <p className="trendingnews">Trending News</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card>
                            <Row>
                                <Col className="mt-2 px-4">
                                    <Card>
                                        <Row>
                                            <Col>
                                                <Card.Img src="holder.js/100px180" />
                                            </Col>

                                            <Col>
                                                <Card.Body>
                                                    <Card.Title>Card Title</Card.Title>
                                                    <Card.Text>
                                                        Some quick example text to build on the card title and make up the
                                                        bulk of the card's content.
                                                    </Card.Text>
                                                    <Button variant="primary" onClick={scrollToTop}>
                                                        <Link to="#" className="loadmore">
                                                            Go somewhere
                                                        </Link>
                                                    </Button>
                                                </Card.Body>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-2 px-4">
                                    <Card>
                                        <Row>
                                            <Col>
                                                <Card.Img src="holder.js/100px180" />
                                            </Col>

                                            <Col>
                                                <Card.Body>
                                                    <Card.Title>Card Title</Card.Title>
                                                    <Card.Text>
                                                        Some quick example text to build on the card title and make up the
                                                        bulk of the card's content.
                                                    </Card.Text>
                                                    <Button variant="primary" onClick={scrollToTop}>
                                                        <Link to="#" className="loadmore">
                                                            Go somewhere
                                                        </Link>
                                                    </Button>
                                                </Card.Body>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-2 mb-2 px-4">
                                    <Card>
                                        <Row>
                                            <Col>
                                                <Card.Img src="holder.js/100px180" />
                                            </Col>

                                            <Col>
                                                <Card.Body>
                                                    <Card.Title>Card Title</Card.Title>
                                                    <Card.Text>
                                                        Some quick example text to build on the card title and make up the
                                                        bulk of the card's content.
                                                    </Card.Text>
                                                    <Button variant="primary" onClick={scrollToTop}>
                                                        <Link to="#" className="loadmore">
                                                            Go somewhere
                                                        </Link>
                                                    </Button>
                                                </Card.Body>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-center mt-3 mb-4">
                                    <Button onClick={scrollToTop}>
                                        <Link to="#" className="loadmore">
                                            Load More
                                        </Link>
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row> */}

                <div class="icon-bar-floating">
                    <a href="https://wa.me/+919029937080"
                        // class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                    </a>

                    <a href="tel:9029937080">
                        <BiSolidPhoneCall className="call p-1" />
                    </a>
                </div>

                {/* ----------------FOOTER----------------- */}

                <MDBFooter bgColor='light' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                        <div className='me-5 d-none d-lg-block'>
                            <span>Get connected with us on social networks:</span>
                        </div>

                        <div>
                            <Link to="#" className='me-4 text-reset'>
                                <FaFacebook />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='twitter' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='instagram' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='linkedin' />
                            </Link>
                        </div>
                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Eximtrac
                                    </h6>
                                    <p>
                                        We are a certified Mumbai-based exporter, specializing in Indian groceries and FMCG products (food and non-food).
                                        With a vast portfolio of 200+ brands and new additions regularly, we source and deliver everything you need, consolidating all your requirements into one solution.
                                    </p>
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>Quick Links</h6>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/" className='text-reset foottext'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Aboutus" className='text-reset foottext'>
                                                About Us
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Products" className='text-reset foottext'>
                                                Shop Now
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/ContactUs" className='text-reset foottext'>
                                                Contact Us
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>OFFICE ADDRESS</h6>
                                    <p>
                                        Eximtrac<br />
                                        Shop No 2&3, Laxmi Apts, Behind Patel Nagar,
                                        M.G. Cross Road no 4, Kandivali West,
                                        Mumbai – 400067, India

                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>CONTACT & EMAIL</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div class="icon-bar">
                                            <a href="mailto:eximtrac@gmail.com" className="textdeco">
                                                <MdEmail className="me-2" /> eximtrac@gmail.com
                                            </a>
                                            <br />
                                            <a href="mailto:connect@eximtrac.com" className="textdeco">
                                                <MdEmail className="me-2 footerphone" /> connect@eximtrac.com
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:9699268642" className="textdeco">
                                                <FaPhoneAlt className="me-2" /> +91-96992 68642
                                            </a>
                                            <br />
                                            <a href="tel:9029937080" className="textdeco">
                                                <FaPhoneAlt className="me-2 footerphone" /> +91-90299 37080
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-4 footerlastbgco'>
                        © 2024 Copyright:
                        <a className='text-reset fw-bold' href='Eximtrac'>
                            Eximtrac
                        </a>
                    </div>
                </MDBFooter>
            </Container >
        </>
    );
};

export default Home;