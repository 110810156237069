import React, { useEffect, useState } from "react";
import "./Aboutus.css";
import { Nav, Navbar, Container, Button, Row, Col, Card, Image, Dropdown } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import Account from "../src/Photos/Account.png";
import container from "../src/Photos/Container.png";
// import dummy4 from "../src/Photos/Dummy4.png";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import gallery1 from "../src/Photos/Gallery1.jpg";
import gallery2 from "../src/Photos/Gallery2.jpg";
import gallery3 from "../src/Photos/Gallery3.jpg";
import gallery4 from "../src/Photos/Gallery4.jpg";
import gallery5 from "../src/Photos/Gallery5.jpg";
import gallery6 from "../src/Photos/Gallery6.jpg";
import gallery7 from "../src/Photos/Gallery7.jpg";
import gallery8 from "../src/Photos/Gallery8.jpg";

// import fmcg1 from "../src/Photos/FMCG 1.png";
// import fmcg4 from "../src/Photos/FMCG 4.png";
// import fmcg3 from "../src/Photos/FMCG 3.png";

import exportimg from "../src/Photos/export.png";
// import cs from "../src/Photos/customersatisfaction.png";


import status from "../src/Photos/StatusHolderCertificate.png";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";

function Aboutus() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in when the component mounts
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        setIsLoggedIn(!!(token && userId)); // Set `isLoggedIn` based on the existence of token and userId
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            alert("You are already logged in!"); // Show an alert if already logged in
        } else {
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        console.log("User logged out and data removed from localStorage.");
        setIsLoggedIn(false);
        navigate("/Login"); // Redirect to login
    };




    const documents = [
        { name: "GST Certificate", file: "EXIMTRAC GST certificate from 24 11 2023.pdf" },
        { name: "FSSAI License", file: "fssai license - UPTO 20.01.2029.pdf" },
        { name: "IEC Copy", file: "IEC COPY NEW.pdf" },
        { name: "STATUS HOLDER CERTIFICATE", file: "ONE STAR EXPORT HOUSE - Status Holder Certificate.pdf" }
    ];

    // Function to handle file download
    const handleDownload = (file) => {
        const fileUrl = `${process.env.PUBLIC_URL}/${file}`;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = file; // Suggested filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>

                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container fluid>
                <Row>
                    <Col className="about-pic text-center">
                        <p className="aboutus"><span className="titlea">A</span>BOUT U<span className="titlea">S</span></p>
                        {/* <Carousel className="w-100">
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100"
                                    src={fmcg3}
                                    alt="Slide1"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100"
                                    src={fmcg4}
                                    alt="Slide2"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100"
                                    src={fmcg1}
                                    alt="Slide3"
                                />
                            </Carousel.Item>
                        </Carousel> */}
                    </Col>
                </Row>

                <Row className="justify-content-center mb-5">
                    <Col className="mt-5" lg={3} xs={12}>
                        <Card className="aboutuscard">
                            <img
                                className="d-block w-100 aboutuscard"
                                src={exportimg}
                                alt="Slide2"
                            />

                            {/* <Card.Img src={dummy4} /> */}
                        </Card>
                    </Col>

                    <Col className="mt-5" lg={6} xs={12}>
                        <Card className="aboutustextcard px-4">
                            <Card.Title className="abtitle mt-4">Who We Are</Card.Title>
                            <Card.Text className="mb-4">
                                Exim Trac is a certified Star Export House based in Mumbai with over 35 years of expertise in serving global markets.<br />
                                We specialize in exporting groceries, including spices, cereals, pulses, grains, snacks, sauces, pickles, and more.
                                Beyond groceries, we also offer a wide range of FMCG products like healthcare items, personal care essentials, homecare products, and puja samagri.
                                <br /><br />
                                Our in-house brand, ‘Lords’, delivers premium-quality flours, grains, pulses, spices, and dry fruits.
                                We also provide white-labeling services, offering tailored packaging, labels, and branding to help you create your own unique product line.

                            </Card.Text>
                        </Card>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <Col className="mt-3" lg={3} xs={12}>
                        <p className="abtitle text-center">Why Choose Us</p>
                    </Col>
                </Row>

                <Container>
                    <Row>
                        <Col lg={6} xs={12} className="mt-3">
                            <Card className="whyusmain1">
                                <Row>
                                    <Col lg={5} xs={8}>
                                        <Card className="whyus1 text-center">
                                            <p className="whyus1headtext mt-3">Diverse Brand Selection</p>
                                        </Card>
                                    </Col>
                                </Row>
                                <p className="whyus1text p-4 text-center">Partnering with 200+ trusted brands to meet every need.</p>
                            </Card>
                        </Col>

                        <Col lg={6} xs={12} className="mt-3">
                            <Card className="whyusmain2">
                                <Row>
                                    <Col lg={5} xs={8}>
                                        <Card className="whyus2 text-center">
                                            <p className="whyus2headtext mt-3">Seamless Digital Experience</p>
                                        </Card>
                                    </Col>
                                </Row>
                                <p className="whyus2text p-4 text-center">A user-friendly online platform for effortless Shopping.</p>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} xs={12} className="mt-4">
                            <Card className="whyusmain3">
                                <Row>
                                    <Col lg={5} xs={8}>
                                        <Card className="whyus3 text-center">
                                            <p className="whyus3headtext mt-3">Tailored Packaging Solutions</p>
                                        </Card>
                                    </Col>
                                </Row>
                                <p className="whyus3text p-4 text-center">Custom packaging designed to suit your product requirements.</p>
                            </Card>
                        </Col>

                        <Col lg={6} xs={12} className="mt-4">
                            <Card className="whyusmain4">
                                <Row>
                                    <Col lg={5} xs={8}>
                                        <Card className="whyus4 text-center">
                                            <p className="whyus4headtext mt-3">Competitive & Fair Pricing</p>
                                        </Card>
                                    </Col>
                                </Row>
                                <p className="whyus4text p-4 text-center">Get the best value with cost-effective pricing.</p>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} xs={12} className="mt-4">
                            <Card className="whyusmain5">
                                <Row>
                                    <Col lg={5} xs={8}>
                                        <Card className="whyus5 text-center">
                                            <p className="whyus5headtext mt-3">Ethics & Values at Core</p>
                                        </Card>
                                    </Col>
                                </Row>
                                <p className="whyus5text p-4 text-center">Our foundation is built on trust, transparency, and integrity.</p>
                            </Card>
                        </Col>

                        <Col lg={6} xs={12} className="mt-4">
                            <Card className="whyusmain6">
                                <Row>
                                    <Col lg={5} xs={8}>
                                        <Card className="whyus6 text-center">
                                            <p className="whyus6headtext mt-3">Dynamic & Young Team</p>
                                        </Card>
                                    </Col>
                                </Row>
                                <p className="whyus6text p-4 text-center">Driven by innovation, passion, and fresh perspectives.</p>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Row>
                    <Col className="mt-5 text-center">
                        <p className="catalogue">Our Credentials</p>
                    </Col>
                </Row>

                {/* <Row>
                    <Col>
                        <Card className="cataloguecard">
                            <p className="p-5 m-5 text-center cataloguecardtext">
                                Star export house of India, ISO Certified, FSSAI License, Drug License, FIEO Membership, Spice Board Membership, Coconut Board Membership, Tea Board Membership.
                            </p>

                            <Row>
                                <Col className="text-center mb-5 pb-4">
                                    <Button onClick={scrollToTop} className="cataloguebtn">
                                        <Link to="/OurCredentials" className="cataloguebtn">
                                            Explore Our Credentials
                                        </Link>
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row> */}


                <Row>
                    {documents.length > 0 ? (
                        documents.map((doc, index) => (
                            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4 mt-3">
                                <Card>
                                    <Card.Img variant="top" src={status} />
                                    <Card.Body>
                                        <Card.Title>{doc.name}</Card.Title>
                                        <Card.Text>
                                            Click the button below to download the document.
                                        </Card.Text>
                                        <Button
                                            variant="primary"
                                            onClick={() => handleDownload(doc.file)}
                                            className="w-100"
                                        >
                                            Download
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <Col>
                            <p>No Documents Available</p>
                        </Col>
                    )}
                </Row>

                <Row>
                    <Col className="mt-5 mb-3 text-center">
                        <p className="gallery">Gallery</p>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-2" lg={3} xs={6}>
                        <Image src={gallery1} thumbnail />
                    </Col>

                    <Col className="mt-2" lg={3} xs={6}>
                        <Image src={gallery2} thumbnail />
                    </Col>

                    <Col className="mt-2" lg={3} xs={6}>
                        <Image src={gallery3} thumbnail />
                    </Col>

                    <Col className="mt-2" lg={3} xs={6}>
                        <Image src={gallery4} thumbnail />
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-2 mb-3" lg={3} xs={6}>
                        <Image src={gallery5} thumbnail />
                    </Col>

                    <Col className="mt-2 mb-3" lg={3} xs={6}>
                        <Image src={gallery6} thumbnail />
                    </Col>

                    <Col className="mt-2 mb-3" lg={3} xs={6}>
                        <Image src={gallery7} thumbnail />
                    </Col>

                    <Col className="mt-2 mb-3" lg={3} xs={6}>
                        <Image src={gallery8} thumbnail />
                    </Col>
                </Row>

                {/* <Row>
                    <Col className="text-center mt-3 mb-4">
                        <Button onClick={scrollToTop} className="galleryloadmore">
                            <Link to="#Gallery" className="galleryloadmore">
                                Load More
                            </Link>
                        </Button>
                    </Col>
                </Row> */}

                <div class="icon-bar-floating">
                    <a href="https://wa.me/+919029937080"
                        // class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                    </a>

                    <a href="tel:9029937080">
                        <BiSolidPhoneCall className="call p-1" />
                    </a>
                </div>

                {/* ----------------FOOTER----------------- */}

                <MDBFooter bgColor='light' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                                        <div className='me-5 d-none d-lg-block'>
                                            <span>Get connected with us on social networks:</span>
                                        </div>
                
                                        <div>
                                            <Link to="#" className='me-4 text-reset'>
                                                <FaFacebook />
                                            </Link>
                
                                            <Link to="#" className='me-4 text-reset'>
                                                <MDBIcon color='secondary' fab icon='twitter' />
                                            </Link>
                
                                            <Link to="#" className='me-4 text-reset'>
                                                <MDBIcon color='secondary' fab icon='instagram' />
                                            </Link>
                
                                            <Link to="#" className='me-4 text-reset'>
                                                <MDBIcon color='secondary' fab icon='linkedin' />
                                            </Link>
                                        </div>
                                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Eximtrac
                                    </h6>
                                    <p>
                                        We are a certified Mumbai-based exporter, specializing in Indian groceries and FMCG products (food and non-food).
                                        With a vast portfolio of 200+ brands and new additions regularly, we source and deliver everything you need, consolidating all your requirements into one solution.
                                    </p>
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>Quick Links</h6>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/" className='text-reset foottext'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Aboutus" className='text-reset foottext'>
                                                About Us
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Products" className='text-reset foottext'>
                                                Shop Now
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/ContactUs" className='text-reset foottext'>
                                                Contact Us
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>OFFICE ADDRESS</h6>
                                    <p>
                                        Eximtrac<br />
                                        Shop No 2&3, Laxmi Apts, Behind Patel Nagar,
                                        M.G. Cross Road no 4, Kandivali West,
                                        Mumbai – 400067, India

                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>CONTACT & EMAIL</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div class="icon-bar">
                                            <a href="mailto:eximtrac@gmail.com" className="textdeco">
                                                <MdEmail className="me-2" /> eximtrac@gmail.com
                                            </a>
                                            <br />
                                            <a href="mailto:connect@eximtrac.com" className="textdeco">
                                                <MdEmail className="me-2 footerphone" /> connect@eximtrac.com
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:9699268642" className="textdeco">
                                                <FaPhoneAlt className="me-2" /> +91-96992 68642
                                            </a>
                                            <br />
                                            <a href="tel:9029937080" className="textdeco">
                                                <FaPhoneAlt className="me-2 footerphone" /> +91-90299 37080
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-4 footerlastbgco'>
                        © 2024 Copyright:
                        <a className='text-reset fw-bold' href='Eximtrac'>
                            Eximtrac
                        </a>
                    </div>
                </MDBFooter>
            </Container>
        </>
    );
}

export default Aboutus;