import React, { useEffect, useState } from "react";
import "./EnquiryPage.css";
import { Button, Card, Col, Container, Nav, Navbar, Row, Dropdown, Table } from "react-bootstrap";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import container from "../src/Photos/Container.png";

function EnquiryPage() {


    // data = localStorage.getItem("loginData")
    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };


    const [input, setData] = useState([]); // Ensure `input` starts as an empty array
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        if (!token || !userId) {
            console.error("Token or User ID not found in localStorage");
            setData([]);
            return;
        }

        fetch(`https://api-ft5g.onrender.com/Get-data-for-user-side-enquiry-page/${userId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.json();
            })
            .then((docs) => {
                if (docs && docs.data) {
                    setData(docs.data);
                    console.log(docs.data);
                } else {
                    console.warn("Data property not found in response:", docs);
                    setData([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setData([]);
            });
    }, []); // Runs only on component mount


    const handleViewOrder = (orderId) => {
        // Store OrderID in localStorage
        localStorage.setItem("selectedOrderId", orderId);

        // Navigate to Order Details Page
        navigate(`/Enquiry/${orderId}`);
    };


    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in when the component mounts
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        setIsLoggedIn(!!(token && userId)); // Set `isLoggedIn` based on the existence of token and userId
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            alert("You are already logged in!"); // Show an alert if already logged in
        } else {
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        console.log("User logged out and data removed from localStorage.");
        setIsLoggedIn(false);
        navigate("/Login"); // Redirect to login
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container fluid>
                <Row>
                    <Col className="mt-3">
                        <p className="enquiry">ENQUIRY</p>
                        <Card className="p-2">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th className="">
                                            Order ID
                                        </th>

                                        <th>
                                            User ID
                                        </th>

                                        <th>
                                            Email ID
                                        </th>

                                        <th>
                                            Customer Name
                                        </th>

                                        <th>
                                            Company Name
                                        </th>

                                        <th>
                                            Enquiry Date
                                        </th>

                                        <th>
                                            View
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {input.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p className="mt-2 ccn">{item.OrderID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.UserID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.EmailID}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.FirstName} {item.LastName}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{item.CompanyName}</p>
                                            </td>

                                            <td>
                                                <p className="mt-2 ccn">{new Date(item.UploadDate).toLocaleString()}</p>
                                            </td>


                                            <td>
                                                <Button
                                                    className="mt-1 dotbtn"
                                                    onClick={() => handleViewOrder(item.OrderID)}
                                                >
                                                    View Enquiry / Quotations
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default EnquiryPage;