import React, { useState, useEffect } from "react";
import "./ViewQuotationOrder.css";
import { Link, useParams } from "react-router-dom";
import { Button, Navbar, Nav, Container, Row, Col, Card, Form, Dropdown, Alert, Table } from "react-bootstrap";
import Logo from "../src/Photos/Logo.jpg";
import Account from "../src/Photos/Account.png";

function ViewQuotationOrder() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const { userId, orderId } = useParams(); // Get UserID and OrderID from the URL


    // States for file inputs
    const [quotationFile, setQuotationFile] = useState(null);
    const [proformaInvoiceFile, setProformaInvoiceFile] = useState(null);
    const [shippingDocuments, setShippingDocuments] = useState([]);

    // States for text inputs
    const [shipmentStatus, setShipmentStatus] = useState("");
    const [blNumber, setBlNumber] = useState("");
    const [shippingLines, setShippingLines] = useState("");
    const [eta, setEta] = useState("");
    const [ProformaInvoiceNumber, setProformaInvoiceNumber] = useState("");
    const [CommercialInvoiceNumber, setCommercialInvoiceNumber] = useState("");
    const [CommercialInvoiceDate, setCommercialInvoiceDate] = useState("");
    const [ProformaInvoiceDate, setProformaInvoiceDate] = useState("");
    const [DischargePort, setDischargePort] = useState("");
    const [FinalDestination, setFinalDestination] = useState("");


    // States for success/error messages
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState(""); // "success" or "danger"

    // Handle file change
    const handleFileChange = (e, setFile) => {
        const files = e.target.files;
        setFile(files); // Set file(s) to state
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        // Append files
        if (quotationFile?.[0]) formData.append("quotationFile", quotationFile[0]);
        if (proformaInvoiceFile?.[0]) formData.append("proformaFile", proformaInvoiceFile[0]);
        if (shippingDocuments?.length > 0) {
            for (let i = 0; i < shippingDocuments.length; i++) {
                formData.append("shippingDocuments", shippingDocuments[i]);
            }
        }

        // const userId = localStorage.getItem('userId');

        // Append other fields
        formData.append("OrderID", orderId); // Replace with dynamic OrderID
        formData.append("UserID", userId); // Replace with dynamic UserID
        formData.append("ShippingStatus", shipmentStatus);
        formData.append("BLNumber", blNumber);
        formData.append("ShippingLines", shippingLines);
        formData.append("ETA", eta);
        formData.append("ProformaInvoiceNumber", ProformaInvoiceNumber)
        formData.append("CommercialInvoiceNumber", CommercialInvoiceNumber)
        formData.append("CommercialInvoiceDate", CommercialInvoiceDate)
        formData.append("ProformaInvoiceDate", ProformaInvoiceDate)
        formData.append("DischargePort", DischargePort)
        formData.append("FinalDestination", FinalDestination)



        try {
            const response = await fetch(`https://api-ft5g.onrender.com/upload-files-to-user/${orderId}/${userId}`, {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Upload successful:", data);
                setMessage("Data has been successfully submitted!");
                setMessageType("success");
            } else {
                const error = await response.json();
                console.error("Error uploading files:", error);
                setMessage("File upload failed. Please try again.");
                setMessageType("danger");
            }
        } catch (err) {
            console.error("Network error:", err);
            setMessage("Network error. Please check your connection.");
            setMessageType("danger");
        }
    };


    // const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState([]);

    useEffect(() => {
        // Adjust the API URL based on the backend route
        const url = orderId
            ? `https://api-ft5g.onrender.com/Get-container-place-enquiry-user-and-admin/${userId}/${orderId}`
            : `https://api-ft5g.onrender.com/Get-container-place-enquiry-user-and-admin/${userId}`;

        fetch(url)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => setUserDetails(data.data))
            .catch((err) => console.error("Error fetching data:", err));
    }, [orderId, userId]);




    const [fileData, setFileData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFileData = async () => {
            const token = localStorage.getItem("token");

            if (!userId || !token) {
                const errorMsg = "Missing required data: userId or token.";
                setError(errorMsg);
                console.error(errorMsg);
                return;
            }

            try {
                const response = await fetch(
                    `https://api-ft5g.onrender.com/get-files-data/${userId}/${orderId}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    console.log("File Data Response:", data); // Debugging response
                    setFileData(data.files || []);
                } else {
                    const errorMsg = `Failed to fetch file data: ${response.status} ${response.statusText}`;
                    throw new Error(errorMsg);
                }
            } catch (err) {
                console.error("Error fetching file data:", err);
                setError(err.message);
            }
        };

        fetchFileData();
    }, [userId, orderId]); // Add userId and orderId as dependencies

    const downloadFile = (filePath, fileName) => {
        const link = document.createElement("a");
        link.href = `https://api-ft5g.onrender.com/${filePath}`;
        link.download = fileName;
        link.click();
    };


    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container fluid>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* <Nav className="me-auto">
                        </Nav> */}

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminDash" className="adminnavtext">Dashboard</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Customers" className="adminnavtext">Customers</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Category" className="adminnavtext">Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/SubCategory" className="adminnavtext">Sub-Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Brands" className="adminnavtext">Brands</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminProducts" className="adminnavtext">Products</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Quotations" className="adminnavtext">Enquiries</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminOrder" className="adminnavtext">Orders</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUsResponse" className="adminnavtext">Contact</Nav.Link>
                            </Button>


                            <Dropdown>
                                <Dropdown.Toggle className="logoscrollbtn p-0 mx-3 mt-1 responsiveview" id="dropdown-basic">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="/Profile">My Profile</Dropdown.Item> */}
                                    {/* <Dropdown.Item href="/OrderPage">Past Orders</Dropdown.Item> */}
                                    <Dropdown.Item href="/AdminLogin">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container fluid className="">
                <Row>
                    <Col className="mt-3 mb-3" lg={6}>
                        <Card>
                            <Form onSubmit={handleSubmit}>
                                {/* Display Success/Error Message */}
                                {message && (
                                    <Alert
                                        variant={messageType}
                                        className="mx-3 mt-3"
                                        onClose={() => setMessage("")}
                                        dismissible
                                    >
                                        {message}
                                    </Alert>
                                )}
                                {/* File Upload Fields */}
                                <Row>
                                    <Col className="mt-3 mx-3">
                                        <Form.Group controlId="formFileQuotation" className="mb-3">
                                            <Form.Label>Upload Quotation</Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={(e) => handleFileChange(e, setQuotationFile)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="mx-3">
                                        <Form.Group controlId="formFileProforma" className="mb-3">
                                            <Form.Label>Upload Proforma Invoice</Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={(e) => handleFileChange(e, setProformaInvoiceFile)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="mx-3">
                                        <Form.Group controlId="formFileMultiple" className="mb-3">
                                            <Form.Label>Upload Shipping Documents</Form.Label>
                                            <Form.Control
                                                type="file"
                                                multiple
                                                onChange={(e) => handleFileChange(e, setShippingDocuments)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="mt-3 mb-3 mx-3">
                                        <Button type="submit" className="adminproductsaddnewcatebtn w-100">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>

                    <Col className="mt-3 mb-3" lg={6}>
                        <Card>
                            <Form onSubmit={handleSubmit}>
                                {/* Text Fields */}
                                <Row>
                                    <Col className="mt-2 mx-3">
                                        <Form.Label>Update Shipping Details</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            onChange={(e) => setShipmentStatus(e.target.value)}
                                            value={shipmentStatus}
                                        >
                                            <option value="">----Shipment Status----</option>
                                            <option value="Inquiry Places">Quotation Uploaded</option>
                                            <option value="Order Confirm">Order Confirm</option>
                                            <option value="Quotation Uploaded">Proforma Uploaded</option>
                                            <option value="In Transit">In Transit</option>
                                            <option value="Order Reached Destination Port">Cargo Reached</option>
                                        </Form.Select>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="mt-3 mx-3">
                                        <Form.Group className="mb-3" controlId="formGroupBLNo.">
                                            <Form.Label>BL Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter BL Number"
                                                value={blNumber}
                                                onChange={(e) => setBlNumber(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Proforma Invoice Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Proforma Invoice Number"
                                                value={ProformaInvoiceNumber}
                                                onChange={(e) => setProformaInvoiceNumber(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formGroupShippingLines">
                                            <Form.Label>Shipping Lines</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Name of Shipping Lines"
                                                value={shippingLines}
                                                onChange={(e) => setShippingLines(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formGroupETA">
                                            <Form.Label>Estimated Time of Arrival</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Estimated Time of Arrival"
                                                value={eta}
                                                onChange={(e) => setEta(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formGroupCIN">
                                            <Form.Label>Commercial Invoice Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Commercial Invoice Number"
                                                value={CommercialInvoiceNumber}
                                                onChange={(e) => setCommercialInvoiceNumber(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formGroupCID">
                                            <Form.Label>Commercial Invoice Date</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Commercial Invoice Date DD/MM/YYYY"
                                                value={CommercialInvoiceDate}
                                                onChange={(e) => setCommercialInvoiceDate(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formGroupPID">
                                            <Form.Label>Proforma Invoice Date</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Proforma Invoice Date DD/MM/YYYY"
                                                value={ProformaInvoiceDate}
                                                onChange={(e) => setProformaInvoiceDate(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formGroupPID">
                                            <Form.Label>Discharge Port</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Discharge Port"
                                                value={DischargePort}
                                                onChange={(e) => setDischargePort(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formGroupPID">
                                            <Form.Label>Final Destination</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Final Destination"
                                                value={FinalDestination}
                                                onChange={(e) => setFinalDestination(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Submit Button */}
                                <Row>
                                    <Col className="mt-3 mb-3 mx-3">
                                        <Button type="submit" className="adminproductsaddnewcatebtn w-100">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="mb-3 mt-3" lg={12} xs={12}>
                        <Card>
                            <h2 className="my-3">Shipment Files</h2>
                            {error && <p className="text-danger">Error: {error}</p>}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Order ID</th>
                                        {/* <th>User ID</th> */}
                                        <th>Proforma Invoice Number</th>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        {/* <th>File Type</th> */}
                                        <th>Upload Date</th>
                                        <th>Order Status</th>
                                        <th>BL Number</th>
                                        <th>Shipping Lines</th>
                                        <th>ETA</th>
                                        <th>Commercial Invoice Number</th>
                                        <th>Commercial Invoice Date</th>
                                        <th>Proforma Invoice Date</th>
                                        <th>Discharge Port</th>
                                        <th>Final Destination</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fileData.map((file, index) => (
                                        <tr key={index}>
                                            <td>{file.OrderID}</td>
                                            {/* <td>{file.UserID}</td> */}
                                            <td>{file.ProformaInvoiceNumber}</td>
                                            <td>{file.DocumentType}</td>
                                            <td>{file.FileName}</td>
                                            {/* <td>{file.FileType}</td> */}
                                            <td>{new Date(file.UploadDate).toLocaleString()}</td>
                                            <td>{file.ShippingStatus}</td>
                                            <td>{file.BLNumber}</td>
                                            <td>{file.ShippingLines}</td>
                                            <td>{file.ETA}</td>
                                            <td>{file.CommercialInvoiceNumber}</td>
                                            <td>{file.CommercialInvoiceDate}</td>
                                            <td>{file.ProformaInvoiceDate}</td>
                                            <td>{file.DischargePort}</td>
                                            <td>{file.FinalDestination}</td>
                                            <td>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => downloadFile(file.FilePath, file.FileName)}
                                                >
                                                    Download
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    {/* <Col lg={8} className="mt-3">
                        <Card className="p-4">
                            <h5 className="text-center mb-4">ORDER DETAILS</h5>

                            {
                                orderDetails.length > 0 ? (
                                    orderDetails.map((item, index) => (
                                        <Row key={index}>
                                            <hr className="mt-3 mb-3" />
                                            <Col>
                                                <Row className="text-center mb-2 mt-3">
                                                    <Col>
                                                        <p className="mb-1 text-muted orderfont2">{item.OrderID}</p>
                                                        <h4 className="orderfont1">{item.PRODUCT_DESCRIPTION}</h4>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-muted mb-1 orderfont1">ORDERED ON</p>
                                                        <p className="orderfont1">{item.UploadDate}</p>
                                                    </Col>
                                                    <Col>
                                                        <p className="text-muted mb-1 orderfont1">STATUS</p>
                                                        <p className="text-warning orderfont1" style={{ fontWeight: "bold" }}>Ongoing</p>
                                                    </Col>
                                                </Row>

                                                <Row className="text-center">
                                                    <Col className="mt-4">
                                                        <p className="text-muted mb-1 orderfont1">QUANTITY</p>
                                                        <p className="orderfont1">{item.UNIT_PER_CTN} {item.UNIT} / CARTONS</p>
                                                    </Col>
                                                    <Col className="mt-4">
                                                        <p className="text-muted mb-1 orderfont1">NO. OF CARTONS</p>
                                                        <p className="orderfont1">{item.CartonQty}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))
                                ) : (
                                    <p>No details available for this order.</p>
                                )
                            }

                            <hr className="my-4" />

                            <p className="text-center text-muted orderfont">
                                Your order will be delivered tentetively by <span style={{ fontWeight: "bold" }}>15 Dec 2024</span>
                            </p>

                            <hr />
                        </Card>
                    </Col> */}

                    <Col className="mt-3">
                        <Card className="p-4">
                            <h5 className="text-center mb-4">ORDER DETAILS</h5>

                            {userDetails.length > 0 ? (
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr className="text-center">
                                            <th>Order ID</th>
                                            <th>Product Description</th>
                                            <th>Ordered On</th>
                                            {/* <th>Status</th> */}
                                            <th>Quantity</th>
                                            <th>No. of Cartons</th>
                                            <th>Total Cases</th>
                                            <th>Total QTY</th>
                                            <th>Rate Per Unit USD</th>
                                            <th>Rate Per Case USD</th>
                                            <th>Total Rate In USD FOB INDIA</th>
                                            <th>Total Net Weight</th>
                                            <th>Total Gross Weight</th>
                                            <th>Total Volume</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userDetails.map((item, index) => (
                                            <tr key={index} className="text-center">
                                                <td>{item.OrderID}</td>
                                                <td>{item.PRODUCT_DESCRIPTION}</td>
                                                <td>{new Date(item.UploadDate).toLocaleString()}</td>
                                                {/* <td className="text-warning" style={{ fontWeight: "bold" }}>Ongoing</td> */}
                                                <td>{item.UNIT_PER_CTN} {item.UNIT} / Case</td>
                                                <td>{item.CartonQty}</td>
                                                <td>{item.TotalCases}</td>
                                                <td>{item.TotalQty}</td>
                                                <td>{item.RatePerUnitUSD}</td>
                                                <td>{item.RatePerCaseUSD}</td>
                                                <td>{item.TotalRateInUSDFobIndia}</td>
                                                <td>{item.TotalNetWeight}</td>
                                                <td>{item.TotalGrossWeight}</td>
                                                <td>{item.TotalVolume}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p className="text-center text-muted">No details available for this order.</p>
                            )}

                            {/* <hr className="my-4" />

                            <p className="text-center text-muted orderfont">
                                Your order will be delivered tentatively by <span style={{ fontWeight: "bold" }}>15 Dec 2024</span>
                            </p> */}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ViewQuotationOrder;