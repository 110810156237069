import React, { useEffect, useState } from "react";
import "./AdminCustomerDetail.css";
// import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useParams } from "react-router-dom";
import { Button, Navbar, Nav, Container, Row, Col, Card, Form, Dropdown } from "react-bootstrap";
// import dash from "../src/Photos/Dashboard.png";
// import menu from "../src/Photos/Menu.png";
// import { IoIosArrowForward } from "react-icons/io";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";

import { CgProfile } from "react-icons/cg";

function AdminCustomerDetail() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    // const [toggled, setToggled] = React.useState(false);


    // State to manage edit mode
    // const [isEditable, setIsEditable] = useState(false);

    // Toggle edit mode
    // const handleEdit = () => setIsEditable(true);
    // const handleCancel = () => setIsEditable(false);

    const { UserID } = useParams(); // Get the userId from the URL
    const [customer, setCustomer] = useState({});

    useEffect(() => {
        const fetchCustomerDetails = async () => {
            try {
                const response = await fetch(`https://api-ft5g.onrender.com/get-profile/${UserID}`);
                if (!response.ok) throw new Error("Failed to fetch customer details");

                const data = await response.json();
                setCustomer(data); // Store the customer data
            } catch (error) {
                console.error("Error fetching customer details:", error);
                setCustomer(null); // Prevents null reference errors
            }
        };

        if (UserID) fetchCustomerDetails(); // Prevent fetch call if UserID is missing
    }, [UserID]);



    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container fluid>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* <Nav className="me-auto">
                        </Nav> */}

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminDash" className="adminnavtext">Dashboard</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Customers" className="adminnavtext">Customers</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Category" className="adminnavtext">Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/SubCategory" className="adminnavtext">Sub-Category</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Brands" className="adminnavtext">Brands</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminProducts" className="adminnavtext">Products</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Quotations" className="adminnavtext">Enquiries</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/AdminOrder" className="adminnavtext">Orders</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUsResponse" className="adminnavtext">Contact</Nav.Link>
                            </Button>


                            <Dropdown>
                                <Dropdown.Toggle className="logoscrollbtn p-0 mx-2 mt-1 responsiveview" id="dropdown-basic">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="/Profile">My Profile</Dropdown.Item> */}
                                    {/* <Dropdown.Item href="/OrderPage">Past Orders</Dropdown.Item> */}
                                    <Dropdown.Item href="/AdminLogin">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container>
                <Row>
                    <Col className="mt-3">
                        <Card className="profile_card mb-3">
                            <Row className="px-5 mt-3">
                                <Col lg={1}>
                                    <CgProfile className="profile_icon" />
                                </Col>

                                <Col>
                                    <p className="profilename">{customer?.FirstName || customer?.EmailID || "No Data Available"}</p>
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col className="px-5 my-3">
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                                    <Form.Label>Email Address / User ID</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter email"
                                                        value={customer.EmailID} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Name"
                                                        value={customer.FirstName} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                                    <Form.Label>Alternate Phone Number</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter Alternate Phone Number"
                                                        value={customer.AlternatePhone} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Phone Number"
                                                        value={customer.PhoneNumber} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Alternate Email ID</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Alternate Email ID"
                                                        value={customer.AlternateEmailID} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Company Name</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Company Name"
                                                        value={customer.CompanyName} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Company Address</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Company Address"
                                                        value={customer.CompanyAddress} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Country"
                                                        value={customer.Country} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter State"
                                                        value={customer.State} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter City"
                                                        value={customer.City} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Zip Code</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Zip Code"
                                                        value={customer.ZipCode} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Discharge Port</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Discharge Port"
                                                        value={customer.DischargePort} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Final Destination</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Final Destination"
                                                        value={customer.FinalDestination} readOnly
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {/* <Row>
                                            <Col lg={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Customer Code</Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Customer Code"
                                                    // readOnly={!isEditable} 
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row> */}

                                        {/* <Row>
                                                <Col className="text-end">
                                                    <Button className="profile_edit">
                                                        Edit
                                                    </Button>
                                                </Col>

                                                <Col>
                                                    <Button className="profile_cancel">
                                                        Cancel
                                                    </Button>
                                                </Col>
                                            </Row> */}

                                        {/* <div className="d-flex justify-content-center mt-3">
                                            {isEditable ? (
                                                <>
                                                    <Button variant="primary" className="me-2" onClick={() => setIsEditable(false)}>
                                                        Save
                                                    </Button>
                                                    <Button variant="secondary" onClick={handleCancel}>
                                                        Cancel
                                                    </Button>
                                                </>
                                            ) : (
                                                <Button variant="primary" onClick={handleEdit}>
                                                    Edit
                                                </Button>
                                            )}
                                        </div> */}
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AdminCustomerDetail;