import React, { useEffect, useState } from "react";
import "./Order.css";
import { Button, Card, Col, Container, Nav, Navbar, Row, Dropdown, Table } from "react-bootstrap";
// import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
// import { IoIosArrowForward } from "react-icons/io";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import container from "../src/Photos/Container.png";

function Order() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const navigate = useNavigate();

    const { userId, orderId } = useParams(); // Get userId and orderId from the URL

    const [orderDetails, setOrderDetails] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            const token = localStorage.getItem("token");

            if (!orderId || !token) {
                const errorMsg = "Missing required data: orderId or token.";
                setError(errorMsg);
                console.error(errorMsg);
                return;
            }

            try {
                const response = await fetch(
                    `https://api-ft5g.onrender.com/Get-container-place-enquiry-user-and-admin/${userId}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    console.log("Order Details Response:", data); // Debugging response
                    const filteredOrder = data.data?.filter((item) => item.OrderID === orderId);
                    setOrderDetails(filteredOrder || []);
                } else {
                    const errorMsg = `Failed to fetch order details: ${response.status} ${response.statusText}`;
                    throw new Error(errorMsg);
                }
            } catch (err) {
                console.error("Error fetching order details:", err);
                setError(err.message);
            }
        };

        const fetchFileData = async () => {
            const token = localStorage.getItem("token");

            if (!userId || !token) {
                const errorMsg = "Missing required data: userId or token.";
                setError(errorMsg);
                console.error(errorMsg);
                return;
            }

            try {
                const response = await fetch(
                    `https://api-ft5g.onrender.com/get-files-data/${userId}/${orderId}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    console.log("File Data Response:", data); // Debugging response
                    setFileData(data.files || []);
                } else {
                    const errorMsg = `Failed to fetch file data: ${response.status} ${response.statusText}`;
                    throw new Error(errorMsg);
                }
            } catch (err) {
                console.error("Error fetching file data:", err);
                setError(err.message);
            }
        };

        fetchOrderDetails();
        fetchFileData();
    }, [userId, orderId]); // Add userId and orderId as dependencies

    const downloadFile = (filePath, fileName) => {
        const link = document.createElement("a");
        link.href = `https://api-ft5g.onrender.com/${filePath}`;
        link.download = fileName;
        link.click();
    };




    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in when the component mounts
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        setIsLoggedIn(!!(token && userId)); // Set `isLoggedIn` based on the existence of token and userId
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            alert("You are already logged in!"); // Show an alert if already logged in
        } else {
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        console.log("User logged out and data removed from localStorage.");
        setIsLoggedIn(false);
        navigate("/Login"); // Redirect to login
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container fluid className="mt-3">
                <Row>
                    <Col className="mb-3 mt-3" lg={12} xs={12}>
                        <Card>
                            <h2 className="my-3">Shipment Files</h2>
                            {error && <p className="text-danger">Error: {error}</p>}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Order ID</th>
                                        {/* <th>User ID</th> */}
                                        <th>Proforma Invoice Number</th>
                                        <th>Document Type</th>
                                        <th>File Name</th>
                                        {/* <th>File Type</th> */}
                                        <th>Upload Date</th>
                                        <th>Order Status</th>
                                        <th>BL Number</th>
                                        <th>Shipping Lines</th>
                                        <th>ETA</th>
                                        <th>Commercial Invoice Number</th>
                                        <th>Commercial Invoice Date</th>
                                        <th>Proforma Invoice Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fileData.map((file, index) => (
                                        <tr key={index}>
                                            <td>{file.OrderID}</td>
                                            {/* <td>{file.UserID}</td> */}
                                            <td>{file.ProformaInvoiceNumber}</td>
                                            <td>{file.DocumentType}</td>
                                            <td>{file.FileName}</td>
                                            {/* <td>{file.FileType}</td> */}
                                            <td>{new Date(file.UploadDate).toLocaleString()}</td>
                                            <td>{file.ShippingStatus}</td>
                                            <td>{file.BLNumber}</td>
                                            <td>{file.ShippingLines}</td>
                                            <td>{file.ETA}</td>
                                            <td>{file.CommercialInvoiceNumber}</td>
                                            <td>{file.CommercialInvoiceDate}</td>
                                            <td>{file.ProformaInvoiceDate}</td>
                                            <td>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => downloadFile(file.FilePath, file.FileName)}
                                                >
                                                    Download
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg={12} xs={12} className="mt-3">
                        <Card className="p-4">
                            <h5 className="text-center mb-4">ORDER DETAILS</h5>

                            {orderDetails.length > 0 ? (
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr className="text-center">
                                            <th>Order ID</th>
                                            <th>Product Description</th>
                                            <th>Ordered On</th>
                                            {/* <th>Status</th> */}
                                            <th>Quantity</th>
                                            <th>No. of Cartons</th>
                                            <th>Total Cases</th>
                                            <th>Total QTY</th>
                                            <th>Rate Per Unit USD</th>
                                            <th>Rate Per Case USD</th>
                                            <th>Total Rate In USD FOB INDIA</th>
                                            <th>Total Net Weight</th>
                                            <th>Total Gross Weight</th>
                                            <th>Total Volume</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetails.map((item, index) => (
                                            <tr key={index} className="text-center">
                                                <td>{item.OrderID}</td>
                                                <td>{item.PRODUCT_DESCRIPTION}</td>
                                                <td>{new Date(item.UploadDate).toLocaleString()}</td>
                                                {/* <td className="text-warning" style={{ fontWeight: "bold" }}>{item.ShippingStatus}</td> */}
                                                <td>{item.UNIT_PER_CTN} {item.UNIT} / Cartons</td>
                                                <td>{item.CartonQty}</td>
                                                <td>{item.TotalCases}</td>
                                                <td>{item.TotalQty}</td>
                                                <td>{item.RatePerUnitUSD}</td>
                                                <td>{item.RatePerCaseUSD}</td>
                                                <td>{item.TotalRateInUSDFobIndia}</td>
                                                <td>{item.TotalNetWeight}</td>
                                                <td>{item.TotalGrossWeight}</td>
                                                <td>{item.TotalVolume}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p className="text-center text-muted">No details available for this order.</p>
                            )}

                            {/* <hr className="my-4" />

                            <p className="text-center text-muted orderfont">
                                Your order will be delivered tentatively by <span style={{ fontWeight: "bold" }}>15 Dec 2024</span>
                            </p> */}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Order;