import React from "react";
import "./UserDashboard.css";
import { Container, Nav, Button, Navbar, Row, Col, Card, Table } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Logo from "../src/Photos/Logo.png";
import { Link } from "react-router-dom";
import Account from "../src/Photos/Account.png";
import container from "../src/Photos/Container.png";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import inquiry from "../src/Photos/Inquiry.png";

function UserDashboard() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    <Button onClick={scrollToTop} className="logoscrollbtn p-0">
                        <Navbar.Brand href="/">
                            <img src={Logo}
                                alt="logo"
                                className="d-inline-block align-top" />
                        </Navbar.Brand>
                    </Button>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>

                            <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="logoscrollbtn p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-2 mobileview">
                        <Link to="#Account">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>

                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container fluid>
                <Row>
                    <Col className="mt-3">
                        <Card className="p-3">
                            <Row className="px-0">
                                <Col lg={1} xs={3}>
                                    <img src={inquiry}
                                        alt=""
                                        className="d-inline-block align-top"
                                    />
                                </Col>

                                <Col>
                                    <Row>
                                        <Col className="p-0" lg={1} xs={1}>
                                            <p className="inquiryno mb-0">5</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="p-0">
                                            <p className="inquiry mb-0">Inquires</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt-3">
                                    <Table responsive>
                                        <thead>
                                            <tr className="">
                                                <th className="">Sr. No.</th>
                                                <th className="">Inquiry For</th>
                                                <th className="">Inquiry Date</th>
                                                <th className="">Status</th>
                                            </tr>
                                        </thead>
                                        <br />
                                        <tbody>
                                            <tr className="">
                                                <td className="border-0">1</td>
                                                <td className="border-0">Inquiry Details</td>
                                                <td className="border-0">09/10/2024</td>
                                                <td className="border-0">
                                                    <Card className="donestatus text-center">Done</Card>
                                                </td>
                                            </tr>

                                            <tr className="">
                                                <td className="border-0">2</td>
                                                <td className="border-0">Inquiry Details</td>
                                                <td className="border-0">10/10/2024</td>
                                                <td className="border-0">
                                                    <Card className="pendingstatus text-center">Pending</Card>
                                                </td>
                                            </tr>

                                            <tr className="">
                                                <td className="border-0">3</td>
                                                <td className="border-0">Inquiry Details</td>
                                                <td className="border-0">11/10/2024</td>
                                                <td className="border-0">
                                                    <Card className="donestatus text-center">Done</Card>
                                                </td>
                                            </tr>

                                            <tr className="">
                                                <td className="border-0">4</td>
                                                <td className="border-0">Inquiry Details</td>
                                                <td className="border-0">12/10/2024</td>
                                                <td className="border-0">
                                                    <Card className="donestatus text-center">Done</Card>
                                                </td>
                                            </tr>

                                            <tr className="">
                                                <td className="border-0">5</td>
                                                <td className="border-0">Inquiry Details</td>
                                                <td className="border-0">13/10/2024</td>
                                                <td className="border-0">
                                                    <Card className="pendingstatus text-center">Pending</Card>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                {/* ----------------FOOTER----------------- */}

                <MDBFooter bgColor='light' className='text-center text-lg-start text-muted pt-4'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                        <div className='me-5 d-none d-lg-block'>
                            <span>Get connected with us on social networks:</span>
                        </div>

                        <div>
                            <Link to="#" className='me-4 text-reset'>
                                <FaFacebook />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='twitter' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='instagram' />
                            </Link>

                            <Link to="#" className='me-4 text-reset'>
                                <MDBIcon color='secondary' fab icon='linkedin' />
                            </Link>
                        </div>
                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Eximtrac
                                    </h6>
                                    <img src={Logo}
                                        alt="logo"
                                        className="d-inline-block align-top"
                                    />
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>Company</h6>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/" className='text-reset foottext'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Aboutus" className='text-reset foottext'>
                                                About Us
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Products" className='text-reset foottext'>
                                                Shop Now
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>OFFICE ADDRESS</h6>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur. Ut ultricies ut vestibulum non.
                                        Iaculis volutpat vel posuere vitae duis.
                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>CONTACT & EMAIL</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div class="icon-bar">
                                            <a href="mailto:info@example.com">
                                                <MdEmail className="me-3" /> info@example.com
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:0123456789">
                                                <FaPhoneAlt className="me-3" /> +91 - 0123456789
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-4 footerlastbgco'>
                        © 2024 Copyright:
                        <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
                            MDBootstrap.com
                        </a>
                    </div>
                </MDBFooter>
            </Container >
        </>
    );
}

export default UserDashboard;