import React, { useState, useEffect } from "react";
import "./Products.css";
import { Container, Nav, Button, Navbar, Row, Col, Card, Form, Dropdown, Image, Carousel } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import Account from "../src/Photos/Account.png";
import container from "../src/Photos/Container.png";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";


function Products({ userId }) {

    userId = localStorage.getItem('userId'); // Fetch userId from localStorage
    console.log('User ID:', userId); // Debugging log

    const token = localStorage.getItem('token');

    const [cartonQuantities, setCartonQuantities] = useState({}); // Use an object to track quantities by product ID

    // Function to handle quantity change
    const handleQuantityChange = (productId, value) => {
        setCartonQuantities((prevQuantities) => ({
            ...prevQuantities,
            [productId]: value, // Update the quantity for the specific product
        }));
    };

    // Function to handle API call when the button is clicked
    // function handleAddToContainer(item, index) {

    const handleAddToContainer = (item) => {
        const quantity = cartonQuantities[item.ID]; // Get the quantity for this product
        console.log("Quantity for Product:", quantity); // Log the quantity before validation

        // Validation: Ensure quantity is entered
        if (!quantity || isNaN(quantity) || quantity <= 0) {
            alert("Please enter a valid quantity.");
            return;
        }

        console.log("Quantity is valid");

        // Prepare the payload
        const payload = {
            Quantity: item.UNIT_PER_CTN, // Assuming UNIT_PER_CTN is the quantity to be sent
            ProductID: item.ID, // Product ID
            UserID: userId, // User ID
            CartonQty: quantity, // Add this field from user input
        };
        console.log("Sending payload:", payload); // Debugging: Log payload

        // Check if UserID is available
        if (!userId) {
            alert('You need to log in to add items to the container.');
            // Redirect to login page if UserID is not set
            window.location.href = '/login'; // Update this path as per your app's login route
            return;
        }

        // Function to create a temporary notification
        function showTemporaryMessage(message) {
            // Create a div element for the message
            const messageDiv = document.createElement('div');
            messageDiv.textContent = message;
            messageDiv.style.position = 'fixed';
            messageDiv.style.top = '150px';
            messageDiv.style.left = '50%';
            messageDiv.style.transform = 'translateX(-50%)';
            messageDiv.style.backgroundColor = '#28a745'; // Success green color
            messageDiv.style.color = 'white';
            messageDiv.style.padding = '10px 20px';
            messageDiv.style.borderRadius = '5px';
            messageDiv.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
            messageDiv.style.zIndex = '1000';

            document.body.appendChild(messageDiv);

            // Remove the message after 5 seconds
            setTimeout(() => {
                document.body.removeChild(messageDiv);
            }, 5000);
        }

        // Make a POST API call
        fetch('https://api-ft5g.onrender.com/add-to-container', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Attach the token
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.message) {
                    showTemporaryMessage(data.message); // Show the response message
                } else {
                    showTemporaryMessage('Product added to the container successfully!');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                showTemporaryMessage('An error occurred while adding the product to the container.');
            });
    }


    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };


    const navigate = useNavigate();

    // const [isAdded, setIsAdded] = useState(false); // Track if the item is added

    // const handleButtonClick = () => {
    //     if (!isAdded) {
    //         setIsAdded(true); // Change button to "Added"
    //     } else {
    //         navigate("/Addtocontainer"); // Redirect to "Add to Container" page on second click
    //     }
    // };



    // const [filterVal, setFilterVal] = useState('');
    // const [ApiData, setApiData] = useState([]);
    // const [input, setData] = useState([]);
    // useEffect(() => {
    //     fetch('http://localhost:1433/GetAllProducts')
    //         .then((res) => res.json())
    //         .then((docs) => {
    //             setData(docs.Data);
    //             setApiData(docs.Data)
    //             console.log(docs.Data);
    //         });
    // }, []);

    // const [filterVal, setFilterVal] = useState('');
    const [input, setInput] = useState([]); // Initial state for all products
    const [filteredData, setFilteredData] = useState([]); // Filtered data state
    const [ApiData, setApiData] = useState([]); // Categories
    const [ApiData2, setApiData2] = useState([]); // Sub-Categories
    const [ApiData3, setApiData3] = useState([]); // Brands

    const [category, setCategory] = useState(''); // Category filter state
    const [subCategory, setSubCategory] = useState(''); // Sub-category filter state
    const [brand, setBrand] = useState(''); // Brand filter state

    // Fetch All Products on Mount
    useEffect(() => {
        fetch('https://api-ft5g.onrender.com/GetAllProducts')
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            })
            .then((docs) => {
                if (docs && docs.data) {
                    setInput(docs.data); // Original data
                    setFilteredData(docs.data); // Set initial filtered data
                } else {
                    console.warn("Data property not found in response:", docs);
                    setInput([]);
                    setFilteredData([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setInput([]);
                setFilteredData([]);
            });
    }, []);

    // Fetch Categories
    useEffect(() => {
        fetch('https://api-ft5g.onrender.com/GetCategory')
            .then((res) => res.json())
            .then((docs) => setApiData(docs.data))
            .catch((error) => console.error("Error fetching categories:", error));
    }, []);

    // Fetch Sub-Categories when Category Changes
    useEffect(() => {
        if (category) {
            fetch(`https://api-ft5g.onrender.com/GetSubCategory?category=${category}`)
                .then((res) => res.json())
                .then((docs) => setApiData2(docs.data))
                .catch((error) => console.error("Error fetching sub-categories:", error));
        } else {
            setApiData2([]);
        }
    }, [category]);

    // Fetch Brands when Category or Sub-Category Changes
    useEffect(() => {
        if (category) {
            const url = subCategory
                ? `https://api-ft5g.onrender.com/GetBrand?category=${category}&subCategory=${subCategory}`
                : `https://api-ft5g.onrender.com/GetBrand?category=${category}`;

            fetch(url)
                .then((res) => res.json())
                .then((docs) => setApiData3(docs.data))
                .catch((error) => console.error("Error fetching brands:", error));
        } else {
            setApiData3([]);
        }
    }, [category, subCategory]);

    // Apply Filter
    const handleFilter = () => {
        console.log("Applying filters...");
        console.log("Selected Category:", category);
        console.log("Selected Sub-Category:", subCategory);
        console.log("Selected Brand:", brand);

        // Start filtering from the original dataset
        let filteredResults = input;

        // Apply category filter
        if (category && category !== "SELECT") {
            filteredResults = filteredResults.filter(
                (item) => item.CATEGORY.toLowerCase() === category.toLowerCase()
            );
        }

        // Apply sub-category filter
        if (subCategory && subCategory !== "SELECT") {
            filteredResults = filteredResults.filter(
                (item) => item.SUB_CATEGORY.toLowerCase() === subCategory.toLowerCase()
            );
        }

        // Apply brand filter
        if (brand && brand !== "SELECT") {
            filteredResults = filteredResults.filter(
                (item) => item.BRAND.toLowerCase() === brand.toLowerCase()
            );
        }

        console.log("Filtered Results:", filteredResults);

        // Update the state with filtered data
        setFilteredData(filteredResults);
    };

    const resetFilters = () => {
        console.log("Resetting filters...");
        setCategory("");
        setSubCategory("");
        setBrand("");
        setFilteredData(input); // Reset to original data
    };



    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in when the component mounts
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        setIsLoggedIn(!!(token && userId)); // Set `isLoggedIn` based on the existence of token and userId
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            alert("You are already logged in!"); // Show an alert if already logged in
        } else {
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        console.log("User logged out and data removed from localStorage.");
        setIsLoggedIn(false);
        navigate("/Login"); // Redirect to login
    };


    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>

                </Container>
            </Navbar>

            {/* ----------------Navbar Complete---------------- */}

            <Container fluid>
                <Row>
                    <Col className="mt-3 filter">
                        <Card className="filtercard">
                            <Row className="px-3 mb-1">
                                {/* Category Filter */}
                                <Col lg={1} xs={3} className="mt-4">
                                    <Form.Label htmlFor="categorySelect" className="filtertext">Category</Form.Label>
                                </Col>
                                <Col lg={2} xs={3} className="mt-3">
                                    <Form.Select
                                        id="categorySelect"
                                        aria-label="Select Category"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    >
                                        <option value="">SELECT</option>
                                        {ApiData.map((name, index) => (
                                            <option key={index} value={name.name}>
                                                {name.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>

                                {/* Sub-Category Filter */}
                                <Col lg={1} xs={3} className="mt-2">
                                    <Form.Label htmlFor="subCategorySelect" className="filtertext">Sub - Category</Form.Label>
                                </Col>
                                <Col lg={2} xs={3} className="mt-3">
                                    <Form.Select
                                        id="subCategorySelect"
                                        aria-label="Select Sub-Category"
                                        value={subCategory}
                                        onChange={(e) => setSubCategory(e.target.value)}
                                    >
                                        <option value="">SELECT</option>
                                        {ApiData2.map((name, index) => (
                                            <option key={index} value={name.SUB_CATEGORY}>
                                                {name.SUB_CATEGORY}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>

                                {/* Brand Filter */}
                                <Col lg={1} xs={3} className="mt-4">
                                    <Form.Label htmlFor="brandSelect" className="filtertext">Brands</Form.Label>
                                </Col>
                                <Col lg={2} xs={3} className="mt-3">
                                    <Form.Select
                                        id="brandSelect"
                                        aria-label="Select Brand"
                                        value={brand}
                                        onChange={(e) => setBrand(e.target.value)}
                                    >
                                        <option value="">SELECT</option>
                                        {ApiData3.map((name, index) => (
                                            <option key={index} value={name.BRAND}>
                                                {name.BRAND}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>

                                {/* Buttons */}
                                <Col className="mt-3">
                                    <Button onClick={handleFilter} className="applyfilterbtn">
                                        Apply Filter
                                    </Button>
                                </Col>

                                <Col className="mt-3">
                                    <Button onClick={resetFilters} className="resetbtn ms-2 applyfilterbtn">
                                        Reset
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                {/* <Row>
                    <Col>
                        <Card className="weightvolumecard">
                            <Row className="px-3">
                                <Col lg={1} xs={3} className="mt-4 mb-2 weightcolor">
                                    <p>Total Weight :</p>
                                </Col>

                                <Col lg={2} xs={3} className="mt-3 mb-2">
                                    <Form.Control type="text" readOnly />
                                </Col>

                                <Col lg={1} xs={3} className="mt-4 mb-2 volumecolor">
                                    <p>Total Volume :</p>
                                </Col>

                                <Col lg={2} xs={3} className="mt-3 mb-2">
                                    <Form.Control type="text" readOnly />
                                </Col>

                                <Col lg={1} xs={3} className="mt-4 mb-2 cartoncolor">
                                    <p>Total Cartons :</p>
                                </Col>

                                <Col lg={2} xs={3} className="mt-3 mb-2">
                                    <Form.Control type="text" readOnly />
                                </Col>

                                <Row>
                                    <Col className="mb-2">
                                        <p className="notecolor">Note* : Total Weight and Total Volume is calculated combined all the products you add in your Container</p>
                                    </Col>
                                </Row>
                            </Row>
                        </Card>
                    </Col>
                </Row> */}

                <Row>
                    {filteredData.map((item, index) => (
                        <Col lg={3} xs={12} md={6} className="mt-3" key={index}>
                            <Card className="p-3 productcard">
                                <Row>
                                    <Col lg={8}>
                                        <p className="categoryname">SKU Code: {item.SKU_CODE}</p>
                                    </Col>

                                    <Col lg={4}>
                                        <p className="categoryname">Product ID: {item.ID}</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <p className="productname">Product: {item.PRODUCT_DESCRIPTION}</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <p className="brandname">Brand Name: {item.BRAND}</p>
                                            </Col>
                                        </Row>

                                        {/* <Row>
                                    <Col>
                                        <p className="categoryname">Category Name: {item.CATEGORY}</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <p className="categoryname">Sub - Category Name: {item.SUB_CATEGORY}</p>
                                    </Col>
                                </Row> */}

                                        <Row>
                                            <Col>
                                                <p className="qty">
                                                    Qty: {item.UNIT_PER_CTN} {item.UNIT} / CASE
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <p className="unitweight">Per Unit Weight: {item.WEIGHT_PER_PKT_GRAMS} GM</p>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col className="mb-3 mt-2">
                                        {item.image_urls.length > 0 ? (
                                            <Carousel>
                                                {item.image_urls.map((imgSrc, index) => (
                                                    <Carousel.Item key={index}>
                                                        <Image src={imgSrc} alt={`Product Image ${index}`} thumbnail />
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        ) : (
                                            <p></p>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <p className="quantity">Quantity (No. of Case) :</p>
                                    </Col>

                                    <Col>
                                        <Form.Control
                                            type="number"
                                            min="1"
                                            placeholder="Order Quantity"
                                            value={cartonQuantities[item.ID] || ""} // Show current quantity for each product
                                            onChange={(e) =>
                                                handleQuantityChange(item.ID, e.target.value)
                                            } // Handle input change
                                            required
                                        />
                                    </Col>
                                </Row>

                                <Row className="text-center">
                                    <Col className="mt-3">
                                        {/* Add API call to the button */}
                                        <Button
                                            onClick={() => handleAddToContainer(item)} // Pass the specific item to the handler
                                            className="addtocontainer w-100"
                                        >
                                            Add To Container
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    ))}

                    {/* <Col lg={3} xs={6} className="mt-3">
                        <Card className="p-3 productcard">
                            <Row>
                                <Col>
                                    <p className="productname">Product Name</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="brandname">Brand Name</p>
                                </Col>

                                <Col>
                                    <p className="categoryname">Category Name</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="qty">Qty: 50 Units / Cartons</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="unitweight">Per Unit Weight: 500gm</p>
                                </Col>
                            </Row>

                            <Row className="text-center">
                                <Col>
                                    <Button
                                        onClick={handleButtonClick}
                                        className="addtocontainer w-100"
                                        style={{
                                            backgroundColor: isAdded ? "#28a745" : "#007bff",
                                            border: "none",
                                            color: "#fff",
                                        }}
                                    >
                                        {isAdded ? "Added" : "Add To Container"}
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>


                <div class="icon-bar-floating">
                    <a href="https://wa.me/+919029937080"
                        // class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                    </a>

                    <a href="tel:9029937080">
                        <BiSolidPhoneCall className="call p-1" />
                    </a>
                </div>


                {/* ----------------FOOTER----------------- */}

                <MDBFooter bgColor='light' className='text-center text-lg-start text-muted pt-4 foote'>
                    {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                                        <div className='me-5 d-none d-lg-block'>
                                            <span>Get connected with us on social networks:</span>
                                        </div>
                
                                        <div>
                                            <Link to="#" className='me-4 text-reset'>
                                                <FaFacebook />
                                            </Link>
                
                                            <Link to="#" className='me-4 text-reset'>
                                                <MDBIcon color='secondary' fab icon='twitter' />
                                            </Link>
                
                                            <Link to="#" className='me-4 text-reset'>
                                                <MDBIcon color='secondary' fab icon='instagram' />
                                            </Link>
                
                                            <Link to="#" className='me-4 text-reset'>
                                                <MDBIcon color='secondary' fab icon='linkedin' />
                                            </Link>
                                        </div>
                                    </section> */}

                    <section className=''>
                        <MDBContainer className='text-center text-md-start mt-5'>
                            <MDBRow className='mt-3'>
                                <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>
                                        {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                                        Eximtrac
                                    </h6>
                                    <p>
                                        We are a certified Mumbai-based exporter, specializing in Indian groceries and FMCG products (food and non-food).
                                        With a vast portfolio of 200+ brands and new additions regularly, we source and deliver everything you need, consolidating all your requirements into one solution.
                                    </p>
                                </MDBCol>

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>Quick Links</h6>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/" className='text-reset foottext'>
                                                Home
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Aboutus" className='text-reset foottext'>
                                                About Us
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/Products" className='text-reset foottext'>
                                                Shop Now
                                            </Link>
                                        </Button>
                                    </p>
                                    <p>
                                        <Button onClick={scrollToTop} className="foottextbtn p-0">
                                            <Link to="/ContactUs" className='text-reset foottext'>
                                                Contact Us
                                            </Link>
                                        </Button>
                                    </p>
                                </MDBCol>

                                <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>OFFICE ADDRESS</h6>
                                    <p>
                                        Eximtrac<br />
                                        Shop No 2&3, Laxmi Apts, Behind Patel Nagar,
                                        M.G. Cross Road no 4, Kandivali West,
                                        Mumbai – 400067, India

                                    </p>
                                </MDBCol>

                                <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                                    <h6 className='text-uppercase fw-bold mb-4 companyname'>CONTACT & EMAIL</h6>
                                    <p>
                                        {/* <MDBIcon color='secondary' icon='envelope' className='me-3' /> */}
                                        <div class="icon-bar">
                                            <a href="mailto:eximtrac@gmail.com" className="textdeco">
                                                <MdEmail className="me-2" /> eximtrac@gmail.com
                                            </a>
                                            <br />
                                            <a href="mailto:connect@eximtrac.com" className="textdeco">
                                                <MdEmail className="me-2 footerphone" /> connect@eximtrac.com
                                            </a>
                                        </div>
                                    </p>

                                    <p>
                                        {/* <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88 */}
                                        <div>
                                            <a href="tel:9699268642" className="textdeco">
                                                <FaPhoneAlt className="me-2" /> +91-96992 68642
                                            </a>
                                            <br />
                                            <a href="tel:9029937080" className="textdeco">
                                                <FaPhoneAlt className="me-2 footerphone" /> +91-90299 37080
                                            </a>
                                        </div>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>

                    <div className='text-center p-4 footerlastbgco'>
                        © 2024 Copyright:
                        <a className='text-reset fw-bold' href='Eximtrac'>
                            Eximtrac
                        </a>
                    </div>
                </MDBFooter>
            </Container >
        </>
    );
}

export default Products;