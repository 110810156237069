import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Button, Card, Col, Container, Nav, Navbar, Row, Form, Dropdown } from "react-bootstrap";
// import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
// import dash from "../src/Photos/Dashboard.png";
// import menu from "../src/Photos/Menu.png";
// import { IoIosArrowForward } from "react-icons/io";
import Account from "../src/Photos/Account.png";
import Logo from "../src/Photos/Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import container from "../src/Photos/Container.png";

import { CgProfile } from "react-icons/cg";

function Profile() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const navigate = useNavigate();

    const token = localStorage.getItem('token');

    const userId = localStorage.getItem('userId');

    // State to manage edit mode
    const [isEditable, setIsEditable] = useState(false);

    // Toggle edit mode
    // const handleEdit = () => setIsEditable(true);
    // const handleCancel = () => setIsEditable(false);

    // State to manage form inputs
    const [formData, setFormData] = useState({
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        AlternatePhone: "",
        CompanyName: "",
        CompanyAddress: "",
        Country: "",
        State: "",
        City: "",
        ZipCode: "",
        DischargePort: "",
        AlternateEmailID: "",
        FinalDestination: "",
    });

    // Fetch user data when the component mounts
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`https://api-ft5g.onrender.com/get-profile/${userId}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    // Update the formData state with fetched data
                    setFormData({
                        FirstName: data.FirstName || "",
                        LastName: data.LastName || "",
                        PhoneNumber: data.PhoneNumber || "",
                        AlternatePhone: data.AlternatePhone || "",
                        CompanyName: data.CompanyName || "",
                        CompanyAddress: data.CompanyAddress || "",
                        Country: data.Country || "",
                        State: data.State || "",
                        City: data.City || "",
                        ZipCode: data.ZipCode || "",
                        DischargePort: data.DischargePort || "",
                        AlternateEmailID: data.AlternateEmailID || "",
                        FinalDestination: data.FinalDestination || "",
                    });
                } else {
                    console.error("Failed to fetch user data.");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [userId, token]);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Save Profile Changes
    const saveProfile = async () => {

        // Log the data you're about to send
        console.log("Data being sent to API:", formData);

        try {
            const response = await fetch("https://api-ft5g.onrender.com/profile", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Add token to headers
                },
                body: JSON.stringify({ userId, ...formData }), //Ensure userId is sent
            });

            // Log the response status and the body
            console.log("Response status:", response.status);
            const result = await response.json();
            console.log("Response body:", result);

            if (response.ok) {
                alert("Profile updated successfully!");
                setIsEditable(false); // Exit edit mode
            } else {
                alert(result.error || "Failed to update profile.");
            }
        } catch (error) {
            console.error("Error updating profile:", error);
            alert("An error occurred while updating the profile.");
        }
    };


    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in when the component mounts
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        setIsLoggedIn(!!(token && userId)); // Set `isLoggedIn` based on the existence of token and userId
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            alert("You are already logged in!"); // Show an alert if already logged in
        } else {
            navigate("/Login"); // Redirect to login page if not logged in
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        console.log("User logged out and data removed from localStorage.");
        setIsLoggedIn(false);
        navigate("/Login"); // Redirect to login
    };


    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navibar" sticky="top">
                <Container>
                    {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0"> */}
                    <Navbar.Brand href="/">
                        <img src={Logo}
                            alt="logo"
                            className="d-inline-block align-top logo" />
                    </Navbar.Brand>
                    {/* </Button> */}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <p className="mt-3 logosidetext">Government Recognised Export House</p>
                        </Nav>

                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/" className="navtext">Home</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Aboutus" className="navtext">About Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/Products" className="navtext">Shop Now</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ContactUs" className="navtext">Contact Us</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0 mx-3">
                                <Nav.Link as={Link} to="/ProductCatalogue" className="navtext">Product Catalogue</Nav.Link>
                            </Button>

                            <Dropdown>
                                <Dropdown.Toggle
                                    className="scrollbtn2 p-0 mx-3 mt-1 responsiveview"
                                    id="dropdown-basic"
                                >
                                    <img
                                        src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleLoginClick}>Login</Dropdown.Item> {/* Login click handler */}
                                    {isLoggedIn && <Dropdown.Item href="/Profile">My Profile</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/OrderPage">Orders</Dropdown.Item>}
                                    {isLoggedIn && <Dropdown.Item href="/EnquiryPage">Enquiry</Dropdown.Item>}
                                    {isLoggedIn && (
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Button onClick={scrollToTop} className="logoscrollbtn p-0 mx-3 responsiveview">
                                <Link to="/Profile">
                                    <img src={Account}
                                        alt="account"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button> */}

                            <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-3 responsiveview">
                                <Link to="/Addtocontainer">
                                    <img src={container}
                                        alt="container"
                                        className="d-inline-block align-top"
                                    />
                                </Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>

                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mobileview">
                        <Link to="/Addtocontainer">
                            <img src={container}
                                alt="container"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>


                    <Button onClick={scrollToTop} className="scrollbtn2 p-0 mx-2 mobileview">
                        <Link to="/Profile">
                            <img src={Account}
                                alt="account"
                                className="d-inline-block align-top"
                            />
                        </Link>
                    </Button>
                </Container>
            </Navbar>

            {/* -----------------Navbar Complete---------------- */}

            <Container className="mt-3">
                <Row>
                    <Col>
                        <Card className="profile_card mb-3">
                            <Row className="px-5 mt-3">
                                <Col lg={1}>
                                    <CgProfile className="profile_icon" />
                                </Col>

                                <Col>
                                    <p className="profilename">{formData?.FirstName || formData?.EmailID || "No Data Available"}</p>
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col className="px-5 my-3">
                                    <Form>
                                        <Row>
                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                        name="FirstName"
                                                        placeholder="Enter First Name"
                                                        readOnly={!isEditable}
                                                        value={formData.FirstName}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control name="LastName"
                                                        placeholder="Enter Last Name"
                                                        readOnly={!isEditable}
                                                        value={formData.LastName}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control name="PhoneNumber"
                                                        placeholder="Enter Phone Number"
                                                        readOnly={!isEditable}
                                                        value={formData.PhoneNumber}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Alternate Phone Number</Form.Label>
                                                    <Form.Control name="AlternatePhone"
                                                        placeholder="Enter Alternate Phone Number"
                                                        readOnly={!isEditable}
                                                        value={formData.AlternatePhone}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Company Name</Form.Label>
                                                    <Form.Control name="CompanyName"
                                                        placeholder="Enter Company Name"
                                                        readOnly={!isEditable}
                                                        value={formData.CompanyName}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Company Address</Form.Label>
                                                    <Form.Control name="CompanyAddress"
                                                        placeholder="Enter Company Address"
                                                        readOnly={!isEditable}
                                                        value={formData.CompanyAddress}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control name="Country"
                                                        placeholder="Enter Country"
                                                        readOnly={!isEditable}
                                                        value={formData.Country}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control name="State"
                                                        placeholder="Enter State"
                                                        readOnly={!isEditable}
                                                        value={formData.State}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control
                                                        name="City"
                                                        placeholder="Enter City"
                                                        readOnly={!isEditable}
                                                        value={formData.City}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Zip Code</Form.Label>
                                                    <Form.Control
                                                        name="ZipCode"
                                                        placeholder="Enter Zip Code"
                                                        readOnly={!isEditable}
                                                        value={formData.ZipCode}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Discharge Port</Form.Label>
                                                    <Form.Control name="DischargePort"
                                                        placeholder="Enter Discharge Port"
                                                        readOnly={!isEditable}
                                                        value={formData.DischargePort}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Alternate Email ID</Form.Label>
                                                    <Form.Control name="AlternateEmailID"
                                                        placeholder="Enter Alternate Email ID"
                                                        readOnly={!isEditable}
                                                        value={formData.AlternateEmailID}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Final Destination</Form.Label>
                                                    <Form.Control name="FinalDestination"
                                                        placeholder="Enter Final Destination"
                                                        readOnly={!isEditable}
                                                        value={formData.FinalDestination}
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {/* <Row>
                                                <Col className="text-end">
                                                    <Button className="profile_edit">
                                                        Edit
                                                    </Button>
                                                </Col>

                                                <Col>
                                                    <Button className="profile_cancel">
                                                        Cancel
                                                    </Button>
                                                </Col>
                                            </Row> */}

                                        <div className="d-flex justify-content-center mt-3">
                                            {isEditable ? (
                                                <>
                                                    <Button variant="primary" className="me-2" onClick={saveProfile}>
                                                        Save
                                                    </Button>
                                                    <Button variant="secondary" onClick={() => setIsEditable(false)}>
                                                        Cancel
                                                    </Button>
                                                </>
                                            ) : (
                                                <Button variant="primary" onClick={() => setIsEditable(true)}>
                                                    Edit
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col >
                </Row >
            </Container >
        </>
    );
}

export default Profile;